import flyoutStyles from "styles/Flyout.module.css";
import { TOPOLOGY_TYPE } from "common/TYPES";
import { useObjectContext } from "context/ObjectContext";
import { useToolContext } from "context/ToolContext";

function EdgeSettingModal({ style, taskManagerRef }) {
  const { isEdgeSettingModalEnabled, setIsEdgeSettingModalEnabled } =
    useToolContext();
  const { objectsDict, setObjectsDict } = useObjectContext();
  const edgeTypeOnChange = (value) => {
    const object = isEdgeSettingModalEnabled.object;
    taskManagerRef.current.addHistory([object], "UPDATE");
    object.setEdgeType(value.target.value);
    setObjectsDict({ ...objectsDict, [object.id]: object });

    setIsEdgeSettingModalEnabled({ enabled: false, object: null });
  };
  const handleDelete = () => {
    const object = isEdgeSettingModalEnabled.object;
    taskManagerRef.current.addHistory([object], "DELETE");
    const prevObjectsDict = { ...objectsDict };
    setObjectsDict({
      type: "DELETE",
      payload: prevObjectsDict[object.id],
    });
    setIsEdgeSettingModalEnabled({ enabled: false, object: null });
  };
  return (
    <div className={flyoutStyles.flyoutContents} style={style}>
      <div className={flyoutStyles.flyoutItem}>
        <div className={flyoutStyles.flyoutSection}>
          <div className={flyoutStyles.settingContainer}>
            <span className={flyoutStyles.settingLabel}>타입</span>
            <select
              onChange={edgeTypeOnChange}
              className={flyoutStyles.settingInput}
              value={isEdgeSettingModalEnabled.object.edgeType}
            >
              {TOPOLOGY_TYPE.EDGE.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.value}
                </option>
              ))}
            </select>
            <span className={flyoutStyles.settingUnit}></span>
          </div>
        </div>
        <div className={flyoutStyles.flyoutItem}>
          <div className={flyoutStyles.flyoutSection}>
            <span className={flyoutStyles.flyoutLabel}>Enabled</span>
            <div className={flyoutStyles.toggleButton}>
              <label className={flyoutStyles.switch}>
                <input
                  type="checkbox"
                  checked={isEdgeSettingModalEnabled.object.enabled}
                  onChange={() => {
                    const object = isEdgeSettingModalEnabled.object;
                    object.setEnabled(!object.enabled);
                    setObjectsDict({ ...objectsDict, [object.id]: object });
                    setIsEdgeSettingModalEnabled({
                      enabled: false,
                      object: null,
                    });
                  }}
                />
                <span className={flyoutStyles.toggle}></span>
              </label>
            </div>
          </div>
          <div className={flyoutStyles.flyoutItem}>
            <div className={flyoutStyles.flyoutSection}>
              <button onClick={handleDelete}>삭제</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EdgeSettingModal;
