import { v4 as uuidv4 } from "uuid";

import { OBJECT_TYPES } from "common/TYPES";
import { getLocationCode } from "features/utils";

const rotate = (center, angle, point) => {
  const x =
    center.x +
    (point.x - center.x) * Math.cos(angle) -
    (point.y - center.y) * Math.sin(angle);
  const y =
    center.y +
    (point.x - center.x) * Math.sin(angle) +
    (point.y - center.y) * Math.cos(angle);
  return { x, y };
};

class Drawing {
  constructor(config) {
    this.id = uuidv4();
    this.type = OBJECT_TYPES.DRAWING;
    this.data = [];
    this.center = { x: 0, y: 0 };
    this.yaw = 0.0;
    this.color = config.color;
    this.stroke = config.stroke;
  }
  setID(id) {
    this.id = id;
  }

  setType(type) {
    this.type = type;
  }

  addPoint(data) {
    this.data.push(data);
  }

  setEndPoint(data) {
    this.data = [this.data[0], data];
  }

  setCenter(center = null) {
    if (center === null) {
      const x =
        this.data.reduce((acc, curr) => acc + curr.x, 0) / this.data.length;
      const y =
        this.data.reduce((acc, curr) => acc + curr.y, 0) / this.data.length;
      this.center = { x, y };
    } else {
      this.center = center;
    }
  }

  setData(data) {
    this.data = data;
  }

  setYaw(yaw) {
    this.yaw = yaw;
  }

  setColor(color) {
    this.color = color;
  }

  setStroke(stroke) {
    this.stroke = stroke;
  }

  rotate(center, angle) {
    this.data = this.data.map((point) => {
      const x =
        center.x +
        (point.x - center.x) * Math.cos(angle) -
        (point.y - center.y) * Math.sin(angle);
      const y =
        center.y +
        (point.x - center.x) * Math.sin(angle) +
        (point.y - center.y) * Math.cos(angle);
      return { x, y };
    });
    this.setYaw(this.yaw + angle);
    // this.orderPoints();
    this.setCenter();
  }

  translate(dx, dy) {
    this.data = this.data.map((point) => {
      return { x: point.x + dx, y: point.y + dy };
    });
    this.setCenter();
  }
}

class Rectangle {
  constructor(config) {
    this.type = OBJECT_TYPES.RECTANGLE;
    this.id = uuidv4();
    this.center = { x: 0, y: 0 };
    this.data = [];
    this.yaw = 0.0;
    this.color = config.color;
    this.fill = config.fill;
  }

  setStartPoint(point) {
    // p1 ----- p2
    // |        |
    // p4 ----- p3
    this.data = [point, point, point, point];
  }

  setEndPoint(point) {
    // p1 ----- p2
    // |        |
    // p4 ----- p3
    this.data[1] = { x: point.x, y: this.data[0].y };
    this.data[2] = point;
    this.data[3] = { x: this.data[0].x, y: point.y };
  }

  orderPoints() {
    const points = [...this.data];

    const p1 = {
      x: Math.min(...points.map((point) => point.x)),
      y: Math.min(...points.map((point) => point.y)),
    };
    const p2 = {
      x: Math.max(...points.map((point) => point.x)),
      y: Math.min(...points.map((point) => point.y)),
    };
    const p3 = {
      x: Math.max(...points.map((point) => point.x)),
      y: Math.max(...points.map((point) => point.y)),
    };
    const p4 = {
      x: Math.min(...points.map((point) => point.x)),
      y: Math.max(...points.map((point) => point.y)),
    };

    this.data = [p1, p2, p3, p4];
  }

  setCornerPoint(idx, point) {
    this.data[idx] = { ...point };
  }

  setCenter(center = null) {
    if (center === null) {
      const x =
        this.data.reduce((acc, curr) => acc + curr.x, 0) / this.data.length;
      const y =
        this.data.reduce((acc, curr) => acc + curr.y, 0) / this.data.length;
      this.center = { x, y };
    } else {
      this.center = center;
    }
  }

  setID(id) {
    this.id = id;
  }

  setYaw(yaw) {
    // between -pi and pi
    this.yaw = yaw;
    if (this.yaw > Math.PI) {
      this.yaw -= 2 * Math.PI;
    } else if (this.yaw < -Math.PI) {
      this.yaw += 2 * Math.PI;
    }
  }

  setColor(color) {
    this.color = color;
  }

  setFill(fill) {
    this.fill = fill;
  }

  setData(data) {
    this.data = data;
  }

  setType(type) {
    this.type = type;
  }

  rotate(center, angle) {
    this.data = this.data.map((point) => {
      const x =
        center.x +
        (point.x - center.x) * Math.cos(angle) -
        (point.y - center.y) * Math.sin(angle);
      const y =
        center.y +
        (point.x - center.x) * Math.sin(angle) +
        (point.y - center.y) * Math.cos(angle);
      return { x, y };
    });
    this.setYaw(this.yaw + angle);
    // this.orderPoints();
    this.setCenter();
  }

  translate(dx, dy) {
    this.data = this.data.map((point) => {
      return { x: point.x + dx, y: point.y + dy };
    });
    this.setCenter();
  }
}

class Vertex {
  constructor() {
    this.id = null;
    this.type = OBJECT_TYPES.VERTEX;
    this.center = { x: 0, y: 0 };
    this.yaw = 0.0;
    this.vertexType = "default";
    this.enabled = true;
    this.size = 0.0;
    this.frame_id = "map";
  }
  setID(id) {
    this.id = `T_${id}`;
  }
  setVertexType(type) {
    this.vertexType = type;
  }

  setEnabled(enabled) {
    this.enabled = enabled;
  }

  setFrameId(frame_id) {
    this.frame_id = frame_id;
  }

  setSize(size) {
    this.size = size;
  }

  setCenter(point) {
    this.center = point;
  }

  translate(dx, dy) {
    this.center = { x: this.center.x + dx, y: this.center.y + dy };
  }

  rotate(center, angle) {
    const x =
      center.x +
      (this.center.x - center.x) * Math.cos(angle) -
      (this.center.y - center.y) * Math.sin(angle);
    const y =
      center.y +
      (this.center.x - center.x) * Math.sin(angle) +
      (this.center.y - center.y) * Math.cos(angle);
    this.center = { x, y };
    this.yaw = this.yaw + angle;
    if (this.yaw > Math.PI) {
      this.yaw -= 2 * Math.PI;
    } else if (this.yaw < -Math.PI) {
      this.yaw += 2 * Math.PI;
    }
  }
}

class Edge {
  constructor() {
    this.id = null;
    this.type = OBJECT_TYPES.EDGE;
    this.center = { x: 0, y: 0 };
    this.yaw = 0.0;
    this.edgeType = "default";
    this.cost = 0.0;
    this.enabled = true;
    this.vertices = []; // [src, dst]
    this.dstPoint = { x: 0, y: 0 }; // used when edge is not updated
    this.srcPoint = { x: 0, y: 0 }; // used when edge is not updated
    this.vertexSize = 0.0; // used when edge is not updated
  }

  setID(id) {
    this.id = id;
  }

  setDstPoint(point) {
    this.dstPoint = point;
  }

  setSrcPoint(point) {
    this.srcPoint = point;
  }

  setVertexSize(size) {
    this.vertexSize = size;
  }

  setEdgeType(type) {
    this.edgeType = type;
  }

  setCost(cost) {
    this.cost = cost;
  }

  setVertices(vertices) {
    this.vertices = vertices;
  }

  setEnabled(enabled) {
    this.enabled = enabled;
  }

  invert() {
    this.vertices = [this.vertices[1], this.vertices[0]];
  }

  getInverted() {
    const edge = new Edge();
    edge.setID(`${this.vertices[1]}_${this.vertices[0]}`);
    edge.setDstPoint(this.srcPoint);
    edge.setSrcPoint(this.dstPoint);
    edge.setVertexSize(this.vertexSize);
    edge.setEdgeType(this.edgeType);
    edge.setCost(this.cost);
    edge.setVertices([this.vertices[1], this.vertices[0]]);
    edge.setEnabled(this.enabled);
    edge.setCost(this.cost);
    edge.setCenter();
    return edge;
  }

  setCenter() {
    const x = (this.srcPoint.x + this.dstPoint.x) / 2;
    const y = (this.srcPoint.y + this.dstPoint.y) / 2;
    this.center = { x, y };
  }

  translate(dx, dy) {
    // this.center = { x: this.center.x + dx, y: this.center.y + dy };
  }

  rotate(center, angle) {}
  update(objectsDict, resolution, canvasScale) {
    const src = objectsDict[this.vertices[0]];
    const dst = objectsDict[this.vertices[1]];
    this.srcPoint = { ...src.center };
    this.dstPoint = { ...dst.center };
    const cost =
      (Math.sqrt(
        Math.pow(this.srcPoint.x - this.dstPoint.x, 2) +
          Math.pow(this.srcPoint.y - this.dstPoint.y, 2)
      ) *
        resolution) /
      canvasScale;
    this.cost = cost;
    this.setCenter();
  }
}

class Rack {
  constructor() {
    this.id = uuidv4();
    this.type = OBJECT_TYPES.RACK;
    this.center = { x: 0, y: 0 };
    this.origin = { x: 0, y: 0 };
    this.yaw = 0.0;
    this.resolution = 0.0;

    this.rackType = null;
    this.width = 0.0;
    this.height = 0.0;
    this.depth = 0.0;
    this.data = []; // rack points

    this.zoneId = "";
    this.aisleId = "";
    this.rackId = 1;
    this.rackName = "";
    this.pickingSide = "right";
    this.locationCodePattern = null;
    this.locationCodePatternName = "";
    this.rackType = null;
    this.rackTypeName = "";

    this.cells = [];
    this.stopOffset = {};
    this.defaultStopOffset = {
      x: 0.0,
      y: 0.5,
      yaw: 0.0,
    };
    this.isRackConfigured = false;
  }

  setResolution(resolution) {
    this.resolution = resolution;
  }
  setCenter(center = null) {
    if (center === null) {
      const x =
        this.data.reduce((acc, curr) => acc + curr.x, 0) / this.data.length;
      const y =
        this.data.reduce((acc, curr) => acc + curr.y, 0) / this.data.length;
      this.center = { x, y };
    } else {
      this.center = center;
    }
  }

  setRackType(rackTypeName, rackType) {
    this.width = rackType.width;
    this.height = rackType.height;
    this.depth = rackType.depth;
    this.levelHeights = rackType.level_heights;
    this.numCell = rackType.num_cell;
    this.numLevel = rackType.num_level;
    this.rackType = rackType;
    this.rackTypeName = rackTypeName;
  }

  setLocationCodePattern(patternName, pattern) {
    this.locationCodePatternName = patternName;
    this.locationCodePattern = pattern;
  }

  setRackPoints() {
    const pixelWidth = this.width / this.resolution;
    const pixelDepth = this.depth / this.resolution;
    let p0 = {
      x: this.center.x - pixelWidth / 2,
      y: this.center.y + pixelDepth / 2,
    };

    let p1 = {
      x: this.center.x + pixelWidth / 2,
      y: this.center.y + pixelDepth / 2,
    };

    let p2 = {
      x: this.center.x + pixelWidth / 2,
      y: this.center.y - pixelDepth / 2,
    };

    let p3 = {
      x: this.center.x - pixelWidth / 2,
      y: this.center.y - pixelDepth / 2,
    };

    // rotate
    const angle = this.yaw;
    const center = this.center;

    p0 = rotate(center, angle, p0);
    p1 = rotate(center, angle, p1);
    p2 = rotate(center, angle, p2);
    p3 = rotate(center, angle, p3);

    this.origin = p0;
    this.data = [p0, p1, p2, p3];
  }

  init(
    rackTypeName,
    rackType,
    locationCodePatternName,
    locationCodePattern,
    resolution
  ) {
    this.setRackType(rackTypeName, rackType);
    this.setLocationCodePattern(locationCodePatternName, locationCodePattern);
    this.setResolution(resolution);
    this.setRackPoints();
  }

  configure() {
    let isZoneIDConfigured = false;
    let isAisleIDConfigured = false;
    let isRackIDConfigured = false;

    const stringPattern = this.locationCodePattern.string_pattern;
    if (stringPattern.includes("Z")) {
      if (this.zoneId !== "") {
        isZoneIDConfigured = true;
      }
    } else {
      isZoneIDConfigured = true;
    }

    if (stringPattern.includes("A")) {
      if (this.aisleId !== "") {
        isAisleIDConfigured = true;
      }
    } else {
      isAisleIDConfigured = true;
    }

    if (stringPattern.includes("R")) {
      if (this.rackId !== "") {
        isRackIDConfigured = true;
      }
    } else {
      isRackIDConfigured = true;
    }
    if (
      this.rackType !== null &&
      this.locationCodePattern !== null &&
      isZoneIDConfigured &&
      isAisleIDConfigured &&
      isRackIDConfigured &&
      this.data.length === 4
    ) {
      this.isRackConfigured = true;
      let codeConfig = {
        Z: this.zoneId,
        A: this.aisleId,
        R: this.rackId,
        L: 0,
        C: 0,
      };
      const rackCode = getLocationCode(codeConfig, this.locationCodePattern);
      this.rackName = rackCode + "-RACK";
      this.generateCell();
    }
  }

  rotate(center, angle) {
    this.data = this.data.map((point) => {
      const x =
        center.x +
        (point.x - center.x) * Math.cos(angle) -
        (point.y - center.y) * Math.sin(angle);
      const y =
        center.y +
        (point.x - center.x) * Math.sin(angle) +
        (point.y - center.y) * Math.cos(angle);
      return { x, y };
    });
    this.yaw = this.yaw + angle;
    if (this.yaw > Math.PI) {
      this.yaw -= 2 * Math.PI;
    } else if (this.yaw < -Math.PI) {
      this.yaw += 2 * Math.PI;
    }

    this.setCenter();
    for (let i = 0; i < this.cells.length; i++) {
      const cell = this.cells[i];
      cell.rotate(center, angle);
    }
  }

  translate(dx, dy) {
    this.data = this.data.map((point) => {
      return { x: point.x + dx, y: point.y + dy };
    });
    for (let i = 0; i < this.cells.length; i++) {
      const cell = this.cells[i];
      cell.translate(dx, dy);
    }
    this.setCenter();
  }

  copyFront(numberingStep = 1) {
    const rack = new Rack();

    rack.init(
      this.rackTypeName,
      this.rackType,
      this.locationCodePatternName,
      this.locationCodePattern,
      this.resolution
    );
    rack.setCenter(this.center);
    rack.zoneId = this.zoneId;
    rack.aisleId = this.aisleId;
    rack.rackId = this.rackId + numberingStep;
    rack.rackName = this.rackName;
    rack.pickingSide = this.pickingSide;
    rack.cells = this.cells;
    rack.isRackConfigured = this.isRackConfigured;
    // rotate
    const angle = this.yaw;
    const dx = (this.width / this.resolution) * Math.cos(angle);
    const dy = (this.width / this.resolution) * Math.sin(angle);

    rack.yaw = this.yaw;
    rack.data = rack.data.map((point) => {
      return { x: point.x + dx, y: point.y + dy };
    });
    rack.center = { x: rack.center.x + dx, y: rack.center.y + dy };
    rack.setRackPoints();
    rack.configure();
    rack.generateCell();
    return rack;
  }

  copyBack(numberingStep = 1) {
    const rack = new Rack();
    rack.init(
      this.rackTypeName,
      this.rackType,
      this.locationCodePatternName,
      this.locationCodePattern,
      this.resolution
    );
    rack.setCenter(this.center);
    rack.zoneId = this.zoneId;
    rack.aisleId = this.aisleId;
    rack.rackId = this.rackId + numberingStep;
    rack.rackName = this.rackName;
    rack.pickingSide = this.pickingSide;
    rack.cells = this.cells;
    rack.isRackConfigured = this.isRackConfigured;
    // rotate
    const angle = this.yaw;
    const dx = (this.width / this.resolution) * Math.cos(angle);
    const dy = (this.width / this.resolution) * Math.sin(angle);

    rack.yaw = this.yaw;
    rack.data = rack.data.map((point) => {
      return { x: point.x - dx, y: point.y - dy };
    });
    rack.center = { x: rack.center.x - dx, y: rack.center.y - dy };
    rack.setRackPoints();
    rack.configure();

    return rack;
  }

  getCell(codeConfig, i, j) {
    const locationCode = getLocationCode(codeConfig, this.locationCodePattern);
    const cell = new Cell(this.resolution);
    cell.setID(locationCode);
    cell.levelId = i;
    if (this.pickingSide === "right") {
      const x =
        ((this.width / (this.numCell[i - 1] + 1)) * j) / this.resolution;
      const y = 0;
      const z = this.levelHeights[i - 1] / this.resolution;
      const centerFromRack = {
        x: x,
        y: y,
        z: z,
      };
      const px =
        centerFromRack.x * Math.cos(this.yaw) -
        centerFromRack.y * Math.sin(this.yaw);
      const py =
        centerFromRack.x * Math.sin(this.yaw) +
        centerFromRack.y * Math.cos(this.yaw);

      const cellCenter = {
        x: this.data[0].x + px,
        y: this.data[0].y + py,
        z: z,
      };
      cell.setCenter(cellCenter);
      cell.yaw = this.yaw + Math.PI;

      return cell;
    } else if (this.pickingSide === "left") {
      const x = (this.width / this.resolution / (this.numCell[i - 1] + 1)) * j;
      const y = -this.depth / this.resolution;
      const z = this.levelHeights[i - 1] / this.resolution;
      const centerFromRack = {
        x: x,
        y: y,
        z: z,
      };
      const px =
        centerFromRack.x * Math.cos(this.yaw) -
        centerFromRack.y * Math.sin(this.yaw);
      const py =
        centerFromRack.x * Math.sin(this.yaw) +
        centerFromRack.y * Math.cos(this.yaw);

      const cellCenter = {
        x: this.data[0].x + px,
        y: this.data[0].y + py,
        z: z,
      };

      cell.setCenter(cellCenter);
      cell.yaw = this.yaw;
      return cell;
    }
  }

  generateCell() {
    const cells = [];
    if (this.locationCodePattern.cell_scheme === "normal") {
      for (let i = 1; i < this.numLevel + 1; i++) {
        for (let j = 1; j < this.numCell[i - 1] + 1; j++) {
          let codeConfig = {
            Z: this.zoneId,
            A: this.aisleId,
            R: this.rackId,
            L: i,
            C: j,
          };
          const cell = this.getCell(codeConfig, i, j);
          cells.push(cell);
        }
      }
    } else if (this.locationCodePattern.cell_scheme === "Z-type") {
      for (let i = 1; i < this.numLevel + 1; i++) {
        for (let j = 1; j < this.numCell[i - 1] + 1; j++) {
          let codeConfig = {
            Z: this.zoneId,
            A: this.aisleId,
            R: this.rackId,
            L: i,
            C: j,
          };
          if (i > 1) {
            let c = 0;
            for (let k = 1; k < i; k++) {
              c += this.numCell[k - 1];
            }
            codeConfig = {
              Z: this.zoneId,
              A: this.aisleId,
              R: this.rackId,
              L: i,
              C: j + c,
            };
          }
          const cell = this.getCell(codeConfig, i, j);
          cells.push(cell);
        }
      }
    } else if (this.locationCodePattern.cell_scheme === "N-type") {
      for (let i = 1; i < this.numLevel + 1; i++) {
        for (let j = 1; j < this.numCell[i - 1] + 1; j++) {
          let codeConfig = {
            Z: this.zoneId,
            A: this.aisleId,
            R: this.rackId,
            L: i,
            C: i + (j - 1) * this.numLevel,
          };
          const cell = this.getCell(codeConfig, i, j);
          cells.push(cell);
        }
      }
    }
    this.cells = cells;
  }
}

class Cell {
  constructor(resolution) {
    this.id = null;
    this.type = OBJECT_TYPES.CELL;
    this.center = { x: 0, y: 0, z: 0 };
    this.yaw = 0.0;
    this.levelId = 0;
    this.resolution = resolution;
  }

  setID(id) {
    this.id = id;
  }

  setCenter(center) {
    this.center = center;
  }

  translate(dx, dy) {
    this.center = {
      x: this.center.x + dx,
      y: this.center.y + dy,
      z: this.center.z,
    };
  }

  rotate(center, angle) {
    const x =
      center.x +
      (this.center.x - center.x) * Math.cos(angle) -
      (this.center.y - center.y) * Math.sin(angle);
    const y =
      center.y +
      (this.center.x - center.x) * Math.sin(angle) +
      (this.center.y - center.y) * Math.cos(angle);
    this.center = { x, y, z: this.center.z };
    this.yaw = this.yaw + angle;
    if (this.yaw > Math.PI) {
      this.yaw -= 2 * Math.PI;
    } else if (this.yaw < -Math.PI) {
      this.yaw += 2 * Math.PI;
    }
  }
}

class StopPose extends Cell {
  constructor(resolution) {
    super(resolution);
    this.type = OBJECT_TYPES.STOP;
    this.parentCellId = null;
  }

  setID(id) {
    this.id = id + "-STOP";
    this.parentCellId = id;
  }

  rotate(center, angle) {
    const x =
      center.x +
      (this.center.x - center.x) * Math.cos(angle) -
      (this.center.y - center.y) * Math.sin(angle);
    const y =
      center.y +
      (this.center.x - center.x) * Math.sin(angle) +
      (this.center.y - center.y) * Math.cos(angle);
    this.center = { x, y, z: this.center.z };
  }
}

class Poi {
  constructor() {
    this.id = uuidv4();
    this.name = "";
    this.number = 0;
    this.type = OBJECT_TYPES.POI;
    this.center = { x: 0, y: 0 };
    this.size = 0.5;
    this.yaw = 0.0;
  }

  setID(id) {
    this.id = id;
  }

  setName(name) {
    this.name = name;
  }

  setNumber(number) {
    this.number = number;
  }

  setCenter(point) {
    this.center = point;
  }

  setSize(size) {
    this.size = size;
  }

  setYaw(yaw) {
    this.yaw = yaw;
  }

  translate(dx, dy) {
    this.center = { x: this.center.x + dx, y: this.center.y + dy };
  }

  rotate(center, angle) {
    const x =
      center.x +
      (this.center.x - center.x) * Math.cos(angle) -
      (this.center.y - center.y) * Math.sin(angle);
    const y =
      center.y +
      (this.center.x - center.x) * Math.sin(angle) +
      (this.center.y - center.y) * Math.cos(angle);
    this.center = { x, y };
    this.yaw = this.yaw + angle;
    if (this.yaw > Math.PI) {
      this.yaw -= 2 * Math.PI;
    } else if (this.yaw < -Math.PI) {
      this.yaw += 2 * Math.PI;
    }
  }
}
class Ruler {
  constructor() {
    this.id = uuidv4();
    this.type = OBJECT_TYPES.RULER;
    this.center = { x: 0, y: 0 };
    this.size = 0.0;
    this.yaw = 0.0;
    this.data = [];
  }

  setCenter(point) {
    this.center = point;
  }

  setSize(size) {
    this.size = size;
  }
}

export { Drawing, Rectangle, Vertex, Edge, Rack, Cell, StopPose, Poi, Ruler };
