// import "./App.css";
import { useEffect, useState, useRef } from "react";
import { useObjectContext } from "context/ObjectContext";
import { useToolContext } from "context/ToolContext";
import { getActiveCategoryColor } from "features/utils";
import { OBJECT_CATEGORY, UI_TOOL_NAMES } from "common/TYPES";
import RackPanel from "components/rightPanel/RackPanel";
import PencilPanel from "components/rightPanel/PencilPanel";
import RectanglePanel from "components/rightPanel/RectanglePanel";
import TopologyPanel from "components/rightPanel/TopologyPanel";
import PoiPanel from "components/rightPanel/PoiPanel";
import RulerPanel from "components/rightPanel/RulerPanel";

function RightPanel() {
  const rightPanelContainerRef = useRef(null);

  const {
    objectsDict,
    setObjectsDict,
    selectedObjectsList,
    setSelectedObjectsList,
    activeObjectCategory,
    setActiveObjectCategory,
  } = useObjectContext();
  const { currentTool } = useToolContext();

  useEffect(() => {
    if (!currentTool) return;
    console.log(currentTool);
    rightPanelContainerRef.current.style.backgroundColor =
      getActiveCategoryColor(activeObjectCategory, 0.2);
  }, [activeObjectCategory]);

  return (
    <div className="RightPanelContainer" ref={rightPanelContainerRef}>
      {activeObjectCategory === OBJECT_CATEGORY.RACK && <RackPanel />}
      {currentTool === UI_TOOL_NAMES.PENCIL && <PencilPanel />}
      {currentTool === UI_TOOL_NAMES.RECTANGLE && <RectanglePanel />}
      {currentTool === UI_TOOL_NAMES.TOPOLOGY && <TopologyPanel />}
      {currentTool === UI_TOOL_NAMES.POI && <PoiPanel />}
      {currentTool === UI_TOOL_NAMES.RULER && <RulerPanel />}
    </div>
  );
}

export default RightPanel;
