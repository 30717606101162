import { UI_TOOL_NAMES } from "common/TYPES";
import { transformPointToOriginalImageCoord } from "features/utils";
import { Drawing } from "features/Object";
import { OBJECT_TYPES } from "common/TYPES";
export class PencilTool {
  constructor(setObjectsDict) {
    this.setObjectsDict = setObjectsDict;
    this.imageOrigin = {};
    this.currentObjects = [];
    this.currentObjectUpdated = false;
  }
  init(
    imageOrigin,
    canvasInfo,
    canvas,
    taskManager,
    config,
    setSelectedObjectsList
  ) {
    this.imageOrigin = imageOrigin;
    this.canvas = canvas;
    this.canvasInfo = canvasInfo;
    this.taskManager = taskManager;
    this.config = config;
    this.setSelectedObjectsList = setSelectedObjectsList;
  }
  resetCurrentObjects() {
    this.currentObjects = [];
  }
  setTool(currentTool) {
    this.currentTool = currentTool;
    if (this.currentTool === UI_TOOL_NAMES.PENCIL) {
      this.isActivated = true;
    } else {
      this.isActivated = false;
    }
  }
  updateObjectsDict(objectsDict) {
    this.objectsDict = objectsDict;
  }
  updateImageOrigin(imageOrigin) {
    if (!imageOrigin) return;
    this.imageOrigin = imageOrigin;
  }

  updateConfig(config) {
    this.config = config;
  }
  updateCanvasInfo(canvasInfo) {
    this.canvasInfo = canvasInfo;
  }

  setLoadedObjects(objects) {}

  listenMouseAction(mouseEvent) {
    if (!this.isActivated) return;
    const button = mouseEvent.event.button;
    const deltaY = mouseEvent.event.deltaY; // scroll down : positive, scroll up : negative
    const x = mouseEvent.event.offsetX;
    const y = mouseEvent.event.offsetY;
    const isShiftPressed = mouseEvent.event.shiftKey;
    const isCtrlPressed = mouseEvent.event.ctrlKey;
    const isAltPressed = mouseEvent.event.altKey;
    const isMetaPressed = mouseEvent.event.metaKey;

    switch (mouseEvent.action) {
      case "mousedown":
        if (this.currentObjectUpdated) {
          this.currentObjects = [];
          this.currentObjectUpdated = false;
        }
        this.mouseDownPos = { x, y };
        const transformedMouseDownPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin
        );
        if (button === 0) {
          this.isMouseLeftDown = true;
        } else if (button === 1) {
          this.isMouseMiddleDown = true;
        } else if (button === 2) {
          this.isMouseRightDown = true;
        }
        //"pencil-drawing-start"
        if (this.isMouseLeftDown) {
          const currentObject = new Drawing(this.config);
          currentObject.addPoint(transformedMouseDownPos);
          this.currentObjects.push(currentObject);
        }
        break;
      case "mousemove":
        if (this.currentObjectUpdated) {
          this.currentObjects = [];

          this.currentObjectUpdated = false;
        }
        this.mouseCurrentPos = { x, y };
        const transformedMouseCurrentPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin
        );
        //"pencil-drawing"
        if (this.isMouseLeftDown && !isShiftPressed) {
          for (let i = 0; i < this.currentObjects.length; i++) {
            const currentObject = this.currentObjects[i];
            currentObject.setType(OBJECT_TYPES.DRAWING);
            currentObject.addPoint(transformedMouseCurrentPos);
          }
        } else if (
          //"pencil-drawing-straight"

          this.isMouseLeftDown &&
          isShiftPressed
        ) {
          for (let i = 0; i < this.currentObjects.length; i++) {
            const currentObject = this.currentObjects[i];
            currentObject.setType(OBJECT_TYPES.LINE);
            currentObject.setEndPoint(transformedMouseCurrentPos);
          }
        } else if (
          //"pencil-idle"
          !this.isMouseLeftDown &&
          !this.isSpacePressed
        ) {
        }
        break;

      case "mouseleave":
      case "mouseup":
        this.mouseUpPos = { x, y };
        this.isMouseMiddleDown = false;
        this.isMouseRightDown = false;
        const transformedMouseUpPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin
        );
        if (this.isMouseLeftDown) {
          this.isMouseLeftDown = false;
          if (
            this.mouseDownPos.x !== this.mouseUpPos.x &&
            this.mouseDownPos.y !== this.mouseUpPos.y
          ) {
            if (isShiftPressed) {
              //pencil-drawing-straight-end
              for (let i = 0; i < this.currentObjects.length; i++) {
                const currentObject = this.currentObjects[i];
                currentObject.setEndPoint(transformedMouseUpPos);
                currentObject.setCenter();
                this.setObjectsDict({
                  type: "ADD",
                  payload: currentObject,
                });
              }

              this.taskManager.addHistory(this.currentObjects, "ADD");
              this.currentObjectUpdated = true;
            } else {
              //pencil-drawing-end
              for (let i = 0; i < this.currentObjects.length; i++) {
                const currentObject = this.currentObjects[i];
                currentObject.addPoint(transformedMouseUpPos);
                currentObject.setCenter();
                this.setObjectsDict({
                  type: "ADD",
                  payload: currentObject,
                });
              }

              this.taskManager.addHistory(this.currentObjects, "ADD");
              this.currentObjectUpdated = true;
            }
          } else {
            this.currentObjectUpdated = true;
          }
        } else {
          //pencil-idle
        }

        break;

      default:
        break;
    }
  }

  listenKeyboardAction(keyboardEvent) {
    if (!this.isActivated) return;
  }
}
