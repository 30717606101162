import styles from "styles/Flyout.module.css";
import { useToolContext } from "context/ToolContext";
import { useState } from "react";

function SaveFlyout() {
  const { setIsUploadModalEnabled } = useToolContext();

  return (
    <div className={styles.flyoutContents}>
      <div className={styles.flyoutItem}>
        <div
          className={styles.Button}
          onClick={() => {
            setIsUploadModalEnabled(true);
          }}
        >
          저장 및 업로드
        </div>
        <div className={styles.Button}>현재 상태 저장</div>
      </div>
    </div>
  );
}

export default SaveFlyout;
