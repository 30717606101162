import "App.css";
import Editor from "pages/Editor";
import Select from "pages/Select";
import Home from "pages/Home";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  document.getSelection().removeAllRanges();
  return (
    <div className="App">
      {/* <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/select" element={<Select />} />
          <Route path="/editor/:env_name" element={<Editor />} />
        </Routes>
      </Router> */}
      <Editor />
    </div>
  );
}

export default App;
