import { createContext, useContext, useReducer, useState } from "react";

import { OBJECT_CATEGORY } from "common/TYPES";
import { objectReducer } from "reducers/objectReducer";
// 1) context creation...
const CTX = createContext();

// 2) context provide to others...
export const ObjectContext = ({ children }) => {
  const [activeObjectCategory, setActiveObjectCategory] = useState(
    OBJECT_CATEGORY.AREA
  );

  const [selectedObjectsList, setSelectedObjectsList] = useState([]);
  const [invisibleObjectsList, setInvisibleObjectsList] = useState([]);
  const [hoveredObject, setHoveredObject] = useState([]);

  const [objectsDict, setObjectsDict] = useReducer(objectReducer, {});

  const values = {
    objectsDict,
    setObjectsDict,

    selectedObjectsList,
    setSelectedObjectsList,

    invisibleObjectsList,
    setInvisibleObjectsList,

    activeObjectCategory,
    setActiveObjectCategory,

    hoveredObject,
    setHoveredObject,
  };

  return <CTX.Provider value={values}>{children}</CTX.Provider>;
};

export const useObjectContext = () => useContext(CTX);
