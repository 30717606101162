import {
  OBJECT_TYPES,
  OBJECT_TYPES_TO_CATEGORY_MAP,
  UI_TOOL_NAMES,
} from "common/TYPES";
import {
  transformPointToOriginalImageCoord,
  checkPointInRack,
  checkPointNearCorner,
} from "features/utils";
import { Rack } from "features/Object";

export class RackTool {
  constructor(setObjectsDict) {
    this.setObjectsDict = setObjectsDict;
    this.objectsDict = {};
    this.imageOrigin = {};
    this.currentObjects = [];
    this.isGenerating = false;
    this.isRotating = false;
    this.isTranslating = false;
    this.moveKeyPressed = false;
    this.isActivated = false;
    this.isCursorOnCanvas = false;

    this.transformedMouseDownPos = { x: 0, y: 0 };
    this.transformedMouseCurrentPos = { x: 0, y: 0 };
    this.transformedMouseUpPos = { x: 0, y: 0 };
    this.transformedPreviousMousePos = { x: 0, y: 0 };
  }
  init(
    imageOrigin,
    mapMetadata,
    canvas,
    canvasInfo,
    taskManager,
    setSelectedObjectsList
  ) {
    this.imageOrigin = imageOrigin;
    this.mapMetadata = mapMetadata;
    this.canvas = canvas;
    this.canvasInfo = canvasInfo;
    this.canvasScale = canvasInfo.scale;
    this.taskManager = taskManager;
    this.setSelectedObjectsList = setSelectedObjectsList;
    this.selectedObject = null;
    this.selectedObjectsList = [];
  }

  resetCurrentObjects() {
    this.currentObjects = [];
  }

  updateSelectedObjectsList(selectedObjectsList) {
    this.selectedObjectsList = selectedObjectsList;
    if (this.selectedObjectsList.length === 1) {
      this.selectedObject = this.selectedObjectsList;
    } else {
      this.selectedObject = null;
    }
  }

  updateObjectsDict(objectsDict) {
    this.objectsDict = objectsDict;
  }
  updateCanvasInfo(canvasInfo) {
    this.canvasInfo = canvasInfo;
    this.canvasScale = canvasInfo.scale;
  }
  setTool(currentTool) {
    this.currentTool = currentTool;
    if (this.currentTool === UI_TOOL_NAMES.RACK) {
      this.isActivated = true;
    } else {
      this.isActivated = false;
    }
    this.selectedObject = null;
    this.currentObjects = [];
  }
  updateImageOrigin(imageOrigin) {
    if (!imageOrigin) return;
    this.imageOrigin = imageOrigin;
  }

  updateConfig(rackToolConfig, rackTypesData, locationCodePatternsData) {
    this.config = rackToolConfig;
    this.rackNumberingStep = rackToolConfig.rackNumberingStep;
    this.rackTypesData = rackTypesData;
    this.locationCodePatternsData = locationCodePatternsData;
    this.rackTypeName = this.config.rackType;
    this.rackType = this.rackTypesData[this.rackTypeName];
    this.locationCodePatternName = this.config.locationCodePattern;
    this.locationCodePattern =
      this.locationCodePatternsData[this.config.locationCodePattern];
  }

  listenMouseAction(mouseEvent) {
    if (!this.isActivated) return;
    const button = mouseEvent.event.button;
    const x = mouseEvent.event.offsetX;
    const y = mouseEvent.event.offsetY;

    let closestRack;
    let isCursorOnRack = false;
    let isCursorNearCorner = false;

    switch (mouseEvent.action) {
      case "mousedown":
        this.mouseDownPos = { x, y };
        this.transformedMouseDownPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin
        );
        this.transformedPreviousMousePos = { ...this.transformedMouseDownPos };
        if (button === 0) {
          this.isMouseLeftDown = true;
        } else if (button === 1) {
          this.isMouseMiddleDown = true;
        } else if (button === 2) {
          this.isMouseRightDown = true;
        }
        closestRack = this.getClosestRack(this.transformedMouseDownPos);
        if (this.selectedObject !== null && this.selectedObject.length === 1) {
          isCursorNearCorner = checkPointNearCorner(
            this.transformedMouseDownPos,
            this.objectsDict[this.selectedObject[0]],
            this.imageOrigin.scale
          );
        } else {
          isCursorNearCorner = checkPointNearCorner(
            this.transformedMouseDownPos,
            closestRack,
            this.imageOrigin.scale
          );
        }
        isCursorOnRack = checkPointInRack(
          this.transformedMouseDownPos,
          closestRack,
          this.imageOrigin.scale
        );

        // generate new rack if clicked on empty space
        if (
          this.isMouseLeftDown &&
          !isCursorOnRack &&
          !isCursorNearCorner &&
          !this.isSpacePressed
        ) {
          if (this.selectedObject !== null) {
            this.setSelectedObjectsList([]);
            this.selectedObject = null;
            break;
          }
          const rack = new Rack();
          rack.setCenter(this.transformedMouseDownPos);
          rack.init(
            this.rackTypeName,
            this.rackType,
            this.locationCodePatternName,
            this.locationCodePattern,
            this.mapMetadata.resolution / this.canvasScale
          );
          this.setObjectsDict({
            type: "ADD",
            payload: rack,
          });
          this.taskManager.addHistory([rack], "ADD");
          this.selectedObject = [rack.id];
          this.setSelectedObjectsList(this.selectedObject);
        } else if (
          // select
          this.isMouseLeftDown &&
          isCursorOnRack &&
          !isCursorNearCorner &&
          !this.isSpacePressed
        ) {
          this.selectedObject = [closestRack.id];
          this.setSelectedObjectsList(this.selectedObject);
          // drag generating

          this.isGenerating = true;
        } else if (
          // rotate
          this.isMouseLeftDown &&
          isCursorNearCorner &&
          this.selectedObject !== null &&
          this.isSpacePressed
        ) {
          this.isRotating = true;
          this.taskManager.setSelectedToHistory("UPDATE");
        } else if (
          // translate
          this.isMouseLeftDown &&
          this.selectedObject !== null &&
          isCursorOnRack &&
          closestRack.id === this.selectedObject[0] &&
          this.isSpacePressed
        ) {
          this.isTranslating = true;
          this.taskManager.setSelectedToHistory("UPDATE");
        }

        break;
      case "mousemove":
        this.isCursorOnCanvas = true;
        this.mouseCurrentPos = { x, y };
        this.transformedMouseCurrentPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin
        );
        closestRack = this.getClosestRack(this.transformedMouseCurrentPos);
        if (this.selectedObject !== null && this.selectedObject.length === 1) {
          isCursorNearCorner = checkPointNearCorner(
            this.transformedMouseCurrentPos,
            this.objectsDict[this.selectedObject[0]],
            this.imageOrigin.scale
          );
        } else {
          isCursorNearCorner = checkPointNearCorner(
            this.transformedMouseCurrentPos,
            closestRack,
            this.imageOrigin.scale
          );
        }
        isCursorOnRack = checkPointInRack(
          this.transformedMouseCurrentPos,
          closestRack,
          this.imageOrigin.scale
        );

        if (
          !this.isMouseLeftDown &&
          isCursorOnRack &&
          !isCursorNearCorner &&
          !this.isSpacePressed
        ) {
          this.canvas.style.cursor = "pointer";
        } else if (
          !this.isMouseLeftDown &&
          isCursorNearCorner &&
          this.isSpacePressed
        ) {
          this.canvas.style.cursor = "grab";
        } else if (
          !this.isMouseLeftDown &&
          isCursorOnRack &&
          this.selectedObject !== null &&
          closestRack.id === this.selectedObject[0] &&
          this.isSpacePressed
        ) {
          this.canvas.style.cursor = "move";
        } else if (this.isTranslating) {
          this.canvas.style.cursor = "move";
        } else if (this.isRotating) {
          this.canvas.style.cursor = "grabbing";
        } else {
          this.canvas.style.cursor = "default";
        }

        if (this.isTranslating) {
          const selectedObject = this.objectsDict[this.selectedObject[0]];
          selectedObject.translate(
            this.transformedMouseCurrentPos.x -
              this.transformedPreviousMousePos.x,
            this.transformedMouseCurrentPos.y -
              this.transformedPreviousMousePos.y
          );
          this.setObjectsDict({
            type: "UPDATE",
            payload: selectedObject,
          });
          this.transformedPreviousMousePos = {
            ...this.transformedMouseCurrentPos,
          };
        }

        if (this.isRotating) {
          const selectedObject = this.objectsDict[this.selectedObject[0]];
          const center = selectedObject.center;
          const angle =
            Math.atan2(
              this.transformedMouseCurrentPos.y - center.y,
              this.transformedMouseCurrentPos.x - center.x
            ) -
            Math.atan2(
              this.transformedPreviousMousePos.y - center.y,
              this.transformedPreviousMousePos.x - center.x
            );
          selectedObject.rotate(center, angle);
          this.setObjectsDict({
            type: "UPDATE",
            payload: selectedObject,
          });
          this.transformedPreviousMousePos = {
            ...this.transformedMouseCurrentPos,
          };
        }

        if (this.isGenerating) {
          this.generateRack();
        }

        break;

      case "mouseleave":
        this.isCursorOnCanvas = false;

      case "mouseup":
        this.mouseUpPos = { x, y };
        this.isMouseMiddleDown = false;
        this.isMouseRightDown = false;
        this.isMouseLeftDown = false;
        this.transformedMouseUpPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin
        );

        if (this.isGenerating) {
          this.isGenerating = false;
          this.taskManager.addHistory(this.currentObjects, "ADD");
          for (let i = 0; i < this.currentObjects.length; i++) {
            this.setObjectsDict({
              type: "ADD",
              payload: this.currentObjects[i],
            });
          }
          this.currentObjects = [];
        }
        if (this.isTranslating) {
          this.isTranslating = false;
        }
        if (this.isRotating) {
          this.isRotating = false;
        }

        break;

      default:
        break;
    }
  }

  listenKeyboardAction(keyboardEvent) {
    const event = keyboardEvent.event;
    const action = keyboardEvent.action;
    const key = event.key;
    let selectedObject;
    switch (action) {
      case "keydown":
        switch (key) {
          case " ":
            this.isSpacePressed = true;
            break;
          // esc
          case "Escape":
            this.selectedObject = null;
            this.setSelectedObjectsList([]);
            break;

          case "w":
          case "W":
            if (event.ctrlKey) {
              break;
            }
            this.translate(0, -1);
            break;
          case "s":
          case "S":
            if (event.ctrlKey) {
              break;
            } else {
              this.translate(0, 1);
            }
            break;
          case "d":
          case "D":
            if (event.ctrlKey) {
              break;
            }
            this.translate(1, 0);
            break;
          case "a":
          case "A":
            if (event.ctrlKey) {
              break;
            }
            this.translate(-1, 0);
            break;

          case "e":
          case "E":
            if (event.ctrlKey) {
              break;
            }
            this.rotate(Math.PI / 360 / 2);
            break;

          case "q":
          case "Q":
            if (event.ctrlKey) {
              break;
            }
            this.rotate(-Math.PI / 360 / 2);
            break;

          case "Delete":
            if (this.selectedObject === null) return;
            this.selectedObject = null;
            this.setSelectedObjectsList([]);
            break;

          default:
            break;
        }
        break;
      case "keyup":
        switch (key) {
          case " ":
            this.isSpacePressed = false;
            break;

          case "w":
          case "W":
          case "s":
          case "S":
          case "a":
          case "A":
          case "d":
          case "D":
          case "e":
          case "E":
          case "q":
          case "Q":
            this.moveKeyPressed = false;
            break;

          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  translate(x, y) {
    let selectedObject;
    if (this.moveKeyPressed === false) {
      this.moveKeyPressed = true;
      this.taskManager.setSelectedToHistory("UPDATE");
    }
    if (this.selectedObject === null || this.isCursorOnCanvas !== true) return;

    selectedObject = this.objectsDict[this.selectedObject[0]];
    selectedObject.translate(x, y);
    this.setObjectsDict({
      type: "UPDATE",
      payload: selectedObject,
    });
    if (this.isGenerating) {
      this.generateRack();
    }
  }

  rotate(angle) {
    let selectedObject;
    if (this.selectedObject === null || this.isCursorOnCanvas !== true) return;
    if (this.moveKeyPressed === false) {
      this.moveKeyPressed = true;
      this.taskManager.setSelectedToHistory("UPDATE");
    }
    selectedObject = this.objectsDict[this.selectedObject[0]];
    selectedObject.rotate(selectedObject.center, angle);
    this.setObjectsDict({
      type: "UPDATE",
      payload: selectedObject,
    });
    if (this.isGenerating) {
      this.generateRack();
    }
  }

  generateRack() {
    // 클릭한 버텍스
    this.currentObjects = [];
    const rack = this.objectsDict[this.selectedObject[0]];
    const prevRackMeterCenter = this.pixelToMeter(rack.center);

    // 마우스 커서
    const mouseMeterPos = this.pixelToMeter(this.transformedMouseCurrentPos);
    // 클릭한 버텍스와 마우스 커서 사이의 거리
    const distance = Math.sqrt(
      (prevRackMeterCenter.x - mouseMeterPos.x) ** 2 +
        (prevRackMeterCenter.y - mouseMeterPos.y) ** 2
    );
    // 방향벡터
    const directionVector = {
      x: (mouseMeterPos.x - prevRackMeterCenter.x) / distance,
      y: (mouseMeterPos.y - prevRackMeterCenter.y) / distance,
    };

    const directionAngle = Math.atan2(directionVector.y, directionVector.x);
    const cursorDirectionAngle = ((directionAngle - -rack.yaw) * 180) / Math.PI;
    const interval = rack.width;
    const num = Math.floor(distance / interval);
    if (Math.abs(cursorDirectionAngle) < 45) {
      let prevRack = rack.copyFront(this.rackNumberingStep);
      for (let i = 0; i < num; i++) {
        this.currentObjects.push(prevRack);
        prevRack = prevRack.copyFront(this.rackNumberingStep);
      }
    } else if (Math.abs(cursorDirectionAngle) > 3 * 45) {
      let prevRack = rack.copyBack(this.rackNumberingStep);
      for (let i = 0; i < num; i++) {
        this.currentObjects.push(prevRack);
        prevRack = prevRack.copyBack(this.rackNumberingStep);
      }
    }
  }

  getClosestRack(point) {
    const rectangles = Object.values(this.objectsDict).filter(
      (object) => object.type === OBJECT_TYPES.RACK
    );
    if (rectangles.length === 0) return null;
    let minDistance = 100000;
    let minRack = null;
    for (let i = 0; i < rectangles.length; i++) {
      const rack = rectangles[i];
      const distance = Math.sqrt(
        (point.x - rack.center.x) ** 2 + (point.y - rack.center.y) ** 2
      );
      if (distance < minDistance) {
        minDistance = distance;
        minRack = rack;
      }
    }
    return minRack;
  }

  meterToPixel(meterPoint) {
    const pixelPoint = {
      x: Math.round(
        ((meterPoint.x - this.mapMetadata.origin[0]) /
          this.mapMetadata.resolution) *
          this.canvasInfo.scale
      ),
      y: Math.round(
        (this.imageOrigin.imageHeight -
          (meterPoint.y - this.mapMetadata.origin[1]) /
            this.mapMetadata.resolution) *
          this.canvasInfo.scale
      ),
    };
    return pixelPoint;
  }

  pixelToMeter(pixelPoint) {
    const meterPoint = {
      x:
        (pixelPoint.x / this.canvasInfo.scale) * this.mapMetadata.resolution +
        this.mapMetadata.origin[0],
      y:
        (this.imageOrigin.imageHeight - pixelPoint.y / this.canvasInfo.scale) *
          this.mapMetadata.resolution +
        this.mapMetadata.origin[1],
    };
    return meterPoint;
  }
}
