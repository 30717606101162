import styles from "styles/CanvasPanel.module.css";
import { Edge, Vertex, Rectangle, Drawing, Poi, Rack } from "features/Object";
import { useEffect, useState } from "react";

import { useDataContext } from "context/DataContext";

import { useDOMContext } from "context/DOMContext";
import usePreventDefault from "hooks/usePreventDefault";
import { useObjectContext } from "context/ObjectContext";
import InteractionManager from "components/canvasPanel/InteractionManager";
import { OBJECT_TYPES } from "common/TYPES";
import { useBeforeUnload } from "react-router-dom";
import useKeyboardEventHandlers from "hooks/useKeyboardEventHandlers";
const alertUser = (e) => {
  e.preventDefault();
  e.returnValue = "";
};

function getMousePos(canvas, evt, scale) {
  const rect = canvas.getBoundingClientRect();
  const event = {
    offsetX: Math.round(evt.clientX - rect.left - canvas.clientLeft) * scale,
    offsetY: Math.round(evt.clientY - rect.top - canvas.clientTop) * scale,
    ctrlKey: evt.ctrlKey,
    shiftKey: evt.shiftKey,
    altKey: evt.altKey,
    button: evt.button,
    deltaY: evt.deltaY,
  };
  return event;
}

const Canvas = ({ taskManagerRef }) => {
  const { canvasRef, canvasContainerRef } = useDOMContext();
  const {
    canvasInfo,
    setCanvasInfo,
    mapMetadata,
    topologyData,
    areaData,
    poiData,
    rackData,
    imageOrigin,
    setImageOrigin,
  } = useDataContext();

  const { objectsDict, setObjectsDict } = useObjectContext();

  const [keyboardEvent, setKeyboardEvent] = useState({
    action: "",
    event: {},
  });
  const [mouseEvent, setMouseEvent] = useState({
    action: "",
    event: {},
  });

  usePreventDefault("wheel", canvasContainerRef.current);
  usePreventDefault("contextmenu");
  useBeforeUnload(alertUser);
  useKeyboardEventHandlers(setKeyboardEvent);

  const [isCanvasLoaded, setIsCanvasLoaded] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  // init canvas
  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas) {
      const ctx = canvas.getContext("2d");
      ctx.imageSmoothingEnabled = false;
      ctx.textAlign = "center";

      const canvasWidth = Math.round(
        canvas.getBoundingClientRect().width * canvasInfo.scale
      );
      const canvasHeight = Math.round(
        canvas.getBoundingClientRect().height * canvasInfo.scale
      );
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      setCanvasInfo({
        canvasWidth: canvasWidth,
        canvasHeight: canvasHeight,
        scale: canvasInfo.scale,
      });
      setIsCanvasLoaded(true);
    }
  }, [canvasRef]);

  const [isOriginInitialized, setIsOriginInitialized] = useState(false);
  const { localizationMapImage } = useDataContext();
  useEffect(() => {
    const { canvasWidth, canvasHeight, scale } = canvasInfo;
    const mapImg = new Image();
    mapImg.src = localizationMapImage;

    mapImg.onload = () => {
      const imgWidth = mapImg ? mapImg.width * scale : 0;
      const imgHeight = mapImg ? mapImg.height * scale : 0;
      if (mapImg && isCanvasLoaded) {
        let scale = 1.0;
        if (canvasWidth < imgWidth || canvasHeight < imgHeight) {
          scale = Math.min(canvasWidth / imgWidth, canvasHeight / imgHeight);
          scale = scale * 0.9;
        }
        const newImageOrigin = {
          x: (canvasWidth - imgWidth * scale) / 2,
          y: (canvasHeight - imgHeight * scale) / 2,
          scale: scale,
          imageWidth: mapImg.width,
          imageHeight: mapImg.height,
        };
        setImageOrigin(newImageOrigin);
        setIsOriginInitialized(true);
      }
    };
  }, [localizationMapImage, isCanvasLoaded, canvasInfo, setImageOrigin]);

  // set loaded objects
  useEffect(() => {
    if (
      canvasInfo &&
      mapMetadata &&
      topologyData &&
      areaData &&
      poiData &&
      rackData &&
      !isDataLoaded &&
      isOriginInitialized
    ) {
      function meterToPixel(meterPoint) {
        const pixelPoint = {
          x: Math.round(
            ((meterPoint.x - mapMetadata.origin[0]) / mapMetadata.resolution) *
              canvasInfo.scale
          ),
          y: Math.round(
            (imageOrigin.imageHeight -
              (meterPoint.y - mapMetadata.origin[1]) / mapMetadata.resolution) *
              canvasInfo.scale
          ),
        };
        return pixelPoint;
      }
      const topologyDataLoaded = Object.keys(topologyData).length > 0;
      if (topologyDataLoaded) {
        const vertices = topologyData["Vertex"];
        if (vertices) {
          let verticesDict = {};
          const verticesKey = Object.keys(vertices);
          for (let i = 0; i < verticesKey.length; i++) {
            const vertex = vertices[verticesKey[i]];
            const meterPoint = { x: vertex["x"], y: vertex["y"] };
            const pixelPoint = meterToPixel(meterPoint);
            const id = vertex["id"].split("_")[1];
            const vertexObject = new Vertex();
            vertexObject.setID(id);
            vertexObject.setVertexType(vertex["type"]);
            vertexObject.setSize(0.5);
            vertexObject.setCenter(pixelPoint);
            verticesDict[vertexObject.id] = vertexObject;
            setObjectsDict({
              type: "ADD",
              payload: vertexObject,
            });
          }
          const edges = topologyData["Edge"];
          if (edges) {
            const edgesKey = Object.keys(edges);
            for (let i = 0; i < edgesKey.length; i++) {
              const edge = edges[edgesKey[i]];
              const edgeObject = new Edge();
              edgeObject.setEdgeType(edge["type"]);
              edgeObject.setVertices([edge["src"], edge["dst"]]);
              edgeObject.setID(`${edge["src"]}_${edge["dst"]}`);
              edgeObject.setSrcPoint(verticesDict[edge["src"]].center);
              edgeObject.setDstPoint(verticesDict[edge["dst"]].center);
              edgeObject.setVertexSize(0.5);
              edgeObject.setCost(edge["cost"]);
              edgeObject.setCenter();
              setObjectsDict({ type: "ADD", payload: edgeObject });
            }
          }
        }
      }
      const areas = areaData;
      const areaDataLoaded = Object.keys(areaData).length > 0;
      if (areaDataLoaded) {
        const areasKey = Object.keys(areas);
        for (let i = 0; i < areasKey.length; i++) {
          const object = areas[areasKey[i]];
          const meterCenterPoint = {
            x: object["center"].x,
            y: object["center"].y,
          };
          const pixelCenterPoint = meterToPixel(meterCenterPoint);
          const meterData = object["data"];
          const pixelData = meterData.map((point) => meterToPixel(point));
          if (
            object["type"] === OBJECT_TYPES.DRAWING ||
            object["type"] === OBJECT_TYPES.LINE
          ) {
            const config = {
              color: object.color,
              stroke: object["stroke"],
            };
            const areaObject = new Drawing(config);
            areaObject.setCenter(pixelCenterPoint);
            areaObject.setData(pixelData);
            areaObject.setYaw(object.yaw);
            areaObject.setType(object["type"]);
            setObjectsDict({ type: "ADD", payload: areaObject });
          } else if (object["type"] === OBJECT_TYPES.RECTANGLE) {
            const config = {
              color: object.color,
              fill: object["fill"],
            };
            const areaObject = new Rectangle(config);
            areaObject.setCenter(pixelCenterPoint);
            areaObject.setData(pixelData);
            areaObject.setYaw(object.yaw);
            setObjectsDict({ type: "ADD", payload: areaObject });
          }
        }
      }
      const pois = poiData;
      const poiDataLoaded = Object.keys(poiData).length > 0;
      if (poiDataLoaded) {
        const poisKey = Object.keys(pois);
        for (let i = 0; i < poisKey.length; i++) {
          const object = pois[poisKey[i]];
          const meterCenterPoint = {
            x: object["center"].x,
            y: object["center"].y,
          };
          const pixelCenterPoint = meterToPixel(meterCenterPoint);
          const poiObject = new Poi();
          poiObject.setCenter(pixelCenterPoint);
          poiObject.setYaw(-object["yaw"]);
          poiObject.setName(object["name"]);
          poiObject.setNumber(object["number"]);
          poiObject.setSize(object["size"]);
          setObjectsDict({ type: "ADD", payload: poiObject });
        }
      }
      const racks = rackData;
      const rackDataLoaded = Object.keys(rackData).length > 0;
      if (rackDataLoaded) {
        const racksKey = Object.keys(racks);
        for (let i = 0; i < racksKey.length; i++) {
          const object = racks[racksKey[i]];
          const meterCenterPoint = {
            x: object["center"].x,
            y: object["center"].y,
          };
          const pixelCenterPoint = meterToPixel(meterCenterPoint);
          const rackObject = new Rack();
          rackObject.setCenter(pixelCenterPoint);
          rackObject.yaw = -object["yaw"];
          rackObject.zoneId = object["zoneId"];
          rackObject.aisleId = object["aisleId"];
          rackObject.rackId = object["rackId"];
          rackObject.pickingSide = object["pickingSide"];

          rackObject.init(
            object.rackTypeName,
            object.rackType,
            object.locationCodePatternName,
            object.locationCodePattern,
            mapMetadata.resolution / canvasInfo.scale
          );
          rackObject.configure();
          setObjectsDict({ type: "ADD", payload: rackObject });
        }
      }
      setIsDataLoaded(true);
    }
  }, [
    canvasInfo,
    mapMetadata,
    topologyData,
    areaData,
    poiData,
    rackData,
    isOriginInitialized,
    isCanvasLoaded,
    isDataLoaded,
    setObjectsDict,
  ]);

  return (
    <>
      <canvas
        className={styles.canvas}
        ref={canvasRef}
        onMouseDown={(event) => {
          const mouseEvent = getMousePos(
            canvasRef.current,
            event,
            canvasInfo.scale
          );
          setMouseEvent({ action: "mousedown", event: mouseEvent });
        }}
        onMouseMove={(event) => {
          const mouseEvent = getMousePos(
            canvasRef.current,
            event,
            canvasInfo.scale
          );
          setMouseEvent({ action: "mousemove", event: mouseEvent });
        }}
        onMouseUp={(event) => {
          const mouseEvent = getMousePos(
            canvasRef.current,
            event,
            canvasInfo.scale
          );
          setMouseEvent({ action: "mouseup", event: mouseEvent });
        }}
        onMouseLeave={(event) => {
          const mouseEvent = getMousePos(
            canvasRef.current,
            event,
            canvasInfo.scale
          );
          setMouseEvent({ action: "mouseleave", event: mouseEvent });
        }}
        onWheel={(event) => {
          const mouseEvent = getMousePos(
            canvasRef.current,
            event,
            canvasInfo.scale
          );
          setMouseEvent({ action: "wheel", event: mouseEvent });
        }}
      >
        {isOriginInitialized && (
          <>
            <InteractionManager
              mouseEvent={mouseEvent}
              keyboardEvent={keyboardEvent}
              imageOrigin={imageOrigin}
              setImageOrigin={setImageOrigin}
              canvasRef={canvasRef}
              canvasInfo={canvasInfo}
              taskManagerRef={taskManagerRef}
            />
          </>
        )}
      </canvas>
    </>
  );
};

export default Canvas;
