import React from "react";
import ReactDOM from "react-dom/client";
import App from "App";
import { ObjectContext } from "context/ObjectContext";
import { ToolContext } from "context/ToolContext";
import { DOMContext } from "context/DOMContext";
import { DataContext } from "context/DataContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ToolContext>
    <ObjectContext>
      <DOMContext>
        <DataContext>{<App />}</DataContext>
      </DOMContext>
    </ObjectContext>
  </ToolContext>
);
