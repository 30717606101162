import { useEffect } from "react";

const usePreventDefault = (eventName, element = window) => {
  useEffect(() => {
    const preventDefault = (event) => {
      event.preventDefault();
    };

    element.addEventListener(eventName, preventDefault);

    return () => {
      element.removeEventListener(eventName, preventDefault);
    };
  }, [eventName, element]);
};

export default usePreventDefault;
