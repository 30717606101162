import {
  OBJECT_TYPES,
  OBJECT_TYPES_TO_CATEGORY_MAP,
  UI_TOOL_NAMES,
} from "common/TYPES";
import {
  transformPointToOriginalImageCoord,
  checkPointOnPoi,
} from "features/utils";
import { Poi } from "features/Object";

export class PoiTool {
  constructor(setObjectsDict) {
    this.setObjectsDict = setObjectsDict;
    this.objectsDict = {};
    this.imageOrigin = {};
    this.currentObjects = [];
    this.isGenerating = false;
    this.isUpdating = false;
    this.idDict = {};
    this.previousPoi = null;
    this.isPoiSettingModalEnabled = false;
  }
  init(
    imageOrigin,
    mapMetadata,
    canvas,
    canvasInfo,
    taskManager,
    setIsPoiSettingModalEnabled
  ) {
    this.imageOrigin = imageOrigin;
    this.mapMetadata = mapMetadata;
    this.canvas = canvas;
    this.canvasInfo = canvasInfo;
    this.canvasScale = canvasInfo.scale;
    this.taskManager = taskManager;
    this.setIsPoiSettingModalEnabled = setIsPoiSettingModalEnabled;
  }

  resetCurrentObjects() {
    this.currentObjects = [];
    this.previousPoi = null;
    this.setIsPoiSettingModalEnabled({
      enabled: false,
      object: null,
    });
  }

  updateModalEnabled(enabled) {
    this.isPoiSettingModalEnabled = enabled.enabled;
  }

  updateObjectsDict(objectsDict) {
    this.objectsDict = objectsDict;
    this.updateIdDict();
  }
  updateCanvasInfo(canvasInfo) {
    this.canvasInfo = canvasInfo;
  }

  updateIdDict() {
    const poiList = Object.values(this.objectsDict).filter(
      (object) => object.type === OBJECT_TYPES.POI
    );

    if (poiList !== undefined && poiList.length === 0) {
      this.idDict = {};
      this.idDict[this.config.name] = [1];
      return;
    }
    for (let i = 0; i < poiList.length; i++) {
      const poi = poiList[i];
      if (this.idDict[poi.name] === undefined) {
        this.idDict[poi.name] = [1];
      }
      // int id list
      const idList = poiList.map((poi_) => {
        if (poi.name === poi_.name) {
          return parseInt(poi_.number);
        } else {
          return 0;
        }
      });

      // 1부터 가장 큰 id 사이 빈 id 찾기
      const maxId = Math.max(...idList);
      this.idDict[poi.name] = [];
      for (let i = 1; i < maxId; i++) {
        if (!idList.includes(i)) {
          this.idDict[poi.name].push(i);
        }
      }
      // sort
      if (this.idDict[poi.name].length === 0) {
        this.idDict[poi.name].push(maxId + 1);
      } else {
        this.idDict[poi.name].sort((a, b) => b - a);
      }
    }
  }
  setTool(currentTool) {
    this.currentTool = currentTool;
    if (this.currentTool === UI_TOOL_NAMES.POI) {
      this.isActivated = true;
    } else {
      this.isActivated = false;
    }
  }
  updateImageOrigin(imageOrigin) {
    if (!imageOrigin) return;
    this.imageOrigin = imageOrigin;
  }

  updateConfig(config) {
    this.config = config;

    this.idDict = {};
    if (this.idDict[this.config.name] === undefined) {
      this.idDict[this.config.name] = [1];
    }
    this.updateIdDict();
  }

  listenMouseAction(mouseEvent) {
    if (!this.isActivated) return;
    const button = mouseEvent.event.button;
    const x = mouseEvent.event.offsetX;
    const y = mouseEvent.event.offsetY;

    let closestPoi, isCursorOnPoi;
    switch (mouseEvent.action) {
      case "mousedown":
        this.mouseDownPos = { x, y };
        const transformedMouseDownPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin
        );
        if (button === 0) {
          this.isMouseLeftDown = true;
        } else if (button === 1) {
          this.isMouseMiddleDown = true;
        } else if (button === 2) {
          this.isMouseRightDown = true;
        }

        closestPoi = this.getClosestPoi(transformedMouseDownPos);

        isCursorOnPoi = checkPointOnPoi(
          transformedMouseDownPos,
          closestPoi,
          this.mapMetadata.resolution
        );
        // open poi setting modal
        if (this.isMouseRightDown && isCursorOnPoi) {
          this.setIsPoiSettingModalEnabled({
            enabled: true,
            object: closestPoi,
          });
        } else {
          this.setIsPoiSettingModalEnabled({
            enabled: false,
            object: null,
          });
        }

        if (
          this.isMouseLeftDown &&
          !isCursorOnPoi &&
          !this.isPoiSettingModalEnabled
        ) {
          this.isGenerating = true;
          const newPoi = new Poi();
          newPoi.center = transformedMouseDownPos;
          newPoi.name = this.config.name;
          newPoi.number = this.idDict[this.config.name].pop();
          this.currentObjects = [newPoi];
        } else if (
          this.isMouseLeftDown &&
          isCursorOnPoi &&
          !this.isPoiSettingModalEnabled
        ) {
          this.isUpdating = true;
          this.currentObjects = [closestPoi];
          this.previousPoi = JSON.parse(JSON.stringify(closestPoi));
          Object.setPrototypeOf(
            this.previousPoi,
            Object.getPrototypeOf(closestPoi)
          );
        }

        break;
      case "mousemove":
        this.mouseCurrentPos = { x, y };
        const transformedMouseCurrentPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin
        );

        closestPoi = this.getClosestPoi(transformedMouseCurrentPos);
        isCursorOnPoi = checkPointOnPoi(
          transformedMouseCurrentPos,
          closestPoi,
          this.mapMetadata.resolution
        );
        if (isCursorOnPoi && !this.isMouseLeftDown) {
          this.currentObjects = [];
          if (this.isSpacePressed) {
            this.canvas.style.cursor = "move";
          } else {
            this.canvas.style.cursor = "pointer";
          }
        } else {
          this.canvas.style.cursor = "default";
        }

        if (
          this.isMouseLeftDown &&
          this.isGenerating &&
          !this.isUpdating &&
          !this.isPoiSettingModalEnabled
        ) {
          // get yaw
          const deltaX = this.mouseCurrentPos.x - this.mouseDownPos.x;
          const deltaY = this.mouseCurrentPos.y - this.mouseDownPos.y;
          const yaw = Math.atan2(deltaY, deltaX);
          this.currentObjects[0].setYaw(yaw);
        } else if (
          !this.isMouseLeftDown &&
          !this.isGenerating &&
          !isCursorOnPoi &&
          !this.isPoiSettingModalEnabled
        ) {
          const previewPoi = new Poi();
          previewPoi.center = transformedMouseCurrentPos;
          previewPoi.name = this.config.name;
          previewPoi.number =
            this.idDict[this.config.name][
              this.idDict[this.config.name].length - 1
            ];
          this.currentObjects = [previewPoi];
        } else if (this.isUpdating && this.isMouseLeftDown) {
          if (this.isSpacePressed) {
            // move
            this.currentObjects[0].setCenter(transformedMouseCurrentPos);
          } else {
            // get yaw
            const deltaX = this.mouseCurrentPos.x - this.mouseDownPos.x;
            const deltaY = this.mouseCurrentPos.y - this.mouseDownPos.y;
            const yaw = Math.atan2(deltaY, deltaX);
            this.currentObjects[0].setYaw(yaw);
          }
        }

        break;

      case "mouseleave":
      case "mouseup":
        this.mouseUpPos = { x, y };
        this.isMouseMiddleDown = false;
        this.isMouseRightDown = false;
        const transformedMouseUpPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin
        );

        if (this.isMouseLeftDown && this.isGenerating) {
          this.isMouseLeftDown = false;
          this.isGenerating = false;

          if (this.currentObjects.length > 0) {
            this.setObjectsDict({
              type: "ADD",
              payload: this.currentObjects[0],
            });
            this.taskManager.addHistory(this.currentObjects, "ADD");
          }
          this.resetCurrentObjects();
        } else if (this.isMouseLeftDown && this.isUpdating) {
          this.isMouseLeftDown = false;
          this.isUpdating = false;

          if (this.currentObjects.length > 0) {
            this.setObjectsDict({
              type: "UPDATE",
              payload: this.currentObjects[0],
            });
            this.taskManager.addHistory([this.previousPoi], "UPDATE");
          }
          this.resetCurrentObjects();
        } else if (this.isMouseLeftDown) {
          this.isMouseLeftDown = false;
          this.isGenerating = false;
          this.isUpdating = false;
          this.resetCurrentObjects();
        }

        break;

      default:
        break;
    }
  }

  listenKeyboardAction(keyboardEvent) {
    const event = keyboardEvent.event;
    const action = keyboardEvent.action;
    const key = event.key;

    switch (action) {
      case "keydown":
        switch (key) {
          case " ":
            this.isSpacePressed = true;
            break;

          default:
            break;
        }
        break;
      case "keyup":
        switch (key) {
          case " ":
            this.isSpacePressed = false;
            break;

          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  getClosestPoi(point) {
    const poiList = Object.values(this.objectsDict).filter(
      (object) => object.type === OBJECT_TYPES.POI
    );
    if (poiList.length === 0) return null;
    let minDistance = 100000;
    let minPoi = null;
    for (let i = 0; i < poiList.length; i++) {
      const poi = poiList[i];
      const distance = Math.sqrt(
        (point.x - poi.center.x) ** 2 + (point.y - poi.center.y) ** 2
      );
      if (distance < minDistance) {
        minDistance = distance;
        minPoi = poi;
      }
    }
    return minPoi;
  }

  meterToPixel(meterPoint) {
    const pixelPoint = {
      x: Math.round(
        ((meterPoint.x - this.mapMetadata.origin[0]) /
          this.mapMetadata.resolution) *
          this.canvasInfo.scale
      ),
      y: Math.round(
        (this.imageOrigin.imageHeight -
          (meterPoint.y - this.mapMetadata.origin[1]) /
            this.mapMetadata.resolution) *
          this.canvasInfo.scale
      ),
    };
    return pixelPoint;
  }

  pixelToMeter(pixelPoint) {
    const meterPoint = {
      x:
        (pixelPoint.x / this.canvasInfo.scale) * this.mapMetadata.resolution +
        this.mapMetadata.origin[0],
      y:
        (this.imageOrigin.imageHeight - pixelPoint.y / this.canvasInfo.scale) *
          this.mapMetadata.resolution +
        this.mapMetadata.origin[1],
    };
    return meterPoint;
  }
}
