import { useEffect, useState } from "react";

const useDetectClose = (elem, initialState) => {
  const [isOpen, setIsOpen] = useState(initialState);

  useEffect(() => {
    const onClick = (e) => {
      if (
        (elem.current !== null && elem.current.contains(e.target)) ||
        elem.current.nextElementSibling.contains(e.target)
      ) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      window.addEventListener("mousedown", onClick);
      window.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          setIsOpen(false);
        }
      });
    }

    return () => {
      window.removeEventListener("mousedown", onClick);
      window.removeEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          setIsOpen(false);
        }
      });
    };
  }, [isOpen, elem]);
  return [isOpen, setIsOpen];
};

export default useDetectClose;
