import React from "react";
import styles from "styles/LocationCodePatternSettingModal.module.css";
import { useToolContext } from "context/ToolContext";
import { useDataContext } from "context/DataContext";
import { useEffect, useRef, useState } from "react";
import { getLocationCode } from "features/utils";
import { ROBOT_STUDIO_API } from "common/API";
import Snackbar from "components/canvasPanel/Snackbar";

const saveLocationCodePatternUrl = ROBOT_STUDIO_API.POST_LOCATION_CODE_PATTERN;

const patternItems = ["Zone", "Aisle", "Rack", "Level", "Cell", "-"];
const SnackbarType = {
  success: "success",
  fail: "fail",
};

function LocationPatternSettingModal() {
  const snackbarSuccessRef = useRef(null);
  const snackbarFailRef = useRef(null);

  const [patternName, setPatternName] = useState("");
  const [stringPattern, setStringPattern] = useState("");
  const [cellScheme, setCellScheme] = useState("normal");
  const [digitPattern, setDigitPattern] = useState({
    Z: 0,
    A: 0,
    R: 0,
    L: 0,
    C: 0,
  });

  const [codeConfig, setCodeConfig] = useState({
    Z: "A",
    A: 1,
    R: 2,
    L: 3,
    C: 4,
  });

  const [selectedPatternName, setSelectedPatternName] = useState(null);
  const [isSaveCalled, setIsSaveCalled] = useState(false);

  const {
    isLocationCodePatternSettingModalEnabled,
    setIsLocationCodePatternSettingModalEnabled,
  } = useToolContext();

  const { locationCodePatternsData, setLocationCodePatternsData } =
    useDataContext();

  const saveLocationCodePatternData = async (locationCodePatternData) => {
    try {
      const response = await fetch(saveLocationCodePatternUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any additional headers if needed
        },
        body: JSON.stringify({
          data: locationCodePatternData,
          branch_name: "none",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to send locationCodePattern data to server");
      }
      console.log("locationCodePattern data sent successfully to server");
      // You can handle the response from the server here if needed
    } catch (error) {
      console.error(
        "Error sending locationCodePattern data to server:",
        error.message
      );
    }
  };

  useEffect(() => {
    if (isSaveCalled) {
      saveLocationCodePatternData(locationCodePatternsData);
      setIsSaveCalled(false);
      snackbarSuccessRef.current.show("로캐이션 코드 패턴 저장 완료");
    }
  }, [isSaveCalled]);

  useEffect(() => {
    if (Object.keys(locationCodePatternsData).length === 0) {
      return;
    }
    setSelectedPatternName(Object.keys(locationCodePatternsData)[0]);
    const stringPattern =
      locationCodePatternsData[Object.keys(locationCodePatternsData)[0]]
        .string_pattern;
    const digitPattern =
      locationCodePatternsData[Object.keys(locationCodePatternsData)[0]]
        .digit_pattern;

    setStringPattern(stringPattern);
    setDigitPattern(digitPattern);
  }, []);

  useEffect(() => {
    const patternItems = document.querySelectorAll(`.${styles.patternItem}`);
    patternItems.forEach((item) => {
      if (item.id === selectedPatternName) {
        item.style.backgroundColor = "rgba(50,190,255, 0.3)";
      } else {
        item.style.backgroundColor = "white";
      }
    });
    if (selectedPatternName !== null) {
      setPatternName(selectedPatternName);
      const stringPattern =
        locationCodePatternsData[selectedPatternName].string_pattern;
      const digitPattern =
        locationCodePatternsData[selectedPatternName].digit_pattern;

      const stringPatternList = stringPattern.split("");
      let stringPatternSpread = "";
      for (let i = 0; i < stringPatternList.length; i++) {
        if (
          digitPattern[stringPatternList[i]] === 0 ||
          digitPattern[stringPatternList[i]] === undefined
        ) {
          stringPatternSpread += stringPatternList[i];
        } else {
          for (let j = 0; j < digitPattern[stringPatternList[i]]; j++) {
            stringPatternSpread += stringPatternList[i];
          }
        }
      }

      setStringPattern(stringPatternSpread);
      setDigitPattern(digitPattern);
      if (
        locationCodePatternsData[selectedPatternName].cell_scheme !== undefined
      ) {
        setCellScheme(
          locationCodePatternsData[selectedPatternName].cell_scheme
        );
      } else {
        setCellScheme("normal");
      }
    }
  }, [selectedPatternName]);

  useEffect(() => {
    const numZ = stringPattern.split("Z").length - 1;
    const numA = stringPattern.split("A").length - 1;
    const numR = stringPattern.split("R").length - 1;
    const numL = stringPattern.split("L").length - 1;
    const numC = stringPattern.split("C").length - 1;
    const digitPattern = {
      Z: numZ,
      A: numA,
      R: numR,
      L: numL,
      C: numC,
    };
    setDigitPattern(digitPattern);
  }, [stringPattern]);

  useEffect(() => {
    // 이미지 테두리 색상 변경
    const cellSchemes = document.querySelectorAll(`.${styles.cellSchemeImage}`);
    cellSchemes.forEach((item) => {
      if (item.alt === cellScheme) {
        item.style.border = "5px solid skyblue";
        item.style.borderRadius = "10px";
      } else {
        item.style.border = "2px solid white";
      }
    });
    document.getElementById(cellScheme).checked = true;
  }, [cellScheme]);

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.containerHeader}>
          <div className={styles.containerTitle}>로케이션 코드 패턴 설정</div>

          <button
            className={styles.closeBtn}
            onClick={() => {
              setIsLocationCodePatternSettingModalEnabled(false);
            }}
          >
            X
          </button>
        </div>
        <div className={styles.body}>
          <div className={styles.leftPanel}>
            <div className={styles.panelHeader}>
              <span className={styles.panelTitle}>리스트</span>
            </div>
            <div className={styles.patternList}>
              {Object.keys(locationCodePatternsData).map((patternName) => (
                <div
                  key={patternName}
                  className={styles.patternItem}
                  id={patternName}
                >
                  <div
                    className={styles.patternItemText}
                    onClick={() => {
                      setSelectedPatternName(patternName);
                      const stringPattern =
                        locationCodePatternsData[patternName].string_pattern;
                      const digitPattern =
                        locationCodePatternsData[patternName].digit_pattern;

                      const stringPatternList = stringPattern.split("");
                      let stringPatternSpread = "";
                      for (let i = 0; i < stringPatternList.length; i++) {
                        if (
                          digitPattern[stringPatternList[i]] === 0 ||
                          digitPattern[stringPatternList[i]] === undefined
                        ) {
                          stringPatternSpread += stringPatternList[i];
                        } else {
                          for (
                            let j = 0;
                            j < digitPattern[stringPatternList[i]];
                            j++
                          ) {
                            stringPatternSpread += stringPatternList[i];
                          }
                        }
                      }
                      setStringPattern(stringPatternSpread);
                      setDigitPattern(digitPattern);
                    }}
                  >
                    {patternName}
                  </div>

                  <img
                    className={styles.deleteBtn}
                    src="/assets/icons/delete.svg"
                    alt="delete"
                    onClick={() => {
                      const newPatternData = { ...locationCodePatternsData };
                      delete newPatternData[patternName];
                      setLocationCodePatternsData(newPatternData);

                      if (patternName === selectedPatternName) {
                        if (Object.keys(newPatternData).length > 0) {
                          setSelectedPatternName(
                            Object.keys(newPatternData)[0]
                          );
                        } else {
                          setSelectedPatternName(null);
                        }
                      }
                    }}
                  />
                </div>
              ))}
            </div>
            <div
              className={styles.addBtn}
              onClick={() => {
                const newPatternData = { ...locationCodePatternsData };
                newPatternData[
                  `pattern_${Object.keys(locationCodePatternsData).length + 1}`
                ] = {
                  string_pattern: "",
                  digit_pattern: {
                    Z: 0,
                    A: 0,
                    R: 0,
                    L: 0,
                    C: 0,
                  },
                };
                setLocationCodePatternsData(newPatternData);
                setSelectedPatternName(
                  `pattern_${Object.keys(locationCodePatternsData).length + 1}`
                );
                setStringPattern("");
                setDigitPattern({
                  Z: 0,
                  A: 0,
                  R: 0,
                  L: 0,
                  C: 0,
                });
              }}
            >
              새로 생성하기
            </div>
          </div>
          <div className={styles.mainPanel}>
            <div className={styles.panelHeader}>
              <div className={styles.panelTitleContainer}>
                <span className={styles.panelTitle}>타입 이름</span>
              </div>
              <div className={styles.textInputContainer}>
                <input
                  className={styles.textInput}
                  type="text"
                  value={patternName}
                  onChange={(e) => {
                    setPatternName(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    //방향키 입력시 좌우 커서 이동
                    if (e.key === "ArrowLeft" && !e.shiftKey) {
                      const input = e.target;
                      const position = input.selectionStart;
                      input.setSelectionRange(position - 1, position - 1);
                    } else if (e.key === "ArrowRight" && !e.shiftKey) {
                      const input = e.target;

                      const position = input.selectionStart;
                      input.setSelectionRange(position + 1, position + 1);
                    }

                    // Ctrl + A 입력시 전체선택
                    if (e.ctrlKey && (e.key === "a" || e.key === "A")) {
                      e.target.select();
                    }

                    // Ctrl+c, Ctrl+v, Ctrl+x 선택된 텍스트만 입력시 복사, 붙여넣기, 잘라내기
                    if (e.ctrlKey && (e.key === "c" || e.key === "C")) {
                      e.preventDefault();
                      const input = e.target;
                      const selectedText = input.value.slice(
                        input.selectionStart,
                        input.selectionEnd
                      );
                      navigator.clipboard.writeText(selectedText);
                    } else if (e.ctrlKey && (e.key === "v" || e.key === "V")) {
                      e.preventDefault();
                      navigator.clipboard.readText().then((text) => {
                        const input = e.target;
                        const position = input.selectionStart;
                        const beforeText = input.value.slice(0, position);
                        const afterText = input.value.slice(position);
                        input.value = beforeText + text + afterText;
                      });
                    } else if (e.ctrlKey && (e.key === "x" || e.key === "X")) {
                      e.preventDefault();
                      const input = e.target;
                      const beforeText = input.value.slice(
                        0,
                        input.selectionStart
                      );
                      const selectedText = input.value.slice(
                        input.selectionStart,
                        input.selectionEnd
                      );
                      const afterText = input.value.slice(input.selectionEnd);
                      navigator.clipboard.writeText(selectedText);
                      input.value = beforeText + afterText;
                    }
                  }}
                />
              </div>
              <button
                className={styles.saveBtn}
                onClick={() => {
                  if (
                    selectedPatternName !== patternName &&
                    Object.keys(locationCodePatternsData).includes(patternName)
                  ) {
                    snackbarFailRef.current.show("이미 존재하는 이름입니다.");
                    return;
                  }
                  if (selectedPatternName !== null) {
                    if (patternName === "") {
                      snackbarFailRef.current.show("이름을 입력해주세요.");
                      return;
                    } else if (stringPattern === "") {
                      snackbarFailRef.current.show("패턴을 입력해주세요.");
                      return;
                    } else {
                      const numZ = stringPattern.split("Z").length - 1;
                      const numA = stringPattern.split("A").length - 1;
                      const numR = stringPattern.split("R").length - 1;
                      const numL = stringPattern.split("L").length - 1;
                      const numC = stringPattern.split("C").length - 1;
                      const digitPattern = {
                        Z: numZ,
                        A: numA,
                        R: numR,
                        L: numL,
                        C: numC,
                      };
                      const newPatternData = { ...locationCodePatternsData };
                      newPatternData[patternName] = {
                        string_pattern: stringPattern.replace(/(.)\1+/g, "$1"),
                        digit_pattern: digitPattern,
                        cell_scheme: cellScheme,
                      };
                      if (patternName !== selectedPatternName) {
                        delete newPatternData[selectedPatternName];
                      }
                      setSelectedPatternName(patternName);
                      setLocationCodePatternsData(newPatternData);
                      setIsSaveCalled(true);
                    }
                  }
                }}
              >
                저장
              </button>
            </div>
            <div
              className={styles.patternItemContainerLabel}
              style={{ marginTop: "25px" }}
            >
              {" "}
              로케이션 코드 패턴
            </div>
            <div className={styles.LocationPatternContainer}>
              <div className={styles.LocationPatternSettingContainer}>
                <div className={styles.patternItemContainer}>
                  <div className={styles.itemLabel}> 아이템 : </div>
                  {patternItems.map((item) => (
                    <div key={item}>
                      <div
                        className={styles.itemCard}
                        onClick={() => {
                          const head = item.charAt(0);
                          if (stringPattern.includes(head) && head !== "-") {
                            if (
                              stringPattern.charAt(stringPattern.length - 1) !==
                              head
                            ) {
                              return;
                            }
                          }
                          setStringPattern(stringPattern + item.charAt(0));
                        }}
                      >
                        <p className={styles.cardLabel}>{item}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={styles.patternResultContainer}>
                  <div className={styles.itemLabel}> 패턴 : </div>
                  <span className={styles.patternResult}>{stringPattern}</span>
                  <div
                    className={styles.patternDelBtn}
                    onClick={(e) => {
                      e.preventDefault();
                      // prevent double click selection of text
                      document.getSelection().removeAllRanges();

                      setStringPattern(stringPattern.slice(0, -1));
                    }}
                  >
                    지우기
                  </div>
                </div>
              </div>
              <div className={styles.patternPreviewContainer}>
                <>
                  <div className={styles.patternPreviewInputContainer}>
                    {["Z", "A", "R", "L", "C"].map((item) => (
                      <div key={item} className={styles.inputBox}>
                        <span className={styles.inputLabel}>{`${item} :`}</span>
                        <input
                          type="text"
                          className={styles.patternPreviewInput}
                          value={codeConfig[item]}
                          onClick={(e) => {
                            // select all text when clicked
                            e.target.select();
                          }}
                          onChange={(e) => {
                            setCodeConfig({
                              ...codeConfig,
                              [item]: e.target.value,
                            });
                          }}
                          onKeyDown={(e) => {
                            // Tab 또는 Enter 입력시 다음 input으로 이동
                            if (
                              (e.key === "Tab" || e.key === "Enter") &&
                              !e.shiftKey &&
                              e.target.parentElement.nextSibling
                            ) {
                              e.preventDefault();
                              const nextInput =
                                e.target.parentElement.nextSibling.querySelector(
                                  `.${styles.patternPreviewInput}`
                                );
                              if (nextInput) {
                                nextInput.focus();
                              }
                            } else if (
                              e.key === "Tab" &&
                              e.shiftKey &&
                              e.target.parentElement.previousSibling
                            ) {
                              e.preventDefault();
                              const prevInput =
                                e.target.parentElement.previousSibling.querySelector(
                                  `.${styles.patternPreviewInput}`
                                );
                              if (prevInput) {
                                prevInput.focus();
                              }
                            }

                            // 방향키 입력시 좌우 커서 이동
                            if (e.key === "ArrowLeft" && !e.shiftKey) {
                              const input = e.target;
                              const position = input.selectionStart;
                              input.setSelectionRange(
                                position - 1,
                                position - 1
                              );
                            } else if (e.key === "ArrowRight" && !e.shiftKey) {
                              const input = e.target;

                              const position = input.selectionStart;
                              input.setSelectionRange(
                                position + 1,
                                position + 1
                              );
                            }
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </>
                <div className={styles.patternPreview}>
                  <span className={styles.patternPreviewLabel}>
                    로케이션코드 미리보기
                  </span>
                  <div className={styles.patternPreviewResult}>
                    {selectedPatternName !== null &&
                      getLocationCode(codeConfig, {
                        string_pattern: stringPattern.replace(/(.)\1+/g, "$1"),
                        digit_pattern: digitPattern,
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.cellSchemeContainer}>
              <div
                className={styles.patternItemContainerLabel}
                style={{ marginTop: "25px" }}
              >
                {" "}
                셀 넘버링 스킴
              </div>
              <div className={styles.cellSchemeInputContainer}>
                <div className={styles.cellSchemeInputBox}>
                  <input
                    type="radio"
                    id="normal"
                    name="scheme"
                    onClick={() => {
                      setCellScheme("normal");
                    }}
                  />
                  <img
                    className={styles.cellSchemeImage}
                    htmlFor="normal"
                    src="/assets/buttons/normal-scheme.svg"
                    alt="normal"
                    onClick={() => {
                      // check radio button
                      document.getElementById("normal").checked = true;
                      setCellScheme("normal");
                    }}
                  ></img>
                </div>
                <div className={styles.cellSchemeInputBox}>
                  <input
                    type="radio"
                    id="N-type"
                    name="scheme"
                    onClick={() => {
                      // check radio button
                      document.getElementById("N-type").checked = true;
                      setCellScheme("N-type");
                    }}
                  />
                  <img
                    className={styles.cellSchemeImage}
                    htmlFor="N-type"
                    src="/assets/buttons/N-scheme.svg"
                    alt="N-type"
                    onClick={() => {
                      // check radio button
                      document.getElementById("N-type").checked = true;
                      setCellScheme("N-type");
                    }}
                  ></img>
                </div>
                <div className={styles.cellSchemeInputBox}>
                  <input
                    type="radio"
                    id="Z-type"
                    name="scheme"
                    onClick={() => {
                      setCellScheme("Z-type");
                    }}
                  />
                  <img
                    className={styles.cellSchemeImage}
                    htmlFor="Z-type"
                    src="/assets/buttons/Z-scheme.svg"
                    alt="Z-type"
                    onClick={() => {
                      // check radio button
                      document.getElementById("Z-type").checked = true;
                      setCellScheme("Z-type");
                    }}
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        <Snackbar ref={snackbarSuccessRef} type={SnackbarType.success} />
      </>
      <>
        <Snackbar ref={snackbarFailRef} type={SnackbarType.fail} />
      </>
    </div>
  );
}

export default LocationPatternSettingModal;
