import { useEffect, useState, useRef } from "react";
import styles from "styles/LeftPanel.module.css";
import { getActiveCategoryColor } from "features/utils";
import { useObjectContext } from "context/ObjectContext";
import { useToolContext } from "context/ToolContext";
import { UI_TOOL_NAMES, OBJECT_TYPES_TO_CATEGORY_MAP } from "common/TYPES";

function MainCategory({ title, type, parentRef, searchTerm }) {
  const mainCategoryRef = useRef(null);
  const visibleButtonRef = useRef(null);
  const [isLock, setIsLock] = useState(true);
  const [isInvisible, setIsInvisible] = useState(false);

  const filterObject = (object) => {
    if (searchTerm === "") {
      return true;
    }
    if (object.name.toLowerCase().includes(searchTerm.toLowerCase())) {
      return true;
    }
    return false;
  };

  const {
    objectsDict,
    setObjectsDict,
    selectedObjectsList,
    setSelectedObjectsList,
    invisibleObjectsList,
    setInvisibleObjectsList,
    activeObjectCategory,
    setActiveObjectCategory,
  } = useObjectContext();

  const { currentTool } = useToolContext();

  useEffect(() => {}, []);

  // hover
  useEffect(() => {
    mainCategoryRef.current.addEventListener("mouseover", (e) => {
      if (currentTool === UI_TOOL_NAMES.CURSOR) {
        if (activeObjectCategory !== type) {
          mainCategoryRef.current.style.backgroundColor =
            getActiveCategoryColor(type, 0.3);
          mainCategoryRef.current.style.cursor = "pointer";
        }
      } else {
        if (activeObjectCategory !== type) {
          mainCategoryRef.current.style.backgroundColor = "transparent";
          mainCategoryRef.current.style.cursor = "default";
        }
      }
    });
    mainCategoryRef.current.addEventListener("mouseout", (e) => {
      if (activeObjectCategory !== type) {
        mainCategoryRef.current.style.backgroundColor = "transparent";
        mainCategoryRef.current.style.cursor = "default";
      } else {
        mainCategoryRef.current.style.backgroundColor = getActiveCategoryColor(
          type,
          0.3
        );
        mainCategoryRef.current.style.cursor = "default";
      }
    });

    return () => {
      // mainCategoryRef.current.removeEventListener("mouseover", () => {});
      // mainCategoryRef.current.removeEventListener("mouseout", () => {});
    };
  }, [mainCategoryRef, currentTool, activeObjectCategory, type]);

  useEffect(() => {
    if (activeObjectCategory === type) {
      mainCategoryRef.current.style.backgroundColor = getActiveCategoryColor(
        type,
        0.3
      );
      setIsLock(false);
    } else {
      mainCategoryRef.current.style.backgroundColor = "transparent";
      setIsLock(true);
    }
  }, [activeObjectCategory, type]);

  useEffect(() => {
    let invisibleList = [...invisibleObjectsList];
    for (let key in objectsDict) {
      if (OBJECT_TYPES_TO_CATEGORY_MAP[objectsDict[key]["type"]] === type) {
        if (isInvisible && invisibleList.indexOf(key) === -1) {
          invisibleList.push(key);
        } else if (!isInvisible && invisibleList.indexOf(key) !== -1) {
          invisibleList = invisibleList.filter((item) => item !== key);
        }
      }
    }
    if (selectedObjectsList.length > 0) {
      setSelectedObjectsList([]);
    }
    setInvisibleObjectsList(invisibleList);
  }, [isInvisible]);

  return (
    <>
      <div
        className={styles.mainCategory}
        ref={mainCategoryRef}
        onClick={(e) => {
          if (e.target !== visibleButtonRef.current) {
            if (currentTool === UI_TOOL_NAMES.CURSOR) {
              if (activeObjectCategory !== type) {
                setActiveObjectCategory(type);

                if (selectedObjectsList.length > 0) {
                  setSelectedObjectsList([]);
                }
              }
            }
          }
        }}
      >
        <div className={styles.lockButtonContainer}>
          {isLock ? (
            <img
              className={styles.lockButton}
              src="/assets/icons/lock.svg"
              alt="lock"
            />
          ) : (
            <img
              className={styles.lockButton}
              src="/assets/icons/lock-open.svg"
              alt="lock-open"
            />
          )}
        </div>
        <div className={styles.mainCategoryTitle}>{title}</div>
        <div className={styles.visibleOptionButtonContainer}>
          {isInvisible ? (
            <img
              className={styles.visibleOptionButton}
              ref={visibleButtonRef}
              src="/assets/icons/eye-closed.svg"
              alt="eye-closed"
              onClick={(e) => {
                if (e.target === visibleButtonRef.current) {
                  setIsInvisible(!isInvisible);
                }
              }}
            />
          ) : (
            <img
              className={styles.visibleOptionButton}
              ref={visibleButtonRef}
              src="/assets/icons/eye-open.svg"
              alt="eye-open"
              onClick={(e) => {
                if (e.target === visibleButtonRef.current) {
                  setIsInvisible(!isInvisible);
                }
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default MainCategory;
