import styles from "styles/Flyout.module.css";
import { useToolContext } from "context/ToolContext";
import { useObjectContext } from "context/ObjectContext";
import { OBJECT_TYPES } from "common/TYPES";
import { useState } from "react";
import { COLOR } from "common/COLOR";
import { Rectangle } from "features/Object";

function RackFlyout() {
  const {
    setIsRackTypeSettingModalEnabled,
    setIsLocationCodePatternSettingModalEnabled,
    taskManagerRef,
  } = useToolContext();
  const {
    objectsDict,
    setObjectsDict,
    selectedObjectsList,
    setSelectedObjectsList,
    invisibleObjectsList,
    activeObjectCategory,
    setActiveObjectCategory,
    hoveredObject,
    setHoveredObject,
  } = useObjectContext();
  return (
    <div className={styles.flyoutContents}>
      <div className={styles.flyoutItem}>
        {" "}
        <div
          className={styles.Button}
          onClick={() => {
            setIsRackTypeSettingModalEnabled(true);
          }}
        >
          랙 타입 설정
        </div>
      </div>
      <div className={styles.flyoutItem}>
        {" "}
        <div
          className={styles.Button}
          onClick={() => {
            setIsLocationCodePatternSettingModalEnabled(true);
          }}
        >
          로케이션코드 패턴 설정
        </div>
      </div>
      <div className={styles.flyoutItem}>
        {" "}
        <div
          className={styles.Button}
          onClick={() => {
            const history = [];
            const racks = Object.values(objectsDict).filter(
              (object) => object.type === OBJECT_TYPES.RACK
            );

            for (let i = 0; i < racks.length; i++) {
              const rack = racks[i];
              const rectangle = new Rectangle({
                color: COLOR.OCCUPIED,
                fill: true,
              });
              const yaw = rack.yaw;
              rack.rotate(rack.center, -yaw);
              rectangle.setStartPoint(rack.data[0]);
              rectangle.setEndPoint(rack.data[2]);
              rectangle.setCenter();
              rectangle.orderPoints();
              rectangle.rotate(rectangle.center, yaw);
              rack.rotate(rack.center, yaw);
              console.log(rack);
              console.log(rectangle);
              setObjectsDict({
                type: "ADD",
                payload: rectangle,
              });
              history.push(rectangle);
            }
            taskManagerRef.current.addHistory(history, "ADD");
          }}
        >
          네비게이션 영역 생성
        </div>
      </div>
    </div>
  );
}

export default RackFlyout;
