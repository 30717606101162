import React from "react";
import styles from "styles/RackTypeSettingModal.module.css";
import { useToolContext } from "context/ToolContext";
import { useDataContext } from "context/DataContext";
import { useEffect, useRef, useState } from "react";
import { RACK_TYPES } from "common/TYPES";
import { ROBOT_STUDIO_API } from "common/API";
import Snackbar from "components/canvasPanel/Snackbar";

const saveRackTypeUrl = ROBOT_STUDIO_API.POST_RACK_TYPE;
const SnackbarType = {
  success: "success",
  fail: "fail",
};

// 회전 행렬 적용 함수 (Y축 회전)
function rotateY(vertex, angle) {
  const cosA = Math.cos(angle);
  const sinA = Math.sin(angle);
  return {
    x: vertex.x * cosA - vertex.z * sinA,
    y: vertex.y,
    z: vertex.x * sinA + vertex.z * cosA,
  };
}

// 회전 행렬 적용 함수 (X축 회전)
function rotateX(vertex, angle) {
  const cosA = Math.cos(angle);
  const sinA = Math.sin(angle);
  return {
    x: vertex.x,
    y: vertex.y * cosA - vertex.z * sinA,
    z: vertex.y * sinA + vertex.z * cosA,
  };
}

function rotateZ(vertex, angle) {
  const cosA = Math.cos(angle);
  const sinA = Math.sin(angle);
  return {
    x: vertex.x * cosA - vertex.y * sinA,
    y: vertex.x * sinA + vertex.y * cosA,
    z: vertex.z,
  };
}

// 투영 함수
function project(vertex, width, height, fov, viewerDistance) {
  const factor = fov / (viewerDistance + vertex.z);
  const x = vertex.x * factor + width / 2;
  const y = -vertex.y * factor + height / 2;
  return { x, y };
}

// 화면에 그리기
function drawRack(
  vertices,
  edges,
  cells,
  textPoints,
  width,
  height,
  rackWidth,
  rackHeight,
  rackDepth,
  numLevels,
  levelHeights,
  numCells,
  rackType,
  fov,
  viewerDistance,
  angleX,
  angleY,
  angleZ,
  ctx
) {
  ctx.clearRect(0, 0, width, height);
  ctx.lineCap = "round";
  ctx.lineJoin = "bevel";

  // 각 꼭짓점을 회전하고 투영
  let rotated = vertices.map((v) => rotateY(v, angleY));
  rotated = rotated.map((v) => rotateX(v, angleX));
  rotated = rotated.map((v) => rotateZ(v, angleZ));
  const projected = rotated.map((v) =>
    project(v, width, height, fov, viewerDistance)
  );

  // 직육면체의 각 모서리를 그리기
  if (rackType === RACK_TYPES[0]) {
    ctx.strokeStyle = "rgba(250, 250, 250, 1.0)";
  } else if (rackType === RACK_TYPES[1]) {
    ctx.strokeStyle = "rgba(0, 0, 255, 1.0)";
  }
  ctx.lineWidth = 10;
  const pilarEdges = edges.slice(0, 4);
  let frontPillarEdges = [];
  let backPillarEdges = [];
  if (angleY < 0) {
    frontPillarEdges = [pilarEdges[0], pilarEdges[2], pilarEdges[3]];
    backPillarEdges = [pilarEdges[1]];
  } else {
    frontPillarEdges = [pilarEdges[1], pilarEdges[2], pilarEdges[3]];
    backPillarEdges = [pilarEdges[0]];
  }
  const levelsEdges = edges.slice(4);

  backPillarEdges.forEach((edge) => {
    const v0 = projected[edge[0]];
    const v1 = projected[edge[1]];
    ctx.beginPath();
    ctx.moveTo(v0.x, v0.y);
    ctx.lineTo(v1.x, v1.y);
    ctx.stroke();
  });

  if (rackType === RACK_TYPES[0]) {
    ctx.fillStyle = "rgba(222, 184, 135, 1.0)";
  } else if (rackType === RACK_TYPES[1]) {
    ctx.fillStyle = "rgba(50, 50, 50, 0.0)";
    ctx.strokeStyle = "rgba(255, 80, 0, 1.0)";
  }
  ctx.lineWidth = 6;

  for (let i = 0; i < levelsEdges.length; i += 4) {
    ctx.beginPath();
    ctx.moveTo(projected[levelsEdges[i][0]].x, projected[levelsEdges[i][0]].y);
    ctx.lineTo(projected[levelsEdges[i][1]].x, projected[levelsEdges[i][1]].y);
    ctx.lineTo(
      projected[levelsEdges[i + 1][1]].x,
      projected[levelsEdges[i + 1][1]].y
    );
    ctx.lineTo(
      projected[levelsEdges[i + 2][1]].x,
      projected[levelsEdges[i + 2][1]].y
    );
    ctx.lineTo(projected[levelsEdges[i][0]].x, projected[levelsEdges[i][0]].y);

    ctx.fill();
    ctx.closePath();
    ctx.moveTo(projected[levelsEdges[i][0]].x, projected[levelsEdges[i][0]].y);
    ctx.lineTo(projected[levelsEdges[i][1]].x, projected[levelsEdges[i][1]].y);
    ctx.stroke();
  }

  ctx.lineWidth = 10;
  if (rackType === RACK_TYPES[0]) {
    ctx.fillStyle = "rgba(222, 184, 135, 1.0)";
  } else if (rackType === RACK_TYPES[1]) {
    ctx.fillStyle = "rgba(50, 50, 50, 0.0)";
    ctx.strokeStyle = "rgba(0, 0, 255, 1.0)";
  }
  frontPillarEdges.forEach((edge) => {
    const v0 = projected[edge[0]];
    const v1 = projected[edge[1]];
    ctx.beginPath();
    ctx.moveTo(v0.x, v0.y);
    ctx.lineTo(v1.x, v1.y);
    ctx.stroke();
  });

  // 셀 그리기
  let rotatedCells = cells.map((cell) => rotateY(cell, angleY));
  rotatedCells = rotatedCells.map((cell) => rotateX(cell, angleX));
  rotatedCells = rotatedCells.map((cell) => rotateZ(cell, angleZ));
  const projectedCells = rotatedCells.map((cell) =>
    project(cell, width, height, fov, viewerDistance)
  );

  ctx.fillStyle = "rgba(0,255, 50, 1.0)";
  ctx.strokeStyle = "rgba(0, 120, 0, 1.0)";

  ctx.lineWidth = 2;

  projectedCells.forEach((cell) => {
    ctx.beginPath();
    ctx.arc(cell.x, cell.y, 6, 0, 2 * Math.PI);
    ctx.fill();

    ctx.stroke();
  });

  // 가로 세로 높이 표시
  ctx.font = "35px Arial";
  ctx.fillStyle = "rgba(0, 0, 0, 1.0)";
  let rotatedTextPoints = textPoints.map((textPoint) =>
    rotateY(textPoint, angleY)
  );
  rotatedTextPoints = rotatedTextPoints.map((textPoint) =>
    rotateX(textPoint, angleX)
  );
  rotatedTextPoints = rotatedTextPoints.map((textPoint) =>
    rotateZ(textPoint, angleZ)
  );
  const textProjected = rotatedTextPoints.map((textPoint) =>
    project(textPoint, width, height, fov, viewerDistance)
  );
  for (let i = 0; i < textProjected.length; i += 2) {
    const center = {
      x: (textProjected[i].x + textProjected[i + 1].x) / 2,
      y: (textProjected[i].y + textProjected[i + 1].y) / 2,
    };
    ctx.save();
    let text = "";
    if (i === 0) {
      text = rackWidth;
      ctx.translate(center.x - 60, center.y - 30);
    } else if (i === 2) {
      text = rackDepth;
      ctx.translate(center.x, center.y - 10);
    } else if (i === 4) {
      ctx.translate(center.x + 20, center.y);
      text = rackHeight;
    }

    ctx.fillText(text + " m", 0, 0);
    ctx.restore();
  }
}

function RackTypeSettingModal() {
  const [isSaveCalled, setIsSaveCalled] = useState(false);

  const [rackWidth, setRackWidth] = useState(1.1);
  const [rackHeight, setRackHeight] = useState(2.5);
  const [rackDepth, setRackDepth] = useState(0.5);
  const [numCells, setNumCells] = useState(4);
  const [numLevels, setNumLevels] = useState(6);
  const [levelHeights, setLevelHeights] = useState([]);
  const [rackType, setRackType] = useState(RACK_TYPES[0]);
  const [angleX, setAngleX] = useState(Math.PI / 8);
  const [angleY, setAngleY] = useState(-0.7);
  const [angleZ, setAngleZ] = useState(0.0);
  const [selectedRackName, setSelectedRackName] = useState(null);
  const [rackName, setRackName] = useState("");
  const snackbarSuccessRef = useRef(null);
  const snackbarFailRef = useRef(null);
  const { setIsRackTypeSettingModalEnabled, isRackTypeSettingModalEnabled } =
    useToolContext();

  const { rackTypesData, setRackTypesData } = useDataContext();

  const canvasRef = useRef(null);

  const saveRackType = async (rackType) => {
    try {
      const response = await fetch(saveRackTypeUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any additional headers if needed
        },
        body: JSON.stringify({
          data: rackType,
          branch_name: "none",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to send rackType data to server");
      }
      console.log("rackType data sent successfully to server");
      // You can handle the response from the server here if needed
    } catch (error) {
      console.error("Error sending rackType data to server:", error.message);
    }
  };

  useEffect(() => {
    if (isSaveCalled) {
      saveRackType(rackTypesData);
      setIsSaveCalled(false);
      snackbarSuccessRef.current.show("랙 타입 저장 완료");
    }
  }, [isSaveCalled]);

  useEffect(() => {
    if (isRackTypeSettingModalEnabled) {
      const canvas = canvasRef.current;
      canvas.width = canvasRef.current.getBoundingClientRect().width * 2;
      canvas.height = canvasRef.current.getBoundingClientRect().height * 2;

      const ctx = canvas.getContext("2d");
      canvasRef.current.style.backgroundColor = "rgba(50, 50, 50, 0.2)";
      const cells = [];
      for (let i = 0; i < numLevels; i++) {
        for (let j = 0; j < numCells[i]; j++) {
          cells.push({
            x: -rackWidth / 2 + (rackWidth / (numCells[i] + 1)) * (j + 1),
            y: -rackHeight / 2 + levelHeights[i],
            z: rackDepth / 2,
          });
        }
      }

      const vertices = [];
      // x: 가로, y: 세로, z: 높이
      // 밑면
      vertices.push({
        x: -rackWidth / 2,
        y: -rackHeight / 2 + 0,
        z: -rackDepth / 2,
      });
      vertices.push({
        x: rackWidth / 2,
        y: -rackHeight / 2 + 0,
        z: -rackDepth / 2,
      });
      vertices.push({
        x: rackWidth / 2,
        y: -rackHeight / 2 + 0,
        z: rackDepth / 2,
      });
      vertices.push({
        x: -rackWidth / 2,
        y: -rackHeight / 2 + 0,
        z: rackDepth / 2,
      });
      // 윗면
      vertices.push({
        x: -rackWidth / 2,
        y: -rackHeight / 2 + rackHeight,
        z: -rackDepth / 2,
      });
      vertices.push({
        x: rackWidth / 2,
        y: -rackHeight / 2 + rackHeight,
        z: -rackDepth / 2,
      });
      vertices.push({
        x: rackWidth / 2,
        y: -rackHeight / 2 + rackHeight,
        z: rackDepth / 2,
      });
      vertices.push({
        x: -rackWidth / 2,
        y: -rackHeight / 2 + rackHeight,
        z: rackDepth / 2,
      });
      // 각 층의 밑면
      for (let i = 0; i < numLevels; i++) {
        vertices.push({
          x: -rackWidth / 2,
          y: -rackHeight / 2 + levelHeights[i],
          z: -rackDepth / 2,
        });
        vertices.push({
          x: rackWidth / 2,
          y: -rackHeight / 2 + levelHeights[i],
          z: -rackDepth / 2,
        });
        vertices.push({
          x: rackWidth / 2,
          y: -rackHeight / 2 + levelHeights[i],
          z: rackDepth / 2,
        });
        vertices.push({
          x: -rackWidth / 2,
          y: -rackHeight / 2 + levelHeights[i],
          z: rackDepth / 2,
        });
      }
      const textPoints = [
        // 윗면 가로 중앙
        {
          x: -rackWidth / 2,
          y: -rackHeight / 2 + rackHeight,
          z: -rackDepth / 2,
        },
        {
          x: rackWidth / 2,
          y: -rackHeight / 2 + rackHeight,
          z: -rackDepth / 2,
        },
        // 윗면 세로 중앙
        {
          x: rackWidth / 2,
          y: -rackHeight / 2 + rackHeight,
          z: -rackDepth / 2,
        },
        {
          x: rackWidth / 2,
          y: -rackHeight / 2 + rackHeight,
          z: rackDepth / 2,
        },

        // 윗면 높이 중앙
        {
          x: rackWidth / 2,
          y: -rackHeight / 2 + rackHeight,
          z: rackDepth / 2,
        },
        {
          x: rackWidth / 2,
          y: -rackHeight / 2 + 0,
          z: rackDepth / 2,
        },
      ];
      const edges = [];
      // 기둥
      for (let i = 0; i < 4; i++) {
        edges.push([i, i + 4]);
      }

      // 각 층
      for (let i = 0; i < numLevels; i++) {
        const start = 8;
        edges.push([start + 4 * i, start + 4 * i + 1]);
        edges.push([start + 4 * i + 1, start + 4 * i + 2]);
        edges.push([start + 4 * i + 2, start + 4 * i + 3]);
        edges.push([start + 4 * i + 3, start + 4 * i]);
      }

      // 윗면
      edges.push([4, 5]);
      edges.push([5, 6]);
      edges.push([6, 7]);
      edges.push([7, 4]);

      // 투영 설정
      const width = canvas.width;
      const height = canvas.height;

      const fov = 50000;
      const viewerDistance =
        (width * Math.max(rackDepth, rackHeight, rackWidth)) / 20;

      // 직육면체 그리기
      drawRack(
        vertices,
        edges,
        cells,
        textPoints,
        width,
        height,
        rackWidth,
        rackHeight,
        rackDepth,
        numLevels,
        levelHeights,
        numCells,
        rackType,
        fov,
        viewerDistance,
        angleX,
        angleY,
        angleZ,
        ctx
      );
    }
  }, [
    isRackTypeSettingModalEnabled,
    rackWidth,
    rackHeight,
    rackDepth,
    numCells,
    numLevels,
    levelHeights,
    rackType,
    angleX,
    angleY,
    angleZ,
  ]);

  useEffect(() => {
    if (Object.keys(rackTypesData).length === 0) {
      return;
    }
    setSelectedRackName(Object.keys(rackTypesData)[0]);
  }, []);

  useEffect(() => {
    // 선택시 rackTypeItem 배경색 변경
    const rackTypeItems = document.querySelectorAll(`.${styles.rackTypeItem}`);
    rackTypeItems.forEach((rackTypeItem) => {
      if (rackTypeItem.id === selectedRackName) {
        rackTypeItem.style.backgroundColor = "rgba(50,190,255, 0.3)";
      } else {
        rackTypeItem.style.backgroundColor = "rgba(0, 0, 0, 0)";
      }
    });

    // 선택한 rackType에 맞는 데이터 설정
    if (selectedRackName !== null) {
      setRackWidth(rackTypesData[selectedRackName].width);
      setRackHeight(rackTypesData[selectedRackName].height);
      setRackDepth(rackTypesData[selectedRackName].depth);
      setNumLevels(rackTypesData[selectedRackName].num_level);
      setLevelHeights(rackTypesData[selectedRackName].level_heights);
      // array인지 int인지 확인
      if (Array.isArray(rackTypesData[selectedRackName].num_cell)) {
        setNumCells(rackTypesData[selectedRackName].num_cell);
      } else {
        let numCells = [];
        for (
          let i = 0;
          i < rackTypesData[selectedRackName].level_heights.length;
          i++
        ) {
          numCells.push(rackTypesData[selectedRackName].num_cell);
        }
        setNumCells(numCells);
      }
      setRackName(selectedRackName);
      if (rackTypesData[selectedRackName].type !== undefined) {
        if (rackTypesData[selectedRackName].type === RACK_TYPES[0]) {
          setRackType(RACK_TYPES[0]);
        } else if (rackTypesData[selectedRackName].type === RACK_TYPES[1]) {
          setRackType(RACK_TYPES[1]);
        }
      } else {
        setRackType(RACK_TYPES[0]);
      }
    }
  }, [selectedRackName]);

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.containerHeader}>
          <div className={styles.containerTitle}>랙 타입 설정</div>
          <button
            className={styles.closeBtn}
            onClick={() => {
              setIsRackTypeSettingModalEnabled(false);
            }}
          >
            X
          </button>
        </div>
        <div className={styles.body}>
          <div className={styles.leftPanel}>
            <div className={styles.panelHeader}>
              <span className={styles.panelTitle}>리스트</span>
            </div>
            <div className={styles.rackTypeList}>
              {Object.keys(rackTypesData).map((rackType) => (
                <div
                  key={rackType}
                  className={styles.rackTypeItem}
                  id={rackType}
                >
                  <div
                    className={styles.rackTypeItemText}
                    onClick={() => {
                      setSelectedRackName(rackType);
                    }}
                  >
                    {rackType}
                  </div>

                  <img
                    className={styles.deleteBtn}
                    src="/assets/icons/delete.svg"
                    alt="delete"
                    onClick={() => {
                      const newRackTypesData = { ...rackTypesData };
                      delete newRackTypesData[rackType];
                      setRackTypesData(newRackTypesData);

                      if (rackType === selectedRackName) {
                        if (Object.keys(newRackTypesData).length > 0) {
                          setSelectedRackName(Object.keys(newRackTypesData)[0]);
                        } else {
                          setSelectedRackName(null);
                        }
                      }
                    }}
                  />
                </div>
              ))}
            </div>
            <div
              className={styles.addBtn}
              onClick={() => {
                const newRackTypesData = { ...rackTypesData };
                newRackTypesData[
                  `rack_${Object.keys(rackTypesData).length + 1}`
                ] = {
                  width: rackWidth,
                  height: rackHeight,
                  depth: rackDepth,
                  num_cell: numCells,
                  num_level: numLevels,
                  level_heights: levelHeights,
                  type: rackType,
                };
                setRackTypesData(newRackTypesData);
                setSelectedRackName(
                  `rack_${Object.keys(rackTypesData).length + 1}`
                );
              }}
            >
              새로 생성하기
            </div>
          </div>
          <div className={styles.canvasPanel}>
            <div className={styles.panelHeader}>
              <div className={styles.panelTitleContainer}>
                <span className={styles.panelTitle}>타입 이름</span>
              </div>
              <input
                className={styles.textInput}
                type="text"
                value={rackName}
                onChange={(e) => {
                  setRackName(e.target.value);
                }}
                onKeyDown={(e) => {
                  //방향키 입력시 좌우 커서 이동
                  if (e.key === "ArrowLeft" && !e.shiftKey) {
                    const input = e.target;
                    const position = input.selectionStart;
                    input.setSelectionRange(position - 1, position - 1);
                  } else if (e.key === "ArrowRight" && !e.shiftKey) {
                    const input = e.target;

                    const position = input.selectionStart;
                    input.setSelectionRange(position + 1, position + 1);
                  }

                  // Ctrl + A 입력시 전체선택
                  if (e.ctrlKey && (e.key === "a" || e.key === "A")) {
                    e.target.select();
                  }

                  // Ctrl+c, Ctrl+v, Ctrl+x 선택된 텍스트만 입력시 복사, 붙여넣기, 잘라내기
                  if (e.ctrlKey && (e.key === "c" || e.key === "C")) {
                    e.preventDefault();
                    const input = e.target;
                    const selectedText = input.value.slice(
                      input.selectionStart,
                      input.selectionEnd
                    );
                    navigator.clipboard.writeText(selectedText);
                  } else if (e.ctrlKey && (e.key === "v" || e.key === "V")) {
                    e.preventDefault();
                    navigator.clipboard.readText().then((text) => {
                      const input = e.target;
                      const position = input.selectionStart;
                      const beforeText = input.value.slice(0, position);
                      const afterText = input.value.slice(position);
                      input.value = beforeText + text + afterText;
                    });
                  } else if (e.ctrlKey && (e.key === "x" || e.key === "X")) {
                    e.preventDefault();
                    const input = e.target;
                    const position = input.selectionStart;
                    const beforeText = input.value.slice(
                      0,
                      input.selectionStart
                    );
                    const selectedText = input.value.slice(
                      input.selectionStart,
                      input.selectionEnd
                    );
                    const afterText = input.value.slice(input.selectionEnd);
                    navigator.clipboard.writeText(selectedText);
                    input.value = beforeText + afterText;
                  }
                }}
              />
              <button
                className={styles.saveBtn}
                onClick={() => {
                  if (
                    selectedRackName !== rackName &&
                    Object.keys(rackTypesData).includes(rackName)
                  ) {
                    snackbarFailRef.current.show("이미 존재하는 이름입니다.");
                    return;
                  }
                  if (selectedRackName === null || rackName === "") {
                    snackbarFailRef.current.show("이름을 입력해주세요.");
                  } else {
                    const newRackTypesData = { ...rackTypesData };
                    if (selectedRackName !== rackName && rackName !== "") {
                      newRackTypesData[rackName] =
                        newRackTypesData[selectedRackName];
                      delete newRackTypesData[selectedRackName];
                      setRackTypesData(newRackTypesData);
                      setSelectedRackName(rackName);
                    }
                    newRackTypesData[rackName] = {
                      width: rackWidth,
                      height: rackHeight,
                      depth: rackDepth,
                      num_cell: numCells,
                      num_level: numLevels,
                      level_heights: levelHeights,
                      type: rackType,
                    };
                    setRackTypesData(newRackTypesData);
                    setIsSaveCalled(true);
                  }
                }}
              >
                저장
              </button>
            </div>
            <canvas ref={canvasRef}></canvas>
            <div className={styles.rangeInputContainer}>
              <input
                className={styles.rangeInput}
                type="range"
                min="-90"
                max="0"
                value={angleY * (180 / Math.PI)}
                onChange={(e) => {
                  setAngleY(e.target.value * (Math.PI / 180));
                }}
              />
              <input
                className={styles.rangeInput}
                type="range"
                min="0"
                max="90"
                value={angleX * (180 / Math.PI)}
                onChange={(e) => {
                  setAngleX(e.target.value * (Math.PI / 180));
                }}
              />
              <button
                className={styles.saveBtn}
                onClick={() => {
                  setAngleX(Math.PI / 8);
                  setAngleY(-0.7);
                  setAngleZ(0.0);
                }}
                style={{ width: "40px", height: "20px", fontSize: "13px" }}
              >
                reset
              </button>
            </div>
          </div>
          <div className={styles.rightPanel}>
            <div className={styles.panelHeader}>
              <span className={styles.panelTitle}>설정</span>
            </div>
            <div
              className={styles.rightPanelSettingContainer}
              onKeyDown={(e) => {
                // Tab 키를 누르면 포커스가 이동하도록 설정
                if (e.key === "Tab") {
                  e.preventDefault();
                  const inputs = document.querySelectorAll(
                    `.${styles.settingInput}`
                  );
                  const inputsArr = Array.from(inputs);
                  const index = inputsArr.findIndex((input) =>
                    input.matches(":focus")
                  );
                  if (e.shiftKey) {
                    // reverse
                    if (index !== -1) {
                      if (index === 0) {
                        inputsArr[inputsArr.length - 1].focus();
                      } else {
                        inputsArr[index - 1].focus();
                      }
                    } else {
                      inputsArr[inputsArr.length - 1].focus();
                    }
                  } else {
                    if (index !== -1) {
                      if (index === inputsArr.length - 1) {
                        inputsArr[0].focus();
                      } else {
                        inputsArr[index + 1].focus();
                      }
                    } else {
                      inputsArr[0].focus();
                    }
                  }
                }

                // Enter 키를 누르면 다음 입력창으로 포커스가 이동하도록 설정
                if (e.key === "Enter") {
                  e.preventDefault();
                  const inputs = document.querySelectorAll(
                    `.${styles.settingInput}`
                  );
                  const inputsArr = Array.from(inputs);
                  const index = inputsArr.findIndex((input) =>
                    input.matches(":focus")
                  );
                  if (index !== -1) {
                    if (index === inputsArr.length - 1) {
                      inputsArr[0].focus();
                    } else {
                      inputsArr[index + 1].focus();
                    }
                  } else {
                    inputsArr[0].focus();
                  }
                }
              }}
            >
              <div className={styles.settingItem}>
                <span className={styles.settingTitle}>랙 종류</span>
                <select
                  className={styles.settingInput}
                  value={rackType}
                  onChange={(e) => {
                    setRackType(e.target.value);
                  }}
                  style={{ fontSize: "12px", cursor: "pointer" }}
                >
                  {RACK_TYPES.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.settingItem}>
                <span className={styles.settingTitle}>너비</span>
                <input
                  className={styles.settingInput}
                  value={rackWidth}
                  onClick={(e) => {
                    e.target.select();
                  }}
                  onChange={(e) => {
                    e.preventDefault();
                    const value = e.target.value;
                    // 숫자 또는 소수점만 입력 가능
                    const re = /^[0-9\b.]+$/;
                    if (value === "" || re.test(value)) {
                      setRackWidth(Number.parseFloat(value));
                    }
                  }}
                  type="number"
                  step={0.1}
                />
              </div>
              <div className={styles.settingItem}>
                <span className={styles.settingTitle}>깊이</span>
                <input
                  className={styles.settingInput}
                  value={rackDepth}
                  onClick={(e) => {
                    e.target.select();
                  }}
                  onChange={(e) => {
                    e.preventDefault();
                    const value = e.target.value;
                    // 숫자 또는 소수점만 입력 가능
                    const re = /^[0-9\b.]+$/;
                    if (value === "" || re.test(value)) {
                      setRackDepth(Number.parseFloat(value));
                    }
                  }}
                  type="number"
                  step={0.1}
                />
              </div>
              <div className={styles.settingItem}>
                <span className={styles.settingTitle}>높이</span>
                <input
                  className={styles.settingInput}
                  value={rackHeight}
                  onClick={(e) => {
                    e.target.select();
                  }}
                  onChange={(e) => {
                    e.preventDefault();
                    const value = e.target.value;
                    // 숫자 또는 소수점만 입력 가능
                    const re = /^[0-9\b.]+$/;
                    if (value === "" || re.test(value)) {
                      setRackHeight(Number.parseFloat(value));
                      const levelHeights = [];
                      for (let i = 0; i < numLevels; i++) {
                        levelHeights.push((i / numLevels) * rackHeight + 0.1);
                      }
                      setLevelHeights(levelHeights);
                    }
                  }}
                  type="number"
                  step={0.1}
                />
              </div>
              <div className={styles.settingItem}>
                <span className={styles.settingTitle}>셀 개수</span>
                <input
                  className={styles.settingInput}
                  onClick={(e) => {
                    e.target.select();
                  }}
                  onChange={(e) => {
                    // 키보드 입력을 받아들이지 않음
                    e.preventDefault();

                    if (parseInt(e.target.value) < 1) {
                      return;
                    }
                    let numCells = [];
                    for (let i = 0; i < numLevels; i++) {
                      numCells.push(parseInt(e.target.value));
                    }
                    setNumCells(numCells);
                  }}
                  onKeyDown={(e) => {
                    // arrow
                    let firstLevNumCells = numCells[0];
                    if (e.key === "ArrowUp") {
                      let newNumCells = [];
                      for (let i = 0; i < numLevels; i++) {
                        newNumCells.push(firstLevNumCells + 1);
                      }
                      setNumCells(newNumCells);
                    } else if (e.key === "ArrowDown") {
                      if (firstLevNumCells - 1 > 0) {
                        let newNumCells = [];
                        for (let i = 0; i < numLevels; i++) {
                          newNumCells.push(firstLevNumCells + 1);
                        }
                        setNumCells(newNumCells);
                      }
                    }
                  }}
                  value={numCells[0]}
                  type="number"
                  step={1}
                />
              </div>

              <div className={styles.settingItem}>
                <span className={styles.settingTitle}>레벨 개수</span>
                <input
                  className={styles.settingInput}
                  onClick={(e) => {
                    e.target.select();
                  }}
                  onChange={(e) => {
                    // 키보드 입력을 받아들이지 않음
                    e.preventDefault();
                    if (parseInt(e.target.value) < 1) {
                      return;
                    }
                    setNumLevels(parseInt(e.target.value));
                    const levelHeights = [];
                    for (let i = 0; i < parseInt(e.target.value); i++) {
                      levelHeights.push(
                        (i / parseInt(e.target.value)) * rackHeight + 0.1
                      );
                    }
                    setLevelHeights(levelHeights);
                    let newNumCells = [];
                    for (let i = 0; i < parseInt(e.target.value); i++) {
                      newNumCells.push(numCells[0]);
                    }
                    setNumCells(newNumCells);
                  }}
                  onKeyDown={(e) => {
                    // arrow
                    if (e.key === "ArrowUp") {
                      setNumLevels(numLevels + 1);
                    } else if (e.key === "ArrowDown") {
                      if (numLevels - 1 > 0) {
                        setNumLevels(numLevels - 1);
                      }
                    }
                    const levelHeights = [];
                    for (let i = 0; i < parseInt(e.target.value); i++) {
                      levelHeights.push(
                        (i / parseInt(e.target.value)) * rackHeight + 0.1
                      );
                    }
                    setLevelHeights(levelHeights);
                  }}
                  value={numLevels}
                  type="number"
                  step={1}
                />
              </div>
              <div className={styles.levelSettingContainerTitle}>
                각 레벨 높이 & 셀 개수
              </div>
              {/* {levelHeights.map( */}
              {[...levelHeights].reverse().map(
                (
                  levelHeight,
                  index // 레벨 높이
                ) => (
                  <div key={index} className={styles.settingItem}>
                    <span className={styles.levelSettingTitle}>
                      {/* Lev {levelHeights.length - index} */}
                      Lev {levelHeights.length - index}
                    </span>
                    <input
                      className={styles.levelSettingInput}
                      onClick={(e) => {
                        e.target.select();
                      }}
                      onChange={(e) => {
                        // 키보드 입력을 받아들이지 않음
                        e.preventDefault();
                        const value = e.target.value;
                        // 숫자 또는 소수점만 입력 가능
                        const re = /^[0-9\b.]+$/;
                        if (value === "" || re.test(value)) {
                          const newLevelHeights = [...levelHeights];
                          newLevelHeights[index] = Number.parseFloat(value);
                          setLevelHeights(newLevelHeights);
                        }
                      }}
                      onKeyDown={(e) => {
                        // arrow
                        if (e.key === "ArrowUp") {
                          const newLevelHeights = [...levelHeights];
                          newLevelHeights[index] += 0.01;
                          setLevelHeights(newLevelHeights);
                        } else if (e.key === "ArrowDown") {
                          const newLevelHeights = [...levelHeights];
                          newLevelHeights[index] -= 0.01;
                          setLevelHeights(newLevelHeights);
                        }
                      }}
                      value={levelHeight.toFixed(2)}
                      type="number"
                      step={0.01}
                    />
                    <input
                      className={styles.levelSettingInput}
                      onClick={(e) => {
                        e.target.select();
                      }}
                      onChange={(e) => {
                        // 키보드 입력을 받아들이지 않음
                        e.preventDefault();
                        const value = e.target.value;
                        // 숫자만 입력 가능
                        const re = /^[0-9\b]+$/;
                        if (value === "" || re.test(value)) {
                          const newNumCells = [...numCells];
                          newNumCells[levelHeights.length - index - 1] =
                            parseInt(value);
                          setNumCells(newNumCells);
                        }
                      }}
                      onKeyDown={(e) => {
                        // arrow
                        if (e.key === "ArrowUp") {
                          const newNumCells = [...numCells];
                          newNumCells[levelHeights.length - index - 1] += 1;
                          setNumCells(newNumCells);
                        } else if (e.key === "ArrowDown") {
                          if (
                            numCells[levelHeights.length - index - 1] - 1 >
                            0
                          ) {
                            const newNumCells = [...numCells];
                            newNumCells[levelHeights.length - index - 1] -= 1;
                            setNumCells(newNumCells);
                          }
                        }
                      }}
                      value={numCells[levelHeights.length - index - 1]}
                      type="number"
                      step={1}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <>
        <Snackbar ref={snackbarSuccessRef} type={SnackbarType.success} />
      </>
      <>
        <Snackbar ref={snackbarFailRef} type={SnackbarType.fail} />
      </>
    </div>
  );
}

export default RackTypeSettingModal;
