// https://github.com/machadop1407/Snackbar-Toast-Notifications-React/tree/main
import React, { useState, forwardRef, useImperativeHandle } from "react";
import styles from "styles/Snackbar.module.css";

const Snackbar = forwardRef((props, ref) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState("");

  useImperativeHandle(ref, () => ({
    show(msg) {
      setMessage(msg);

      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
        setMessage("");
      }, 3000);
    },
  }));
  return (
    <div
      className={styles.snackbar}
      id={showSnackbar ? styles.show : styles.hide}
      style={{
        backgroundColor: props.type === "success" ? "#A1EEBD" : "#FF8080",
        color: props.type === "success" ? "black" : "white",
      }}
    >
      <div className={styles.symbol}>
        {props.type === "success" ? <h1>&#x2713;</h1> : <h1>&#x2613;</h1>}
      </div>
      <div className={styles.message}>{message}</div>
    </div>
  );
});

export default Snackbar;
