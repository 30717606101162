import {
  OBJECT_TYPES,
  OBJECT_TYPES_TO_CATEGORY_MAP,
  UI_TOOL_NAMES,
} from "common/TYPES";
import { transformPointToOriginalImageCoord } from "features/utils";
import { Ruler } from "features/Object";

export class RulerTool {
  constructor(setObjectsDict) {
    this.setObjectsDict = setObjectsDict;
    this.objectsDict = {};
    this.imageOrigin = {};
    this.rulerPoints = [];
    this.currentObjects = [];
  }
  init(imageOrigin, mapMetadata, canvas, canvasInfo, taskManager) {
    this.imageOrigin = imageOrigin;
    this.mapMetadata = mapMetadata;
    this.canvas = canvas;
    this.canvasInfo = canvasInfo;
    this.canvasScale = canvasInfo.scale;
    this.taskManager = taskManager;
  }
  updateCanvasInfo(canvasInfo) {
    this.canvasInfo = canvasInfo;
  }

  resetCurrentObjects() {
    this.currentObjects = [];
  }

  updateObjectsDict(objectsDict) {
    this.objectsDict = objectsDict;
    const objects = Object.values(this.objectsDict).filter(
      (object) => object.type === OBJECT_TYPES.RULER
    );
    this.rulerPoints = [];

    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      const data = object.data;
      if (data.length < 2) continue;
      const point1 = {
        id: object.id,
        center: data[0],
        index: 0,
      };
      const point2 = {
        id: object.id,
        center: data[data.length - 1],
        index: data.length - 1,
      };
      this.rulerPoints.push(point1);
      this.rulerPoints.push(point2);
    }
  }
  setTool(currentTool) {
    this.currentTool = currentTool;
    if (this.currentTool === UI_TOOL_NAMES.RULER) {
      this.isActivated = true;
    } else {
      this.isActivated = false;
    }
  }
  updateImageOrigin(imageOrigin) {
    if (!imageOrigin) return;
    this.imageOrigin = imageOrigin;
  }

  updateConfig(config) {
    this.config = config;
  }

  listenMouseAction(mouseEvent) {
    if (!this.isActivated) return;
    const button = mouseEvent.event.button;
    const x = mouseEvent.event.offsetX;
    const y = mouseEvent.event.offsetY;

    let closestObject, isCursorOnObject, pointIndex;
    switch (mouseEvent.action) {
      case "mousedown":
        this.mouseDownPos = { x, y };
        const transformedMouseDownPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin
        );
        if (button === 0) {
          this.isMouseLeftDown = true;
        } else if (button === 1) {
          this.isMouseMiddleDown = true;
        } else if (button === 2) {
          this.isMouseRightDown = true;
        }

        [closestObject, isCursorOnObject, pointIndex] = this.checkPointOnObject(
          transformedMouseDownPos
        );

        if (this.isMouseLeftDown) {
          if (this.currentObjects.length === 0) {
            const ruler = new Ruler();

            if (isCursorOnObject) {
              ruler.setCenter(closestObject.data[pointIndex]);
              ruler.data.push(closestObject.data[pointIndex]);
            } else {
              ruler.setCenter(transformedMouseDownPos);
              ruler.data.push(transformedMouseDownPos);
            }
            ruler.setSize(this.config.size);
            this.setObjectsDict({
              type: "ADD",
              payload: ruler,
            });
            this.currentObjects = [ruler];
          } else if (this.currentObjects.length === 1) {
            const ruler = this.currentObjects[0];
            if (isCursorOnObject) {
              ruler.data[ruler.data.length - 1] =
                closestObject.data[pointIndex];
            } else {
              ruler.data[ruler.data.length - 1] = transformedMouseDownPos;
            }

            this.setObjectsDict({
              type: "UPDATE",
              payload: ruler,
            });
            this.taskManager.addHistory(this.currentObjects, "ADD");

            this.currentObjects = [];
          }
        }

        break;
      case "mousemove":
        this.mouseCurrentPos = { x, y };
        const transformedMouseCurrentPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin
        );

        [closestObject, isCursorOnObject, pointIndex] = this.checkPointOnObject(
          transformedMouseCurrentPos
        );
        if (!this.isMouseLeftDown) {
          if (isCursorOnObject) {
            if (
              this.currentObjects.length === 1 &&
              closestObject !== null &&
              this.currentObjects[0].id !== closestObject.id
            ) {
              this.canvas.style.cursor = "pointer";
            } else if (this.currentObjects.length === 0) {
              this.canvas.style.cursor = "pointer";
            } else {
              this.canvas.style.cursor = "default";
            }
          }
        } else {
          this.canvas.style.cursor = "default";
        }
        if (!this.isMouseLeftDown && this.currentObjects.length === 1) {
          const ruler = this.currentObjects[0];
          if (ruler.data.length === 1) {
            ruler.data.push(transformedMouseCurrentPos);
            this.setObjectsDict({
              type: "UPDATE",
              payload: ruler,
            });
          } else if (ruler.data.length > 1) {
            if (isCursorOnObject) {
              ruler.data[ruler.data.length - 1] =
                closestObject.data[pointIndex];

              this.setObjectsDict({
                type: "UPDATE",
                payload: ruler,
              });
            } else {
              ruler.data[ruler.data.length - 1] = transformedMouseCurrentPos;
              this.setObjectsDict({
                type: "UPDATE",
                payload: ruler,
              });
            }
          }
        }

        break;

      case "mouseleave":
      case "mouseup":
        this.mouseUpPos = { x, y };
        this.isMouseMiddleDown = false;
        this.isMouseRightDown = false;
        this.isMouseLeftDown = false;
        const transformedMouseUpPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin
        );

        break;

      default:
        break;
    }
  }

  listenKeyboardAction(keyboardEvent) {
    const event = keyboardEvent.event;
    const action = keyboardEvent.action;
    const key = event.key;

    switch (action) {
      case "keydown":
        switch (key) {
          case " ":
            this.isSpacePressed = true;
            break;
          case "Escape":
            if (this.currentObjects.length > 0) {
              this.setObjectsDict({
                type: "DELETE",
                payload: this.currentObjects[0],
              });
            }
            this.currentObjects = [];
            break;

          default:
            break;
        }
        break;
      case "keyup":
        switch (key) {
          case " ":
            this.isSpacePressed = false;
            break;

          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  checkPointOnObject(point) {
    if (this.rulerPoints.length === 0) return [null, false, null];
    for (let i = 0; i < this.rulerPoints.length; i++) {
      const rulerPoint = this.rulerPoints[i];
      const object = this.objectsDict[rulerPoint.id];
      const pixelObjectSize = object.size / this.mapMetadata.resolution;
      const distance = Math.sqrt(
        (point.x - rulerPoint.center.x) ** 2 +
          (point.y - rulerPoint.center.y) ** 2
      );
      if (distance <= pixelObjectSize) {
        return [object, true, rulerPoint.index];
      }
    }
    return [null, false, null];
  }

  meterToPixel(meterPoint) {
    const pixelPoint = {
      x: Math.round(
        ((meterPoint.x - this.mapMetadata.origin[0]) /
          this.mapMetadata.resolution) *
          this.canvasInfo.scale
      ),
      y: Math.round(
        (this.imageOrigin.imageHeight -
          (meterPoint.y - this.mapMetadata.origin[1]) /
            this.mapMetadata.resolution) *
          this.canvasInfo.scale
      ),
    };
    return pixelPoint;
  }

  pixelToMeter(pixelPoint) {
    const meterPoint = {
      x:
        (pixelPoint.x / this.canvasInfo.scale) * this.mapMetadata.resolution +
        this.mapMetadata.origin[0],
      y:
        (this.imageOrigin.imageHeight - pixelPoint.y / this.canvasInfo.scale) *
          this.mapMetadata.resolution +
        this.mapMetadata.origin[1],
    };
    return meterPoint;
  }
}
