import styles from "styles/Loading.module.css";
import { useEffect } from "react";

function Loading({ loadingInfo }) {
  useEffect(() => {
    // set cursor to loading
    document.body.style.cursor = "wait";
    return () => {
      // reset cursor
      document.body.style.cursor = "default";
    };
  }, []);
  return (
    <div className={styles.loadingContainer}>
      {" "}
      <div className={styles.loadingSpinner}></div>
      <div className={styles.loadingInfo}>{loadingInfo}</div>
    </div>
  );
}

export default Loading;
