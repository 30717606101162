// import "./App.css";
import { useEffect, useState, useRef } from "react";
import { useObjectContext } from "context/ObjectContext";
import { useToolContext } from "context/ToolContext";
import { UI_TOOL_NAMES, OBJECT_TYPES, TOPOLOGY_TYPE } from "common/TYPES";
import NumericInput from "react-numeric-input";

import styles from "styles/RightPanel.module.css";

import { useDataContext } from "context/DataContext";

function TopologyPanel() {
  const {
    objectsDict,
    setObjectsDict,
    selectedObjectsList,
    setSelectedObjectsList,
    activeObjectCategory,
    setActiveObjectCategory,
  } = useObjectContext();
  const { currentTool } = useToolContext();
  const { topologyToolConfig, setTopologyToolConfig } = useToolContext();

  const vertexSizeOnChange = (event) => {
    setTopologyToolConfig({
      ...topologyToolConfig,
      vertexSize: event.target.value,
    });
    for (let key in objectsDict) {
      if (objectsDict[key].type === OBJECT_TYPES.VERTEX) {
        objectsDict[key].size = event.target.value;
      }
    }
    setObjectsDict({ ...objectsDict });
  };

  const edgeTypeOnChange = (event) => {
    setTopologyToolConfig({
      ...topologyToolConfig,
      edgeType: event.target.value,
    });
  };

  const vertexTypeOnChange = (event) => {
    setTopologyToolConfig({
      ...topologyToolConfig,
      vertexType: event.target.value,
    });
  };

  // Function to handle input change
  const handleVertexIntervalChange = (value) => {
    // remove m from the value
    setTopologyToolConfig({
      ...topologyToolConfig,
      vertexInterval: parseFloat(value),
    });
  };

  return (
    <div className={styles.panelContainer}>
      {currentTool === UI_TOOL_NAMES.TOPOLOGY &&
        selectedObjectsList.length === 0 && (
          <>
            <div className={styles.container}>
              <div className={styles.title}>툴 설정</div>
              <div className={styles.settingItemContainer}>
                <div className={styles.settingItemTitle}>
                  Vertex 자동 생성 간격 [m]
                </div>
                <div className={styles.settingItemContent}>
                  <NumericInput
                    value={topologyToolConfig.vertexInterval.toFixed(2)}
                    step={0.1}
                    precision={2}
                    className={styles.settingInput}
                    onChange={handleVertexIntervalChange}
                    style={{
                      wrap: {
                        width: "70%",
                      },
                      input: {
                        width: "70%",
                      },
                    }}
                  />
                </div>
              </div>
              <div className={styles.settingItemContainer}>
                <div className={styles.settingItemTitle}>Vertex 타입</div>
                <div className={styles.settingItemContent}>
                  <select
                    onChange={vertexTypeOnChange}
                    className={styles.settingInput}
                    value={topologyToolConfig.vertexType}
                  >
                    {TOPOLOGY_TYPE.VERTEX.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={styles.settingItemContainer}>
                <div className={styles.settingItemTitle}>Vertex 크기 [m]</div>
                <div className={styles.settingItemContent}>
                  <input
                    type="range"
                    min="0.3"
                    max="1"
                    step={0.1}
                    value={topologyToolConfig.vertexSize}
                    className={styles.settingInputRange}
                    onChange={vertexSizeOnChange}
                  />
                  <span className={styles.settingUnit}>
                    {topologyToolConfig.vertexSize}m
                  </span>
                </div>
              </div>
              <div className={styles.settingItemContainer}>
                <div className={styles.settingItemTitle}>Edge 타입</div>
                <div className={styles.settingItemContent}>
                  <select
                    onChange={edgeTypeOnChange}
                    className={styles.settingInput}
                    value={topologyToolConfig.edgeType}
                  >
                    {TOPOLOGY_TYPE.EDGE.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={styles.settingItemContainer}>
                <div className={styles.settingItemTitle}>Two-way</div>
                <div className={styles.settingItemContent}>
                  <div className={styles.toggleButton}>
                    <label className={styles.switch}>
                      <input
                        type="checkbox"
                        checked={topologyToolConfig.twoWay}
                        onChange={() => {
                          setTopologyToolConfig({
                            ...topologyToolConfig,
                            twoWay: !topologyToolConfig.twoWay,
                          });
                        }}
                      />
                      <span className={styles.toggle}></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
}

export default TopologyPanel;
