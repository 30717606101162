function EditorHeader({ title }) {
  return (
    <div className="EditorHeader">
      {" "}
      <p className="title">{title}</p>
    </div>
  );
}

export default EditorHeader;
