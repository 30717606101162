// import "./App.css";
import { useEffect, useState, useRef } from "react";
import { useObjectContext } from "context/ObjectContext";
import { useToolContext } from "context/ToolContext";
import { UI_TOOL_NAMES, OBJECT_TYPES } from "common/TYPES";
import { COLOR } from "common/COLOR";

import styles from "styles/RightPanel.module.css";

import { useDataContext } from "context/DataContext";

function RectanglePanel() {
  const {
    objectsDict,
    setObjectsDict,
    selectedObjectsList,
    setSelectedObjectsList,
    activeObjectCategory,
    setActiveObjectCategory,
  } = useObjectContext();
  const { currentTool } = useToolContext();
  const { rectangleToolConfig, setRectangleToolConfig } = useToolContext();
  const [activeColor, setActiveColor] = useState(rectangleToolConfig.color);
  useEffect(() => {}, [rectangleToolConfig, activeColor]);

  return (
    <div className={styles.panelContainer}>
      {currentTool === UI_TOOL_NAMES.RECTANGLE &&
        selectedObjectsList.length === 0 && (
          <>
            <div className={styles.container}>
              <div className={styles.title}>툴 설정</div>
              <div className={styles.settingItemContainer}>
                <div className={styles.settingItemTitle}> Area 타입 지정</div>
                <div className={styles.settingItemContent}>
                  <div className={styles.circleButtonContainer}>
                    <div
                      className={styles.circleButton}
                      style={{
                        backgroundColor: COLOR.FREE_SPACE,
                        border:
                          activeColor === COLOR.FREE_SPACE
                            ? "4px solid #2196f3"
                            : "1px solid skyblue",
                      }}
                      onClick={() => {
                        setRectangleToolConfig({
                          ...rectangleToolConfig,
                          color: COLOR.FREE_SPACE,
                        });
                        setActiveColor(COLOR.FREE_SPACE);
                      }}
                    ></div>
                    <div
                      className={styles.circleButton}
                      style={{
                        backgroundColor: COLOR.OCCUPIED,
                        border:
                          activeColor === COLOR.OCCUPIED
                            ? "4px solid #2196f3"
                            : "1px solid skyblue",
                      }}
                      onClick={() => {
                        setRectangleToolConfig({
                          ...rectangleToolConfig,
                          color: COLOR.OCCUPIED,
                        });
                        setActiveColor(COLOR.OCCUPIED);
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className={styles.settingItemContainer}>
                <div className={styles.settingItemContent}>
                  <div>
                    <span className={styles.settingItemTitle}>채우기</span>
                    <div className={styles.toggleButton}>
                      <label className={styles.switch}>
                        <input
                          type="checkbox"
                          checked={rectangleToolConfig.fill}
                          onChange={() => {
                            setRectangleToolConfig({
                              ...rectangleToolConfig,
                              fill: !rectangleToolConfig.fill,
                            });
                          }}
                        />
                        <span className={styles.toggle}></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
}

export default RectanglePanel;
