import {
  OBJECT_TYPES,
  OBJECT_TYPES_TO_CATEGORY_MAP,
  UI_TOOL_NAMES,
} from "common/TYPES";
import {
  transformPointToOriginalImageCoord,
  checkPointInRectangle,
  checkPointNearCorner,
  checkPointOnCorner,
} from "features/utils";
import { Rectangle } from "features/Object";

export class RectangleTool {
  constructor(setObjectsDict) {
    this.setObjectsDict = setObjectsDict;
    this.imageOrigin = {};
    this.currentObjects = [];
    this.currentObjectUpdated = false;
    this.isDrawing = false;
    this.isMovingRectangle = false;
    this.isMovingCorner = false;
    this.cornerMovingRect = null;
    this.transformedPrevMousePos = { x: 0, y: 0 };
  }
  init(
    imageOrigin,
    canvasInfo,
    canvas,
    taskManager,
    config,
    setSelectedObjectsList,
    setHoveredObject
  ) {
    this.imageOrigin = imageOrigin;
    this.canvas = canvas;
    this.canvasInfo = canvasInfo;
    this.taskManager = taskManager;
    this.config = config;
    this.setSelectedObjectsList = setSelectedObjectsList;
    this.setHoveredObject = setHoveredObject;
    this.selectedObject = null;
  }
  resetCurrentObjects() {
    this.currentObjects = [];
    this.setHoveredObject([]);
    this.setSelectedObjectsList([]);
    this.selectedObject = null;
  }
  setTool(currentTool) {
    this.currentTool = currentTool;
    if (this.currentTool === UI_TOOL_NAMES.RECTANGLE) {
      this.isActivated = true;
    } else {
      this.isActivated = false;
    }
  }
  updateImageOrigin(imageOrigin) {
    if (!imageOrigin) return;
    this.imageOrigin = imageOrigin;
  }
  updateObjectsDict(objectsDict) {
    this.objectsDict = objectsDict;
  }

  updateConfig(config) {
    this.config = config;
  }
  updateCanvasInfo(canvasInfo) {
    this.canvasInfo = canvasInfo;
  }

  listenMouseAction(mouseEvent) {
    if (!this.isActivated) return;
    const button = mouseEvent.event.button;
    const x = mouseEvent.event.offsetX;
    const y = mouseEvent.event.offsetY;
    let closestRectangle,
      isCursorInRectangle,
      isMouseOnCorner,
      isMouseNearCorner;
    switch (mouseEvent.action) {
      case "mousedown":
        if (button === 0) {
          this.isMouseLeftDown = true;
        } else if (button === 1) {
          this.isMouseMiddleDown = true;
        } else if (button === 2) {
          this.isMouseRightDown = true;
        }
        if (this.currentObjectUpdated) {
          this.currentObjects = [];
          this.currentObjectUpdated = false;
        }
        const transformedMouseDownPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin
        );
        this.transformedPrevMousePos = { ...transformedMouseDownPos };

        if (this.selectedObject !== null) {
          closestRectangle = this.selectedObject;
        } else {
          closestRectangle = this.getClosestRectangle(transformedMouseDownPos);
        }
        isCursorInRectangle = checkPointInRectangle(
          transformedMouseDownPos,
          closestRectangle,
          this.imageOrigin.scale
        );
        isMouseOnCorner = checkPointOnCorner(
          transformedMouseDownPos,
          closestRectangle,
          this.imageOrigin.scale
        );
        isMouseNearCorner = checkPointNearCorner(
          transformedMouseDownPos,
          closestRectangle,
          this.imageOrigin.scale
        );

        //"rectangle-drawing-start"
        if (this.isMouseLeftDown && !this.isSpacePressed) {
          this.isDrawing = true;

          const currentObject = new Rectangle(this.config);
          currentObject.setStartPoint(transformedMouseDownPos);
          this.currentObjects.push(currentObject);
        } else if (
          // move rectangle
          this.isMouseLeftDown &&
          this.isSpacePressed &&
          isCursorInRectangle &&
          this.selectedObject === null
        ) {
          this.selectedObject = closestRectangle;
          this.setSelectedObjectsList([this.selectedObject.id]);
        } else if (
          this.isMouseLeftDown &&
          this.isSpacePressed &&
          isCursorInRectangle &&
          this.selectedObject !== null
        ) {
          this.isMovingRectangle = true;
          this.taskManager.addHistory([this.selectedObject], "UPDATE");
        } else if (
          this.isMouseLeftDown &&
          this.isSpacePressed &&
          isMouseOnCorner &&
          this.selectedObject !== null
        ) {
          this.isMovingCorner = true;
          this.cornerMovingRect = this.selectedObject;
          this.taskManager.addHistory([this.selectedObject], "UPDATE");
        } else if (
          this.isMouseLeftDown &&
          this.isSpacePressed &&
          isMouseNearCorner &&
          this.selectedObject !== null
        ) {
          this.isRotating = true;
          this.cornerRotatingRect = this.selectedObject;
          this.taskManager.addHistory([this.selectedObject], "UPDATE");
        }
        break;
      case "mousemove":
        if (this.currentObjectUpdated) {
          this.currentObjects = [];
          this.currentObjectUpdated = false;
        }
        this.mouseCurrentPos = { x, y };
        const transformedMouseCurrentPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin
        );
        if (this.selectedObject !== null) {
          closestRectangle = this.selectedObject;
        } else {
          closestRectangle = this.getClosestRectangle(
            transformedMouseCurrentPos
          );
        }
        isCursorInRectangle = checkPointInRectangle(
          transformedMouseCurrentPos,
          closestRectangle,
          this.imageOrigin.scale
        );
        isMouseOnCorner = checkPointOnCorner(
          transformedMouseCurrentPos,
          closestRectangle,
          this.imageOrigin.scale
        );
        isMouseNearCorner = checkPointNearCorner(
          transformedMouseCurrentPos,
          closestRectangle,
          this.imageOrigin.scale
        );

        //"rectangle-drawing"
        if (this.isDrawing && !this.isSpacePressed) {
          this.currentObjects[0].setEndPoint(transformedMouseCurrentPos);
        } else if (
          //"rectangle-idle"
          !this.isMouseLeftDown &&
          this.isSpacePressed
        ) {
          if (isCursorInRectangle && !this.isMouseLeftDown) {
            if (this.selectedObject === null) {
              this.canvas.style.cursor = "pointer";
              this.setHoveredObject([closestRectangle.id]);
            } else {
              this.canvas.style.cursor = "move";
            }
          } else if (isMouseOnCorner && !this.isMouseLeftDown) {
            if (this.selectedObject !== null) {
              this.canvas.style.cursor = "pointer";
            }
          } else if (isMouseNearCorner && !this.isMouseLeftDown) {
            if (this.selectedObject !== null) {
              this.canvas.style.cursor = "grab";
            }
          } else {
            if (!this.isMovingRectangle && !this.isMovingCorner) {
              if (this.selectedObject === null) {
                this.canvas.style.cursor = "default";
                this.setHoveredObject([]);
              }
            }
          }
          // move rectangle
        } else if (
          !this.isDrawing &&
          this.isMouseLeftDown &&
          this.isMovingRectangle &&
          this.selectedObject !== null
        ) {
          const deltaX =
            transformedMouseCurrentPos.x - this.transformedPrevMousePos.x;
          const deltaY =
            transformedMouseCurrentPos.y - this.transformedPrevMousePos.y;
          this.selectedObject.translate(deltaX, deltaY);
          this.setObjectsDict({
            type: "UPDATE",
            payload: this.selectedObject,
          });
          this.transformedPrevMousePos = { ...transformedMouseCurrentPos };
        } else if (
          this.isMovingCorner &&
          this.isMouseLeftDown &&
          this.isSpacePressed &&
          !this.isDrawing &&
          this.selectedObject !== null
        ) {
          let cornerIndex = -1;
          let minDist = Number.MAX_VALUE;
          for (let i = 0; i < 4; i++) {
            const corner = this.selectedObject.data[i];
            const dist = Math.sqrt(
              (corner.x - transformedMouseCurrentPos.x) ** 2 +
                (corner.y - transformedMouseCurrentPos.y) ** 2
            );
            if (dist < minDist) {
              minDist = dist;
              // deep copy
              cornerIndex = i;
            }
          }
          if (cornerIndex === -1) break;
          this.selectedObject.setCornerPoint(
            cornerIndex,
            transformedMouseCurrentPos
          );
          // this.selectedObject.setCenter();
          this.setObjectsDict({
            type: "UPDATE",
            payload: this.selectedObject,
          });
        } else if (
          this.isRotating &&
          this.isMouseLeftDown &&
          this.isSpacePressed &&
          !this.isDrawing &&
          this.selectedObject !== null
        ) {
          const center = this.selectedObject.center;
          const relativeAngleCurrent = Math.atan2(
            transformedMouseCurrentPos.y - center.y,
            transformedMouseCurrentPos.x - center.x
          );
          const relativeAngleDown = Math.atan2(
            this.transformedPrevMousePos.y - center.y,
            this.transformedPrevMousePos.x - center.x
          );
          const angle = relativeAngleCurrent - relativeAngleDown;
          this.selectedObject.rotate(center, angle);
          this.setObjectsDict({
            type: "UPDATE",
            payload: this.selectedObject,
          });

          this.transformedPrevMousePos = { ...transformedMouseCurrentPos };
        }
        break;
      case "mouseleave":
      case "mouseup":
        this.isMouseMiddleDown = false;
        this.isMouseRightDown = false;
        this.isMouseLeftDown = false;

        const transformedMouseUpPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin
        );
        if (this.isDrawing) {
          this.isDrawing = false;

          //rectangle-drawing-end
          this.currentObjects[0].setEndPoint(transformedMouseUpPos);
          this.currentObjects[0].setCenter();
          this.currentObjects[0].orderPoints();
          if (
            this.currentObjects[0].center.x !==
              this.currentObjects[0].data[2].x &&
            this.currentObjects[0].center.y !== this.currentObjects[0].data[2].y
          ) {
            this.setObjectsDict({
              type: "ADD",
              payload: this.currentObjects[0],
            });
            this.taskManager.addHistory(this.currentObjects, "ADD");
            this.currentObjectUpdated = true;
          } else {
            this.currentObjects = [];
          }
        } else if (this.isMovingRectangle) {
          this.isMovingRectangle = false;
        } else if (this.isMovingCorner) {
          this.isMovingCorner = false;
        }

        break;

      default:
        break;
    }
  }

  listenKeyboardAction(keyboardEvent) {
    const event = keyboardEvent.event;
    const action = keyboardEvent.action;
    const key = event.key;

    switch (action) {
      case "keydown":
        switch (key) {
          case " ":
            this.isSpacePressed = true;
            break;

          default:
            break;
        }
        break;
      case "keyup":
        switch (key) {
          case " ":
            this.isSpacePressed = false;
            this.canvas.style.cursor = "default";
            this.setSelectedObjectsList([]);
            this.setHoveredObject([]);
            this.selectedObject = null;

            break;

          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  getClosestRectangle(point) {
    const rectangles = Object.values(this.objectsDict).filter(
      (object) => object.type === OBJECT_TYPES.RECTANGLE
    );
    if (rectangles.length === 0) return null;
    let minDistance = 100000;
    let minRectangle = null;
    for (let i = 0; i < rectangles.length; i++) {
      const rectangle = rectangles[i];
      const distance = Math.sqrt(
        (point.x - rectangle.center.x) ** 2 +
          (point.y - rectangle.center.y) ** 2
      );
      if (distance < minDistance) {
        minDistance = distance;
        minRectangle = rectangle;
      }
    }
    return minRectangle;
  }
}
