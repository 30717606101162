import styles from "styles/ToolBar.module.css";
import { useEffect, useRef } from "react";
import useDetectClose from "hooks/useDetectClose";

import { useDOMContext } from "context/DOMContext";
import { useObjectContext } from "context/ObjectContext";
import { useToolContext } from "context/ToolContext";
import {
  OBJECT_TYPES,
  TOOL_NAMES_TO_OBJECT_CATEGORY_MAP,
  UI_TOOL_NAMES,
} from "common/TYPES";


function ToolButton({ buttonName, icon, haveFlyout, flyout }) {
  const flyoutRef = useRef();
  const buttonRef = useRef();
  const [isOpen, setIsOpen] = useDetectClose(flyoutRef, false);
  const { toolBarRef } = useDOMContext();
  const {
    selectedObjectsList,
    setSelectedObjectsList,
    setActiveObjectCategory,
  } = useObjectContext();

  const { setCurrentTool } = useToolContext();

  function handleFlyOutClick(event) {
    setIsOpen(!isOpen);
    // add active class to the button if the flyout is open
    if (isOpen) {
      flyoutRef.current.classList.remove(styles.active);
    } else {
      flyoutRef.current.classList.add(styles.active);
    }
  }

  function handleButtonClick(event) {
    setCurrentTool(buttonName);
    const toolObjectType = TOOL_NAMES_TO_OBJECT_CATEGORY_MAP[buttonName];
    if (toolObjectType !== OBJECT_TYPES.DEFAULT) {
      setActiveObjectCategory(toolObjectType);
    }

    const buttons = toolBarRef.current.children;
    // add active class to if the button is clicked
    if (selectedObjectsList.length > 0) {
      setSelectedObjectsList([]);
    }
    for (let button of buttons) {
      if (button === buttonRef.current) {
        button.classList.add(styles.active);
      } else {
        button.classList.remove(styles.active);
      }
    }
  }

  // set initial active button
  useEffect(() => {
    if (buttonName === UI_TOOL_NAMES.CURSOR) {
      buttonRef.current.classList.add(styles.active);
    }
  }, []);

  return (
    <div
      className={styles.toolButtonContainer}
      onClick={handleButtonClick}
      ref={buttonRef}
      id={buttonName}
    >
      <div className={styles.button}>
        <img
          className={styles.toolButton}
          src={icon}
          alt={`${buttonName}-button`}
        />
      </div>
      {haveFlyout && (
        <div
          className={styles.flyout}
          onClick={handleFlyOutClick}
          ref={flyoutRef}
        >
          <img
            className={styles.flyoutButton}
            src="/assets/icons/flyout.svg"
            alt="flyout button"
            style={{
              transform: isOpen
                ? "rotate(180deg) translateY(-10px)"
                : "rotate(0deg)",
            }}
          />
        </div>
      )}
      {isOpen && <>{flyout}</>}
    </div>
  );
}

export default ToolButton;
