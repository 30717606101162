import {
  OBJECT_TYPES,
  OBJECT_CATEGORY,
  OBJECT_TYPES_TO_CATEGORY_MAP,
  UI_TOOL_NAMES,
} from "common/TYPES";
import {
  getActiveCategoryColor,
  getEdgeTypeColor,
  getToolColor,
  getVertexTypeColor,
  getPoiTypeColor,
} from "features/utils";

export class Painter {
  constructor() {
    this.imageOrigin = {};
    this.imageCenter = {};
    this.canvas = null;
    this.objectsDict = {};
    this.conflictedRackNames = [];
    this.selectedObjectsList = [];
    this.hoveringObjectIds = [];
    this.activeObjectCategory = OBJECT_TYPES.DEFAULT;
    this.invisibleObjectsList = [];
    this.entireSelectedRect = { data: [], center: { x: 0, y: 0 } };
    this.cornerSize = 20;
    this.isInitialized = false;

    this.vertexObjects = [];
    this.edgeObjects = [];
    this.poiObjects = [];
    this.areaObjects = [];
    this.rackObjects = [];
    this.rulerObjects = [];
    this.selectedObjects = [];

    this.viewTextSize = 35;
  }
  init(
    canvas,
    navMapImage,
    localizationMapImage,
    mapMetadata,
    canvasInfo,
    imageOrigin
  ) {
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d");
    this.ctx.imageSmoothingEnabled = false;

    this.canvasWidth = canvasInfo.canvasWidth;
    this.canvasHeight = canvasInfo.canvasHeight;
    this.canvasInfo = canvasInfo;
    this.canvasScale = canvasInfo.scale;
    this.setMap(navMapImage, localizationMapImage, mapMetadata);
    this.updateImageOrigin(imageOrigin);
    this.currentImage.onload = () => {
      this.drawImage();
    };
    this.mapOriginPixel = {
      x: Math.round(
        (-this.mapMetadata.origin[0] / this.mapMetadata.resolution) *
          this.canvasScale
      ),
      y: Math.round(
        (this.imageOrigin.imageHeight +
          this.mapMetadata.origin[1] / this.mapMetadata.resolution) *
          this.canvasScale
      ),
    };
    this.drawImage();
    this.drawGrid();
    this.isInitialized = true;
  }

  meterToPixel(meterPoint) {
    const pixelPoint = {
      x: Math.round(
        ((meterPoint.x - this.mapMetadata.origin[0]) /
          this.mapMetadata.resolution) *
          this.canvasScale
      ),
      y: Math.round(
        (this.imageOrigin.imageHeight -
          (meterPoint.y - this.mapMetadata.origin[1]) /
            this.mapMetadata.resolution) *
          this.canvasScale
      ),
    };
    return pixelPoint;
  }

  setMap(navMapImage, localizationMapImage, mapMetadata) {
    this.navMapImg = new Image();
    this.navMapImg.src = navMapImage;
    this.localizationMapImg = new Image();
    this.localizationMapImg.src = localizationMapImage;
    this.mapMetadata = mapMetadata;

    this.currentImage = this.localizationMapImg;
  }

  updateImageOrigin(imageOrigin) {
    if (!imageOrigin) return;
    this.imageOrigin = imageOrigin;
    this.imageCenter = {
      x:
        this.imageOrigin.x +
        (this.navMapImg.width / 2) * this.imageOrigin.scale,
      y:
        this.imageOrigin.y +
        (this.navMapImg.height / 2) * this.imageOrigin.scale,
    };
  }
  updateCanvasInfo(canvasInfo) {
    this.canvasInfo = canvasInfo;
    this.canvasScale = canvasInfo.scale;
    this.canvasWidth = canvasInfo.canvasWidth;
    this.canvasHeight = canvasInfo.canvasHeight;
    this.mapOriginPixel = {
      x: Math.round(
        (-this.mapMetadata.origin[0] / this.mapMetadata.resolution) *
          this.canvasScale
      ),
      y: Math.round(
        (this.imageOrigin.imageHeight +
          this.mapMetadata.origin[1] / this.mapMetadata.resolution) *
          this.canvasScale
      ),
    };
  }
  updateInvisibleObjectsList(invisibleObjectsList) {
    this.invisibleObjectsList = invisibleObjectsList;
    this.updateObjectsDict(this.objectsDict);
  }
  updateObjectsDict(objectsDict) {
    this.objectsDict = objectsDict;
    const ids = Object.keys(this.objectsDict);
    if (ids.length < 1) {
      return;
    }
    this.vertexObjects = [];
    this.edgeObjects = [];
    this.poiObjects = [];
    this.areaObjects = [];
    this.rackObjects = [];
    this.rulerObjects = [];
    this.selectedObjects = [];
    for (let i = 0; i < ids.length; i++) {
      if (this.invisibleObjectsList.includes(ids[i])) continue;
      if (this.selectedObjectsList.includes(ids[i])) {
        this.selectedObjects.push(this.objectsDict[ids[i]]);
      }
      const object = this.objectsDict[ids[i]];
      if (object.type === OBJECT_TYPES.VERTEX) {
        this.vertexObjects.push(object);
      } else if (object.type === OBJECT_TYPES.EDGE) {
        this.edgeObjects.push(object);
      } else if (object.type === OBJECT_TYPES.POI) {
        this.poiObjects.push(object);
      } else if (
        OBJECT_TYPES_TO_CATEGORY_MAP[object.type] === OBJECT_CATEGORY.AREA
      ) {
        this.areaObjects.push(object);
      } else if (
        OBJECT_TYPES_TO_CATEGORY_MAP[object.type] === OBJECT_CATEGORY.RACK
      ) {
        this.rackObjects.push(object);
      } else if (object.type === OBJECT_TYPES.RULER) {
        this.rulerObjects.push(object);
      }
    }
    // console.log(this.stopObjects);
    this.checkRackConflict();
  }
  updateHover(ids) {
    this.hoveringObjectIds = ids;
  }
  updateSelectedObjectsList(selectedObjectsList) {
    this.selectedObjectsList = selectedObjectsList;
  }
  updateDrag(isSelectDragging, mouseDownPos, mouseCurrentPos) {
    this.isSelectDragging = isSelectDragging;
    this.draggingArea = {
      mouseDownPos,
      mouseCurrentPos,
    };
  }
  updateActiveObjectType(activeObjectCategory) {
    this.activeObjectCategory = activeObjectCategory;
    this.canvas.style.backgroundColor = getActiveCategoryColor(
      activeObjectCategory,
      0.2
    );
  }
  updateEntireSelectedRect(entireSelectedRect) {
    this.entireSelectedRect = entireSelectedRect;
  }
  transformPointToCanvasCoord(point) {
    const x = Math.round(point.x * this.imageOrigin.scale) + this.imageOrigin.x;
    const y = Math.round(point.y * this.imageOrigin.scale) + this.imageOrigin.y;

    return { x, y };
  }

  checkRackConflict() {
    this.conflictedRackNames = [];
    for (let i = 0; i < this.rackObjects.length; i++) {
      // 중복 검사
      const object = this.rackObjects[i];
      for (let j = i + 1; j < this.rackObjects.length; j++) {
        const target = this.rackObjects[j];
        if (
          object.rackName === target.rackName &&
          object.id !== target.id &&
          target.rackName !== ""
        ) {
          this.conflictedRackNames.push(object.rackName);
          break;
        }
      }
    }
  }

  drawImage() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    const scaledWidth =
      this.navMapImg.width * this.imageOrigin.scale * this.canvasScale;
    const scaledHeight =
      this.navMapImg.height * this.imageOrigin.scale * this.canvasScale;
    this.ctx.drawImage(
      this.currentImage,
      this.imageOrigin.x,
      this.imageOrigin.y,
      scaledWidth,
      scaledHeight
    );
  }

  changeImage(keyboardEvent) {
    if (keyboardEvent.event.key === "m" && keyboardEvent.action === "keydown") {
      if (this.currentImage === this.navMapImg) {
        this.currentImage = this.localizationMapImg;
      } else {
        this.currentImage = this.navMapImg;
      }
      this.drawImage();
    }
  }
  drawRotator(center, corners) {
    corners.forEach((corner) => {
      this.ctx.beginPath();
      this.ctx.strokeStyle = "skyblue";
      this.ctx.lineWidth = 5;
      const p = corner;
      const centeredX = p.x - center.x;
      const centeredY = p.y - center.y;
      const yaw = Math.atan2(centeredY, centeredX);
      let resizedX, resizedY;
      resizedX = p.x + 40 * Math.cos(yaw);
      resizedY = p.y + 40 * Math.sin(yaw);

      this.ctx.arc(
        resizedX,
        resizedY,
        this.cornerSize,
        yaw - Math.PI / 3,
        yaw + Math.PI / 3
      );
      this.ctx.stroke();
      this.ctx.strokeStyle = "white";
      this.ctx.lineWidth = 1;
      this.ctx.arc(
        resizedX,
        resizedY,
        this.cornerSize,
        yaw - Math.PI / 3,
        yaw + Math.PI / 3
      );
      this.ctx.stroke();
      this.ctx.closePath();
    });
  }
  drawCorner(center, corners) {
    const color = "skyblue";

    // write rotated text that width and height of rect on center of edge
    this.ctx.save();
    this.ctx.globalAlpha = 0.5;
    this.ctx.beginPath();
    corners.forEach((corner) => {
      const p = corner;
      this.ctx.moveTo(p.x, p.y);
      // draw border
      this.ctx.strokeStyle = color;
      this.ctx.lineWidth = 5;
      this.ctx.arc(p.x, p.y, this.cornerSize, 0, 2 * Math.PI);
      this.ctx.stroke();
      // ring shape that is filled with skyblue
      this.ctx.fillStyle = "white";
      this.ctx.arc(p.x, p.y, this.cornerSize, 0, 2 * Math.PI);
      this.ctx.fill();
    });
    this.ctx.closePath();
    this.ctx.restore();
  }
  pixelToMeter(pixelPoint) {
    const meterPoint = {
      x:
        (pixelPoint.x / this.canvasInfo.scale) * this.mapMetadata.resolution +
        this.mapMetadata.origin[0],
      y:
        (this.imageOrigin.imageHeight - pixelPoint.y / this.canvasInfo.scale) *
          this.mapMetadata.resolution +
        this.mapMetadata.origin[1],
    };
    return meterPoint;
  }
  drawRuler(object, isHoveredObject = false, isSelectedObject = false) {
    const data = object.data;
    const transformedPoints = data.map((point) =>
      this.transformPointToCanvasCoord(point)
    );
    if (transformedPoints.length === 1) {
      const point = transformedPoints[0];
      const meterPoint = this.pixelToMeter(object.data[0]);
      const fontSize =
        (object.size / this.mapMetadata.resolution) *
        this.canvasScale *
        this.imageOrigin.scale *
        2;
      this.ctx.beginPath();
      this.ctx.strokeStyle = "#9333EA";
      this.ctx.fillStyle = "#4F46E5";
      this.ctx.lineWidth = 3 * this.imageOrigin.scale;
      // line from start to end
      this.ctx.moveTo(point.x, point.y);
      this.ctx.lineTo(point.x, point.y);
      this.ctx.closePath();
      this.ctx.beginPath();

      this.ctx.arc(
        point.x,
        point.y,
        (object.size / this.mapMetadata.resolution) *
          this.canvasScale *
          this.imageOrigin.scale,
        0,
        2 * Math.PI
      );
      this.ctx.stroke();
      this.ctx.fill();
      this.ctx.closePath();
      this.ctx.beginPath();

      // x,y text
      this.ctx.textAlign = "center";
      this.ctx.font = `${fontSize}px Arial`;
      this.ctx.fillStyle = "black";
      this.ctx.fillText(
        `(${meterPoint.x.toFixed(2)}, ${meterPoint.y.toFixed(2)})`,
        point.x,
        point.y +
          (object.size / this.mapMetadata.resolution) *
            this.canvasScale *
            this.imageOrigin.scale *
            2
      );
      this.ctx.closePath();
    } else if (transformedPoints.length === 2) {
      const point1 = transformedPoints[0];
      const point2 = transformedPoints[1];
      const center = {
        x: (point1.x + point2.x) / 2,
        y: (point1.y + point2.y) / 2,
      };
      const meterPoint1 = this.pixelToMeter(object.data[0]);
      const meterPoint2 = this.pixelToMeter(object.data[1]);
      const distance = Math.sqrt(
        (meterPoint1.x - meterPoint2.x) ** 2 +
          (meterPoint1.y - meterPoint2.y) ** 2
      );
      let yaw = Math.atan2(point2.y - point1.y, point2.x - point1.x);
      if (yaw > Math.PI / 2 || yaw < -Math.PI / 2) {
        yaw += Math.PI;
      }

      const text = `${distance.toFixed(2)}m`;

      const fontSize =
        (object.size / this.mapMetadata.resolution) *
        this.canvasScale *
        this.imageOrigin.scale *
        2;
      this.ctx.beginPath();
      this.ctx.strokeStyle = "#9333EA";
      this.ctx.fillStyle = "#4F46E5";
      this.ctx.lineWidth = 3 * this.imageOrigin.scale;
      // line from start to end
      this.ctx.moveTo(point1.x, point1.y);
      this.ctx.lineTo(point2.x, point2.y);
      this.ctx.stroke();

      // distance text
      this.ctx.closePath();
      this.ctx.beginPath();

      this.ctx.arc(
        point1.x,
        point1.y,
        (object.size / this.mapMetadata.resolution) *
          this.canvasScale *
          this.imageOrigin.scale,
        0,
        2 * Math.PI
      );
      this.ctx.stroke();
      this.ctx.fill();
      this.ctx.closePath();
      this.ctx.beginPath();
      this.ctx.arc(
        point2.x,
        point2.y,
        (object.size / this.mapMetadata.resolution) *
          this.canvasScale *
          this.imageOrigin.scale,
        0,
        2 * Math.PI
      );
      this.ctx.stroke();
      this.ctx.fill();
      this.ctx.closePath();
      this.ctx.beginPath();
      this.ctx.textAlign = "center";
      this.ctx.font = `${fontSize}px Arial`;
      this.ctx.fillStyle = "black";
      this.ctx.fillText(
        `(${meterPoint1.x.toFixed(2)}, ${meterPoint1.y.toFixed(2)})`,
        point1.x,
        point1.y +
          (object.size / this.mapMetadata.resolution) *
            this.canvasScale *
            this.imageOrigin.scale
      );
      this.ctx.fillText(
        `(${meterPoint2.x.toFixed(2)}, ${meterPoint2.y.toFixed(2)})`,
        point2.x,
        point2.y +
          (object.size / this.mapMetadata.resolution) *
            this.canvasScale *
            this.imageOrigin.scale
      );

      // distance text
      this.ctx.save();
      this.ctx.translate(center.x, center.y);
      this.ctx.rotate(yaw);
      this.ctx.font = `${fontSize}px Arial`;
      this.ctx.fillStyle = "black";
      this.ctx.fillText(text, -fontSize, 0);
      this.ctx.stroke();

      this.ctx.restore();
      this.ctx.closePath();

      this.ctx.stroke();
    }
  }

  drawPencil(
    object,
    isHoveredObject = false,
    isSelectedObject = false,
    isOnlySelectedObject = false
  ) {
    if (isSelectedObject) {
      this.ctx.save();
      this.ctx.globalAlpha = 0.8;
    }
    this.ctx.beginPath();
    this.ctx.strokeStyle = object.color;

    this.ctx.lineWidth = object.stroke * this.imageOrigin.scale;
    // only dot for lineCap
    this.ctx.lineCap = "round";
    this.ctx.lineJoin = "round";
    const transformedPoints = object.data.map((point) =>
      this.transformPointToCanvasCoord(point)
    );
    this.ctx.moveTo(transformedPoints[0].x, transformedPoints[0].y);

    for (let i = 0; i < transformedPoints.length; i++) {
      const point = transformedPoints[i];
      this.ctx.lineTo(point.x, point.y);
      this.ctx.moveTo(point.x, point.y);
    }
    this.ctx.stroke();
    this.ctx.closePath();

    if (isSelectedObject || isHoveredObject) {
      if (object.type === OBJECT_TYPES.DRAWING) {
        this.ctx.beginPath();

        this.ctx.lineWidth = (object.stroke * this.imageOrigin.scale) / 2;
        this.ctx.strokeStyle = "skyblue";

        this.ctx.moveTo(transformedPoints[0].x, transformedPoints[0].y);

        for (let i = 0; i < transformedPoints.length; i++) {
          const point = transformedPoints[i];
          this.ctx.lineTo(point.x, point.y);
          this.ctx.moveTo(point.x, point.y);
        }
        this.ctx.stroke();

        const minX = Math.min(...transformedPoints.map((point) => point.x));
        const minY = Math.min(...transformedPoints.map((point) => point.y));
        const maxX = Math.max(...transformedPoints.map((point) => point.x));
        const maxY = Math.max(...transformedPoints.map((point) => point.y));

        const p1 = { x: minX, y: minY };
        const p2 = { x: maxX, y: minY };
        const p3 = { x: maxX, y: maxY };
        const p4 = { x: minX, y: maxY };
        const corners = [p1, p2, p3, p4];
        this.ctx.beginPath();
        this.ctx.lineWidth = 5;
        this.ctx.moveTo(p1.x, p1.y);
        this.ctx.lineTo(p2.x, p2.y);
        this.ctx.lineTo(p3.x, p3.y);
        this.ctx.lineTo(p4.x, p4.y);
        this.ctx.lineTo(p1.x, p1.y);
        this.ctx.stroke();
        this.ctx.closePath();
        if (isOnlySelectedObject) {
          this.drawCorner(
            this.transformPointToCanvasCoord(object.center),
            corners
          );
        }
      } else if (object.type === OBJECT_TYPES.LINE) {
        const startPoint = object.data[0];
        const endPoint = object.data[1];

        const transformedStartPoint =
          this.transformPointToCanvasCoord(startPoint);
        const transformedEndPoint = this.transformPointToCanvasCoord(endPoint);
        this.ctx.beginPath();
        this.ctx.strokeStyle = "skyblue";
        this.ctx.lineWidth = object.stroke / 4;
        this.ctx.moveTo(transformedStartPoint.x, transformedStartPoint.y);
        this.ctx.lineTo(transformedEndPoint.x, transformedEndPoint.y);
        this.ctx.stroke();
        this.ctx.closePath();
        this.ctx.arc(
          transformedStartPoint.x,
          transformedStartPoint.y,
          object.stroke,
          0,
          2 * Math.PI
        );
        this.ctx.arc(
          transformedEndPoint.x,
          transformedEndPoint.y,
          object.stroke,
          0,
          2 * Math.PI
        );
        this.ctx.fillStyle = "skyblue";
        this.ctx.fill();
      }
    }
    if (isSelectedObject) {
      this.ctx.restore();
    }
  }

  drawRectangle(
    object,
    isHoveredObject = false,
    isSelectedObject = false,
    isOnlySelectedObject = false
  ) {
    if (isSelectedObject) {
      this.ctx.save();
      this.ctx.globalAlpha = 0.8;
    }
    if (object.fill) {
      this.ctx.fillStyle = object.color;
      this.ctx.strokeStyle = object.color;
    } else {
      this.ctx.strokeStyle = object.color;
    }
    if (isSelectedObject || isHoveredObject) {
      this.ctx.strokeStyle = "skyblue";
      this.ctx.lineWidth = 5;
    } else {
      this.ctx.lineWidth = 1;
    }

    const transformedPoints = object.data.map((point) =>
      this.transformPointToCanvasCoord(point)
    );
    this.ctx.beginPath();
    this.ctx.moveTo(transformedPoints[0].x, transformedPoints[0].y);
    this.ctx.lineTo(transformedPoints[1].x, transformedPoints[1].y);
    this.ctx.lineTo(transformedPoints[2].x, transformedPoints[2].y);
    this.ctx.lineTo(transformedPoints[3].x, transformedPoints[3].y);
    this.ctx.lineTo(transformedPoints[0].x, transformedPoints[0].y);
    if (object.fill) {
      this.ctx.fill();
    }
    this.ctx.stroke();
    this.ctx.closePath();
    if (isSelectedObject) {
      this.ctx.restore();
    }

    if (isOnlySelectedObject) {
      // draw corners as filled circle
      this.drawCorner(
        this.transformPointToCanvasCoord(object.center),
        transformedPoints
      );
      this.drawRotator(
        this.transformPointToCanvasCoord(object.center),
        transformedPoints
      );
    }
  }

  drawVertex(object, isHoveredObject = false, isSelectedObject = false) {
    this.ctx.beginPath();
    //연두색
    if (isSelectedObject || isHoveredObject) {
      this.ctx.strokeStyle = "skyblue";
      if (isSelectedObject) {
        this.ctx.lineWidth = 10;
      } else {
        this.ctx.lineWidth = 5;
      }
    } else {
      this.ctx.strokeStyle = "black";

      this.ctx.lineWidth = 2;
    }
    this.ctx.fillStyle = getVertexTypeColor(object.vertexType, 0.8);
    const transformedPoints = this.transformPointToCanvasCoord(object.center);
    const vertexPixelSize =
      ((object.size / this.mapMetadata.resolution) *
        this.canvasScale *
        this.imageOrigin.scale) /
      2;
    this.ctx.arc(
      transformedPoints.x,
      transformedPoints.y,
      vertexPixelSize,
      0,
      2 * Math.PI
    );
    this.ctx.stroke();
    if (object.enabled) {
      this.ctx.fill();
    }

    this.ctx.textAlign = "center";
    if (vertexPixelSize > this.viewTextSize) {
      this.ctx.font = `${vertexPixelSize / 2}px Arial`;
      this.ctx.fillStyle = "black";
      this.ctx.fillText(
        `${object.id}`,
        transformedPoints.x,
        transformedPoints.y
      );
      this.ctx.stroke();
    }
    this.ctx.closePath();
  }
  drawEdge(object, isHoveredObject = false, isSelectedObject = false) {
    // this.ctx.lineCap = "round";
    // this.ctx.lineJoin = "round";
    const srcID = object.vertices[0];
    const dstID = object.vertices[1];
    const srcVertex = this.objectsDict[srcID];
    const dstVertex = this.objectsDict[dstID];
    let srcCenter;
    let dstCenter;
    let enabled = object.enabled;
    let vertexSize;

    if (dstVertex === undefined) {
      dstCenter = object.dstPoint;
    } else {
      dstCenter = dstVertex.center;
      enabled = enabled && dstVertex.enabled;
    }

    if (srcVertex === undefined) {
      srcCenter = object.srcPoint;
      vertexSize = object.vertexSize;
    } else {
      srcCenter = srcVertex.center;
      vertexSize = srcVertex.size;
      enabled = enabled && srcVertex.enabled;
    }
    const src = this.transformPointToCanvasCoord(srcCenter);
    const dst = this.transformPointToCanvasCoord(dstCenter);
    const center = this.transformPointToCanvasCoord(object.center);
    // arrow from src to dst, in half of the line
    const arrowSize =
      ((vertexSize / this.mapMetadata.resolution) *
        this.canvasScale *
        this.imageOrigin.scale) /
      2;

    const dx = dst.x - src.x;
    const dy = dst.y - src.y;
    const centerX = center.x;
    const centerY = center.y;

    const angle = Math.atan2(dy, dx);

    this.ctx.beginPath();
    if (isSelectedObject) {
      this.ctx.strokeStyle = "skyblue";
    } else {
      if (!enabled) {
        this.ctx.strokeStyle = getEdgeTypeColor("disabled", 0.5);
      } else {
        this.ctx.strokeStyle = getEdgeTypeColor(object.edgeType, 0.5);
      }
    }

    this.ctx.lineWidth =
      ((vertexSize / this.mapMetadata.resolution) *
        this.canvasScale *
        this.imageOrigin.scale) /
      6;
    this.ctx.moveTo(src.x, src.y);
    this.ctx.lineTo(dst.x, dst.y);

    this.ctx.moveTo(centerX, centerY);
    this.ctx.lineTo(
      centerX - arrowSize * Math.cos(angle - Math.PI / 6),
      centerY - arrowSize * Math.sin(angle - Math.PI / 6)
    );
    this.ctx.moveTo(centerX, centerY);
    this.ctx.lineTo(
      centerX - arrowSize * Math.cos(angle + Math.PI / 6),
      centerY - arrowSize * Math.sin(angle + Math.PI / 6)
    );
    this.ctx.stroke();
    this.ctx.closePath();

    if (arrowSize > this.viewTextSize) {
      this.ctx.save();

      this.ctx.translate(centerX, centerY);
      if (angle > Math.PI / 2 || angle < -Math.PI / 2) {
        this.ctx.rotate(angle + Math.PI);
      } else {
        this.ctx.rotate(angle);
      }
      this.ctx.textAlign = "center";
      this.ctx.font = `${arrowSize / 2}px Arial`;
      this.ctx.fillStyle = "black";
      this.ctx.fillText(`${object.cost.toFixed(2)}`, 0, -arrowSize / 2);
      this.ctx.restore();

      this.ctx.save();
      this.ctx.beginPath();
      this.ctx.lineWidth = 0;
      this.ctx.strokeStyle = "white";
      this.ctx.fillStyle = "rgba(135,206,235,0.7)";
      this.ctx.arc(centerX, centerY, arrowSize / 4, 0, 2 * Math.PI);
      this.ctx.fill();
      this.ctx.closePath();
      this.ctx.restore();
    }
    this.ctx.closePath();
  }

  drawPoi(object, isHoveredObject = false, isSelectedObject = false) {
    const transformedPoints = this.transformPointToCanvasCoord(object.center);
    const pixelPoiSize =
      ((object.size / this.mapMetadata.resolution) *
        this.canvasScale *
        this.imageOrigin.scale) /
      2;

    const yaw = object.yaw;
    this.ctx.beginPath();
    this.ctx.lineCap = "round";
    this.ctx.strokeStyle = getPoiTypeColor(object.name, 0.7);
    this.ctx.fillStyle = getPoiTypeColor(object.name, 0.8);
    this.ctx.lineWidth = pixelPoiSize;
    this.ctx.arc(
      transformedPoints.x,
      transformedPoints.y,
      pixelPoiSize,
      0,
      2 * Math.PI
    );

    this.ctx.moveTo(
      transformedPoints.x + pixelPoiSize * Math.cos(yaw + Math.PI / 2),
      transformedPoints.y + pixelPoiSize * Math.sin(yaw + Math.PI / 2)
    );
    this.ctx.lineTo(
      transformedPoints.x + pixelPoiSize * 2 * Math.cos(yaw),
      transformedPoints.y + pixelPoiSize * 2 * Math.sin(yaw)
    );

    this.ctx.moveTo(
      transformedPoints.x + pixelPoiSize * Math.cos(yaw - Math.PI / 2),
      transformedPoints.y + pixelPoiSize * Math.sin(yaw - Math.PI / 2)
    );
    this.ctx.lineTo(
      transformedPoints.x + pixelPoiSize * 2 * Math.cos(yaw),
      transformedPoints.y + pixelPoiSize * 2 * Math.sin(yaw)
    );
    this.ctx.stroke();
    this.ctx.fill();
    this.ctx.closePath();

    this.ctx.beginPath();
    this.ctx.strokeStyle = getPoiTypeColor("heading", 0.6);
    this.ctx.lineWidth = pixelPoiSize / 4;

    this.ctx.moveTo(transformedPoints.x, transformedPoints.y);
    this.ctx.lineTo(
      transformedPoints.x + pixelPoiSize * 2 * Math.cos(yaw),
      transformedPoints.y + pixelPoiSize * 2 * Math.sin(yaw)
    );
    this.ctx.stroke();

    this.ctx.closePath();
    this.ctx.save();
    this.ctx.translate(transformedPoints.x, transformedPoints.y);
    if (yaw > Math.PI / 2 || yaw < -Math.PI / 2) {
      this.ctx.rotate(yaw + Math.PI);
    } else {
      this.ctx.rotate(yaw);
    }
    this.ctx.textAlign = "center";
    this.ctx.font = `${pixelPoiSize / 2}px Arial`;
    this.ctx.fillStyle = "black";
    this.ctx.fillText(`${object.name}-${object.number}`, 0, pixelPoiSize);
    this.ctx.fillText(
      `${(-(object.yaw * 180) / Math.PI).toFixed(1)}deg`,
      0,
      -pixelPoiSize
    );
    this.ctx.restore();
  }

  drawRack(
    object,
    isHoveredObject = false,
    isSelectedObject = false,
    isOnlySelectedObject = false
  ) {
    if (
      object === undefined ||
      object.data === undefined ||
      object.center === undefined ||
      object.cells === undefined
    )
      return;
    this.ctx.textAlign = "center";

    const transformedPoints = object.data.map((point) =>
      this.transformPointToCanvasCoord(point)
    );
    this.ctx.beginPath();
    this.ctx.moveTo(transformedPoints[0].x, transformedPoints[0].y);
    this.ctx.lineTo(transformedPoints[1].x, transformedPoints[1].y);
    this.ctx.lineTo(transformedPoints[2].x, transformedPoints[2].y);
    this.ctx.lineTo(transformedPoints[3].x, transformedPoints[3].y);
    this.ctx.lineTo(transformedPoints[0].x, transformedPoints[0].y);
    this.ctx.lineTo(transformedPoints[1].x, transformedPoints[1].y);
    if (isSelectedObject) {
      if (this.conflictedRackNames.includes(object.rackName)) {
        this.ctx.strokeStyle = "red";
        this.ctx.fillStyle = "red";
        this.ctx.lineWidth = 3 * this.canvasScale * this.imageOrigin.scale;
        this.ctx.stroke();
        this.ctx.fill();
      }
      // this.ctx.globalAlpha = 0.5;

      this.ctx.strokeStyle = "blue";
      this.ctx.lineWidth = 1 * this.canvasScale * this.imageOrigin.scale;
      this.ctx.stroke();
    } else if (this.conflictedRackNames.includes(object.rackName)) {
      this.ctx.fillStyle = "red";
      this.ctx.strokeStyle = "red";
      this.ctx.lineWidth = 3 * this.canvasScale * this.imageOrigin.scale;
      this.ctx.stroke();
      this.ctx.fill();
    } else {
      this.ctx.strokeStyle = "#EA580C";
      this.ctx.fillStyle = "orange";
      this.ctx.lineWidth = 0.5 * this.canvasScale * this.imageOrigin.scale;
      this.ctx.save();
      this.ctx.globalAlpha = 0.3;

      this.ctx.fill();
      this.ctx.restore();
      this.ctx.stroke();
    }
    if (isHoveredObject) {
      this.ctx.strokeStyle = "skyblue";
      this.ctx.lineWidth = 1 * this.canvasScale * this.imageOrigin.scale;
      this.ctx.stroke();
    }
    this.ctx.closePath();

    const arrowSize =
      ((object.depth / this.mapMetadata.resolution) *
        this.canvasScale *
        this.imageOrigin.scale) /
      2;
    // p0 p3
    const arrowStart = {
      x: (transformedPoints[0].x + transformedPoints[3].x) / 2,
      y: (transformedPoints[0].y + transformedPoints[3].y) / 2,
    };
    // p1 p2
    const arrowEnd = {
      x: (transformedPoints[1].x + transformedPoints[2].x) / 2,
      y: (transformedPoints[1].y + transformedPoints[2].y) / 2,
    };
    const dx = arrowEnd.x - arrowStart.x;
    const dy = arrowEnd.y - arrowStart.y;
    const angle = Math.atan2(dy, dx);
    this.ctx.beginPath();
    this.ctx.moveTo(arrowStart.x, arrowStart.y);
    this.ctx.lineTo(arrowEnd.x, arrowEnd.y);

    this.ctx.lineTo(
      arrowEnd.x - arrowSize * Math.cos(angle - Math.PI / 6),
      arrowEnd.y - arrowSize * Math.sin(angle - Math.PI / 6)
    );
    this.ctx.moveTo(arrowEnd.x, arrowEnd.y);
    this.ctx.lineTo(
      arrowEnd.x - arrowSize * Math.cos(angle + Math.PI / 6),
      arrowEnd.y - arrowSize * Math.sin(angle + Math.PI / 6)
    );

    this.ctx.stroke();
    this.ctx.closePath();
    this.ctx.beginPath();
    this.ctx.fillStyle = "#C026D3";
    this.ctx.strokeStyle = "#EC4899";
    this.ctx.lineWidth = 0.2 * this.imageOrigin.scale * this.canvasScale;
    const origin = transformedPoints[0];
    this.ctx.arc(
      origin.x,
      origin.y,
      0.7 * this.imageOrigin.scale * this.canvasScale,
      0,
      2 * Math.PI
    );
    this.ctx.stroke();
    this.ctx.fill();
    this.ctx.closePath();
    let duplicatedCellXY = [];
    if (object.isRackConfigured) {
      // draw cell
      const cells = object.cells;
      const cellSize = 1 * this.imageOrigin.scale * this.canvasScale;
      if (cellSize > 4) {
        for (let i = 0; i < cells.length; i++) {
          const cell = cells[i];
          if (cell === undefined || cell.center === undefined) continue;
          const cellCenter = cell.center;

          const transformedCenter =
            this.transformPointToCanvasCoord(cellCenter);
          const coordText = `(${cellCenter.x.toFixed(
            0
          )}, ${cellCenter.y.toFixed(0)})`;
          if (duplicatedCellXY.includes(coordText)) {
            continue;
          } else {
            duplicatedCellXY.push(coordText);
          }

          // draw cell
          this.ctx.beginPath();
          this.ctx.strokeStyle = "#DCFCE7";
          this.ctx.fillStyle = "#84CC16";
          this.ctx.lineWidth = 0.3 * this.imageOrigin.scale * this.canvasScale;
          this.ctx.arc(
            transformedCenter.x,
            transformedCenter.y,
            cellSize,
            0,
            2 * Math.PI
          );
          this.ctx.stroke();
          this.ctx.fill();
          this.ctx.closePath();
        }
      }

      // write rack name
      const transformedCenter = this.transformPointToCanvasCoord(object.center);
      const fontPixelSize =
        (object.width / object.resolution / object.rackName.length) *
        this.canvasScale *
        this.imageOrigin.scale *
        0.8;

      if (fontPixelSize > this.viewTextSize) {
        this.ctx.beginPath();

        this.ctx.font = `${fontPixelSize}px Arial`;
        if (this.conflictedRackNames.includes(object.rackName)) {
          this.ctx.fillStyle = "white";
        } else {
          this.ctx.fillStyle = "black";
        }
        this.ctx.save();
        this.ctx.translate(transformedCenter.x, transformedCenter.y);

        if (object.yaw > Math.PI / 2 || object.yaw < -Math.PI / 2) {
          this.ctx.rotate(object.yaw + Math.PI);
        } else {
          this.ctx.rotate(object.yaw);
        }
        this.ctx.fillText(`${object.rackName}`, 0, fontPixelSize / 4);
        this.ctx.restore();
      }
    } else {
      // write rack name
      const transformedCenter = this.transformPointToCanvasCoord(object.center);
      const fontPixelSize =
        (object.depth / object.resolution / String(object.rackId).length) *
        this.canvasScale *
        this.imageOrigin.scale *
        0.3;
      if (fontPixelSize > this.viewTextSize) {
        this.ctx.save();
        this.ctx.font = `${fontPixelSize}px Arial`;
        this.ctx.fillStyle = "black";
        this.ctx.translate(transformedCenter.x, transformedCenter.y);

        if (object.yaw > Math.PI / 2 || object.yaw < -Math.PI / 2) {
          this.ctx.rotate(object.yaw + Math.PI);
        } else {
          this.ctx.rotate(object.yaw);
        }
        this.ctx.fillText(`${object.rackId}`, 0, fontPixelSize / 2);
        this.ctx.restore();
      }
    }
    this.ctx.closePath();

    if (isSelectedObject) {
      this.ctx.restore();
    }

    if (isOnlySelectedObject) {
      // draw corners as filled circle
      this.ctx.save();
      this.drawRotator(
        this.transformPointToCanvasCoord(object.center),
        transformedPoints
      );
      this.ctx.restore();
    }
  }

  drawObject(objects) {
    if (!objects) return;
    if (objects.length < 1) {
      return;
    }

    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      const isHoveredObject = this.hoveringObjectIds.includes(object.id);
      const isSelectedObject = this.selectedObjectsList.includes(object.id);
      const isOnlySelectedObject =
        isSelectedObject && this.selectedObjectsList.length === 1;
      if (isHoveredObject || isOnlySelectedObject) {
        this.ctx.save();
        this.ctx.globalAlpha = 0.8;
      }
      switch (object.type) {
        case OBJECT_TYPES.RULER:
          if (object.data.length < 1) {
            return;
          }
          if (
            OBJECT_TYPES_TO_CATEGORY_MAP[object.type] !==
            this.activeObjectCategory
          ) {
            this.ctx.save();
            this.ctx.globalAlpha = 0.5;
          }
          this.drawRuler(
            object,
            isHoveredObject,
            isSelectedObject,
            isOnlySelectedObject
          );
          this.ctx.restore();
          break;
        case OBJECT_TYPES.DRAWING:
        case OBJECT_TYPES.LINE:
          if (object.data.length < 1) {
            return;
          }
          if (
            OBJECT_TYPES_TO_CATEGORY_MAP[object.type] !==
            this.activeObjectCategory
          ) {
            this.ctx.save();
            this.ctx.globalAlpha = 0.5;
          }
          this.drawPencil(
            object,
            isHoveredObject,
            isSelectedObject,
            isOnlySelectedObject
          );
          this.ctx.restore();
          break;
        case OBJECT_TYPES.RECTANGLE:
          if (
            OBJECT_TYPES_TO_CATEGORY_MAP[object.type] !==
            this.activeObjectCategory
          ) {
            this.ctx.save();
            this.ctx.globalAlpha = 0.5;
          }
          this.drawRectangle(
            object,
            isHoveredObject,
            isSelectedObject,
            isOnlySelectedObject
          );
          this.ctx.restore();
          break;
        case OBJECT_TYPES.VERTEX:
          if (
            OBJECT_TYPES_TO_CATEGORY_MAP[object.type] !==
            this.activeObjectCategory
          ) {
            this.ctx.save();
            this.ctx.globalAlpha = 0.5;
          }
          this.drawVertex(object, isHoveredObject, isSelectedObject);
          this.ctx.restore();
          break;
        case OBJECT_TYPES.EDGE:
          if (
            OBJECT_TYPES_TO_CATEGORY_MAP[object.type] !==
            this.activeObjectCategory
          ) {
            this.ctx.save();
            this.ctx.globalAlpha = 0.5;
          }
          this.drawEdge(object, isHoveredObject, isSelectedObject);
          this.ctx.restore();
          break;
        case OBJECT_TYPES.POI:
          if (
            OBJECT_TYPES_TO_CATEGORY_MAP[object.type] !==
            this.activeObjectCategory
          ) {
            this.ctx.save();
            this.ctx.globalAlpha = 0.5;
          }
          this.drawPoi(object, isHoveredObject, isOnlySelectedObject);
          this.ctx.restore();
          break;
        case OBJECT_TYPES.RACK:
          if (
            OBJECT_TYPES_TO_CATEGORY_MAP[object.type] !==
            this.activeObjectCategory
          ) {
            this.ctx.save();
            this.ctx.globalAlpha = 0.5;
          }
          this.drawRack(
            object,
            isHoveredObject,
            isSelectedObject,
            isOnlySelectedObject
          );
          this.ctx.restore();
          break;

        default:
          break;
      }
      if (isHoveredObject || isSelectedObject || isOnlySelectedObject) {
        this.ctx.restore();
      }
    }
  }

  setCursorStyle(cursorType) {
    this.canvas.style.cursor = `${cursorType}`;
  }

  updateRobotData(data) {
    this.robotPoses = data;
  }

  setCanvasBackgroundColor(currentTool) {
    if (!currentTool) return;

    this.canvas.style.backgroundColor = getToolColor(currentTool, 0.2);
  }

  drawGrid() {
    const imgX = this.imageOrigin.x;
    const imgY = this.imageOrigin.y;
    const imgScale = this.imageOrigin.scale;
    const meterPerPixel = this.mapMetadata.resolution;

    const gridColor = "rgba(0,0,0,0.1)";

    // draw grid
    this.ctx.beginPath();
    this.ctx.strokeStyle = gridColor;
    this.ctx.lineWidth = 1.5;
    this.ctx.lineCap = "butt";
    this.ctx.lineJoin = "bevel";
    let meterInterval = 1.0;
    if (imgScale > 5.0 && imgScale <= 10.0) {
      meterInterval = 1.0;
    } else if (imgScale < 0.8) {
      meterInterval = 5.0;
    } else if (imgScale > 10.0) {
      meterInterval = 0.05;
    }
    const gridInterval =
      (meterInterval / meterPerPixel) * imgScale * this.canvasScale;
    for (let x = imgX; x < this.canvasWidth; x += gridInterval) {
      this.ctx.moveTo(x, 0);
      this.ctx.lineTo(x, this.canvasHeight);
    }
    for (let y = imgY; y < this.canvasHeight; y += gridInterval) {
      this.ctx.moveTo(0, y);
      this.ctx.lineTo(this.canvasWidth, y);
    }
    for (let x = imgX; x > 0; x -= gridInterval) {
      this.ctx.moveTo(x, 0);
      this.ctx.lineTo(x, this.canvasHeight);
    }
    for (let y = imgY; y > 0; y -= gridInterval) {
      this.ctx.moveTo(0, y);
      this.ctx.lineTo(this.canvasWidth, y);
    }
    this.ctx.stroke();

    // write grid interval on fixed point with bar
    this.ctx.beginPath();
    this.ctx.strokeStyle = "black";
    this.ctx.lineWidth = 2;
    this.ctx.lineCap = "butt";
    this.ctx.lineJoin = "bevel";
    this.ctx.moveTo(this.canvasWidth - 30, 30);
    this.ctx.lineTo(this.canvasWidth - 30 - gridInterval, 30);
    this.ctx.moveTo(this.canvasWidth - 30, 30 - 10);
    this.ctx.lineTo(this.canvasWidth - 30, 30 + 10);
    this.ctx.moveTo(this.canvasWidth - 30 - gridInterval, 30 - 10);
    this.ctx.lineTo(this.canvasWidth - 30 - gridInterval, 30 + 10);
    this.ctx.stroke();

    this.ctx.font = `20px Arial`;
    this.ctx.fillStyle = "black";
    this.ctx.fillText(
      `${meterInterval}m`,
      this.canvasWidth - 45 - gridInterval / 2,
      60
    );
    this.ctx.stroke();
  }

  drawSelected() {
    if (this.entireSelectedRect.data.length < 1) {
      return;
    }

    const selectedRect = this.entireSelectedRect.data;
    const center = this.entireSelectedRect.center;
    const transformedCenter = this.transformPointToCanvasCoord(center);
    const p1 = this.transformPointToCanvasCoord(selectedRect[0]);
    const p2 = this.transformPointToCanvasCoord(selectedRect[1]);
    const p3 = this.transformPointToCanvasCoord(selectedRect[2]);
    const p4 = this.transformPointToCanvasCoord(selectedRect[3]);
    const transformedPoints = [p1, p2, p3, p4];
    this.ctx.beginPath();
    this.ctx.strokeStyle = "blue";
    this.ctx.lineWidth = 4;
    if (
      this.activeObjectCategory !== OBJECT_CATEGORY.RACK ||
      this.selectedObjectsList.length > 1
    ) {
      this.ctx.moveTo(transformedPoints[0].x, transformedPoints[0].y);
      this.ctx.lineTo(transformedPoints[1].x, transformedPoints[1].y);
      this.ctx.lineTo(transformedPoints[2].x, transformedPoints[2].y);
      this.ctx.lineTo(transformedPoints[3].x, transformedPoints[3].y);
      this.ctx.lineTo(transformedPoints[0].x, transformedPoints[0].y);
      this.ctx.stroke();
    }
    if (this.entireSelectedRect.drawCorner) {
      this.drawCorner(transformedCenter, transformedPoints);
    }
    if (
      this.entireSelectedRect.drawRotator &&
      this.entireSelectedRect.data.length > 1
    ) {
      this.drawRotator(transformedCenter, transformedPoints);
    }
  }

  drawDragging() {
    if (this.isSelectDragging) {
      // draw drag selection box with Lightly colored square
      const dragStart = this.draggingArea.mouseDownPos;
      const dragEnd = this.draggingArea.mouseCurrentPos;
      this.ctx.beginPath();
      this.ctx.strokeStyle = "rgba(0,0,0,0.5)";
      this.ctx.lineWidth = 1;
      this.ctx.lineCap = "round";
      this.ctx.lineJoin = "round";
      // skyblue
      this.ctx.fillStyle = "rgba(135,206,235,0.5)";
      this.ctx.fillRect(
        dragStart.x,
        dragStart.y,
        dragEnd.x - dragStart.x,
        dragEnd.y - dragStart.y
      );
    }
  }

  drawRobotPoses() {
    if (!this.robotPoses) return;
    for (let i = 0; i < this.robotPoses.length; i++) {
      const robotPose = this.robotPoses[i];
      const id = robotPose.id;
      const meterXY = {
        x: robotPose.x,
        y: robotPose.y,
      };
      const pixelXY = this.meterToPixel(meterXY);
      const yaw = -robotPose.yaw;
      const transformedPoints = this.transformPointToCanvasCoord(pixelXY);
      this.ctx.beginPath();
      this.ctx.strokeStyle = "black";
      this.ctx.fillStyle = "skyblue";
      this.ctx.lineWidth = 2;
      const p1 = {
        x: transformedPoints.x + 30 * this.imageOrigin.scale * Math.cos(yaw),
        y: transformedPoints.y + 30 * this.imageOrigin.scale * Math.sin(yaw),
      };
      const p2 = {
        x:
          transformedPoints.x +
          15 * this.imageOrigin.scale * Math.cos(yaw + (3 * Math.PI) / 4),
        y:
          transformedPoints.y +
          15 * this.imageOrigin.scale * Math.sin(yaw + (3 * Math.PI) / 4),
      };
      const p3 = {
        x:
          transformedPoints.x +
          15 * this.imageOrigin.scale * Math.cos(yaw - (3 * Math.PI) / 4),
        y:
          transformedPoints.y +
          15 * this.imageOrigin.scale * Math.sin(yaw - (3 * Math.PI) / 4),
      };
      this.ctx.moveTo(p1.x, p1.y);
      this.ctx.lineTo(p2.x, p2.y);
      this.ctx.lineTo(p3.x, p3.y);
      this.ctx.lineTo(p1.x, p1.y);
      this.ctx.stroke();
      this.ctx.fill();
      this.ctx.textAlign = "center";
      this.ctx.font = `${
        (20 * this.canvasScale * this.imageOrigin.scale) / 2 / 2
      }px Arial`;
      this.ctx.fillStyle = "white";
      this.ctx.fillText(
        `flody-${id}`,
        transformedPoints.x + 2,
        transformedPoints.y + 2
      );
      this.ctx.fillStyle = "black";
      this.ctx.fillText(
        `flody-${id}`,
        transformedPoints.x,
        transformedPoints.y
      );

      this.ctx.stroke();
    }
  }

  drawMapOrigin() {
    if (this.imageOrigin === undefined) return;
    const axisWidth = 3;
    const axisLength = 10;
    const origin = this.transformPointToCanvasCoord(this.mapOriginPixel);
    this.ctx.beginPath();
    this.ctx.strokeStyle = "red";
    this.ctx.lineWidth = axisWidth * this.canvasScale * this.imageOrigin.scale;
    this.ctx.moveTo(origin.x, origin.y);
    this.ctx.lineTo(
      origin.x + axisLength * this.canvasScale * this.imageOrigin.scale,
      origin.y
    );
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.strokeStyle = "green";
    this.ctx.moveTo(origin.x, origin.y);

    this.ctx.lineTo(
      origin.x,
      origin.y - axisLength * this.canvasScale * this.imageOrigin.scale
    );
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.strokeStyle = "blue";
    this.ctx.fillStyle = "blue";
    this.ctx.arc(
      origin.x,
      origin.y,
      (axisWidth / 2) * this.canvasScale * this.imageOrigin.scale,
      0,
      2 * Math.PI
    );
    this.ctx.fill();
  }

  refreshCanvas(currentObjects = null) {
    if (this.isInitialized) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      this.drawImage();
      this.drawGrid();
      this.drawObject(this.areaObjects);
      this.drawMapOrigin();
      this.drawRobotPoses();
      this.drawObject(this.poiObjects);
      this.drawObject(this.edgeObjects);
      this.drawObject(this.vertexObjects);
      this.drawObject(this.rackObjects);
      this.drawObject(this.rulerObjects);
      this.drawSelected();
      this.drawDragging();
      if (currentObjects !== null) {
        this.drawObject(currentObjects);
      }
    }
    // this.ctx.rotate(0.05);
  }
}
