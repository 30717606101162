import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useDataContext } from "context/DataContext";
import { useObjectContext } from "context/ObjectContext";
import { useToolContext } from "context/ToolContext";
import { UI_TOOL_NAMES, OBJECT_TYPES } from "common/TYPES";
import { ROBOT_STUDIO_API } from "common/API";
import axios from "axios";

import { Painter } from "features/Painter";
import { Cursor } from "features/Cursor";
import { PencilTool } from "features/PencilTool";
import { RectangleTool } from "features/RectangleTool";
import { TopologyTool } from "features/TopologyTool";
import { PoiTool } from "features/PoiTool";
import { RackTool } from "features/RackTool";
import { RulerTool } from "features/RulerTool";

const InteractionManager = ({
  mouseEvent,
  keyboardEvent,
  imageOrigin,
  setImageOrigin,
  canvasRef,
  canvasInfo,
  taskManagerRef,
}) => {
  const {
    objectsDict,
    setObjectsDict,
    selectedObjectsList,
    setSelectedObjectsList,
    invisibleObjectsList,
    activeObjectCategory,
    setActiveObjectCategory,
    hoveredObject,
    setHoveredObject,
  } = useObjectContext();
  const {
    currentTool,
    rectangleToolConfig,
    pencilToolConfig,
    topologyToolConfig,
    isVertexSettingModalEnabled,
    setIsVertexSettingModalEnabled,
    isEdgeSettingModalEnabled,
    setIsEdgeSettingModalEnabled,
    poiToolConfig,
    isPoiSettingModalEnabled,
    setIsPoiSettingModalEnabled,
    rackToolConfig,
    rulerToolConfig,
    zoomPanRef,
  } = useToolContext();

  const {
    navMapImage,
    localizationMapImage,
    mapMetadata,
    rackTypesData,
    locationCodePatternsData,
    setCanvasInfo,
  } = useDataContext();
  const [isPanning, setIsPanning] = useState(false);

  const painterRef = useRef(new Painter());

  const cursorRef = useRef(new Cursor());
  const pencilRef = useRef(new PencilTool(setObjectsDict));
  const rectangleRef = useRef(new RectangleTool(setObjectsDict));
  const topologyRef = useRef(new TopologyTool(setObjectsDict));
  const poiRef = useRef(new PoiTool(setObjectsDict));
  const rackRef = useRef(new RackTool(setObjectsDict));
  const rulerRef = useRef(new RulerTool(setObjectsDict));

  const activeToolRef = useRef(new PencilTool(setObjectsDict)); // default: cursor

  const [robotPose, setRobotPose] = useState(null);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(ROBOT_STUDIO_API.GET_ROBOT_POSES);

      setRobotPose(response.data);
      setError(null);
    } catch (err) {
      setError("Failed to fetch data");
    }
  };

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(fetchData, 500);

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    painterRef.current.updateRobotData(robotPose);
    painterRef.current.refreshCanvas(activeToolRef.current.currentObjects);
  }, [robotPose]);

  useEffect(() => {
    const intervalId = setInterval(
      painterRef.current.refreshCanvas(activeToolRef.current.currentObjects),
      500
    );
    // 컴포넌트가 언마운트될 때 interval을 정리합니다
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    zoomPanRef.current.init(
      imageOrigin,
      setImageOrigin,
      canvasRef.current,
      setIsPanning
    );
    cursorRef.current.init(
      imageOrigin,
      mapMetadata,
      canvasInfo,
      painterRef.current,
      taskManagerRef.current,
      selectedObjectsList,
      setSelectedObjectsList,
      setObjectsDict,
      setHoveredObject,
      setActiveObjectCategory
    );
    painterRef.current.init(
      canvasRef.current,
      navMapImage,
      localizationMapImage,
      mapMetadata,
      canvasInfo,
      imageOrigin
    );

    pencilRef.current.init(
      imageOrigin,
      canvasInfo,
      canvasRef.current,
      taskManagerRef.current,
      pencilToolConfig,
      setSelectedObjectsList
    );
    rectangleRef.current.init(
      imageOrigin,
      canvasInfo,
      canvasRef.current,
      taskManagerRef.current,
      rectangleToolConfig,
      setSelectedObjectsList,
      setHoveredObject
    );
    topologyRef.current.init(
      imageOrigin,
      mapMetadata,
      canvasRef.current,
      canvasInfo,
      taskManagerRef.current,
      setIsVertexSettingModalEnabled,
      setIsEdgeSettingModalEnabled
    );

    poiRef.current.init(
      imageOrigin,
      mapMetadata,
      canvasRef.current,
      canvasInfo,
      taskManagerRef.current,
      setIsPoiSettingModalEnabled
    );

    rackRef.current.init(
      imageOrigin,
      mapMetadata,
      canvasRef.current,
      canvasInfo,
      taskManagerRef.current,
      setSelectedObjectsList
    );

    rulerRef.current.init(
      imageOrigin,
      mapMetadata,
      canvasRef.current,
      canvasInfo,
      taskManagerRef.current
    );
    // painterRef.current.refreshCanvas();
  }, []);

  // update pencil tool config
  useEffect(() => {
    pencilRef.current.updateConfig(pencilToolConfig);
  }, [pencilToolConfig]);

  // update rectangle tool config
  useEffect(() => {
    rectangleRef.current.updateConfig(rectangleToolConfig);
  }, [rectangleToolConfig]);

  // update topology tool config
  useEffect(() => {
    topologyRef.current.updateConfig(topologyToolConfig);
    cursorRef.current.updateVertexSize(topologyToolConfig.vertexSize);
    // painterRef.current.refreshCanvas();
  }, [topologyToolConfig]);

  // update poi tool config
  useEffect(() => {
    poiRef.current.updateConfig(poiToolConfig);
  }, [poiToolConfig]);

  // update rack tool config
  useEffect(() => {
    rackRef.current.updateConfig(
      rackToolConfig,
      rackTypesData,
      locationCodePatternsData
    );
  }, [rackToolConfig, rackTypesData, locationCodePatternsData]);

  // update ruler tool config
  useEffect(() => {
    rulerRef.current.updateConfig(rulerToolConfig);
  }, [rulerToolConfig]);

  useEffect(() => {
    // painterRef.current.refreshCanvas();
    poiRef.current.updateModalEnabled(isPoiSettingModalEnabled);
    topologyRef.current.updateModalEnabled(
      isVertexSettingModalEnabled,
      isEdgeSettingModalEnabled
    );
  }, [
    isEdgeSettingModalEnabled,
    isVertexSettingModalEnabled,
    isPoiSettingModalEnabled,
  ]);
  // mouseEvent
  useEffect(() => {
    zoomPanRef.current.listenMouseAction(mouseEvent);

    if (!isPanning) {
      activeToolRef.current.listenMouseAction(mouseEvent);
    }
    painterRef.current.refreshCanvas(activeToolRef.current.currentObjects);
  }, [mouseEvent, isPanning]);

  // keyboardEvent
  useEffect(() => {
    zoomPanRef.current.listenKeyboardAction(keyboardEvent);
    activeToolRef.current.listenKeyboardAction(keyboardEvent);
    taskManagerRef.current.listenKeyboardAction(keyboardEvent);
    painterRef.current.changeImage(keyboardEvent);
    painterRef.current.refreshCanvas(activeToolRef.current.currentObjects);
  }, [keyboardEvent]);

  // imageOrigin
  useEffect(() => {
    painterRef.current.updateImageOrigin(imageOrigin);
    activeToolRef.current.updateImageOrigin(imageOrigin);
  }, [imageOrigin]);

  const resized = () => {
    const canvasWidth = Math.round(
      canvasRef.current.getBoundingClientRect().width * canvasInfo.scale
    );
    const canvasHeight = Math.round(
      canvasRef.current.getBoundingClientRect().height * canvasInfo.scale
    );
    setCanvasInfo({
      canvasWidth: canvasWidth,
      canvasHeight: canvasHeight,
      scale: canvasInfo.scale,
    });
    canvasRef.current.width = canvasWidth;
    canvasRef.current.height = canvasHeight;
  };
  useEffect(() => {
    window.addEventListener("resize", resized);

    painterRef.current.updateCanvasInfo(canvasInfo);
    activeToolRef.current.updateCanvasInfo(canvasInfo);
    cursorRef.current.updateCanvasInfo(canvasInfo);
    pencilRef.current.updateCanvasInfo(canvasInfo);
    rectangleRef.current.updateCanvasInfo(canvasInfo);
    topologyRef.current.updateCanvasInfo(canvasInfo);
    poiRef.current.updateCanvasInfo(canvasInfo);
    rackRef.current.updateCanvasInfo(canvasInfo);
    rulerRef.current.updateCanvasInfo(canvasInfo);
    taskManagerRef.current.updateCanvasInfo(canvasInfo);
  }, [canvasInfo]);

  // objectsDict
  useEffect(() => {
    // console.log(objectsDict);
    painterRef.current.updateObjectsDict(objectsDict);
    pencilRef.current.updateObjectsDict(objectsDict);
    rectangleRef.current.updateObjectsDict(objectsDict);
    topologyRef.current.updateObjectsDict(objectsDict);
    poiRef.current.updateObjectsDict(objectsDict);
    rackRef.current.updateObjectsDict(objectsDict);
    cursorRef.current.updateObjectsDict(objectsDict);
    activeToolRef.current.updateObjectsDict(objectsDict);

    // cursorRef.current.updateObjectsDict(objectsDict);
    taskManagerRef.current.updateObjectsDict(objectsDict);
    painterRef.current.refreshCanvas(activeToolRef.current.currentObjects);
    // console.log(objectsDict);
  }, [objectsDict]);

  // selectedObjectsList
  useEffect(() => {
    cursorRef.current.updateSelectedObjectsList(selectedObjectsList);
    rackRef.current.updateSelectedObjectsList(selectedObjectsList);
    painterRef.current.updateSelectedObjectsList(selectedObjectsList);
    taskManagerRef.current.updateSelectedObjectsList(selectedObjectsList);
    painterRef.current.refreshCanvas(activeToolRef.current.currentObjects);
  }, [selectedObjectsList]);

  // invisibleObjectsList
  useEffect(() => {
    cursorRef.current.updateInvisibleObjectsList(invisibleObjectsList);
    painterRef.current.updateInvisibleObjectsList(invisibleObjectsList);
    painterRef.current.refreshCanvas(activeToolRef.current.currentObjects);
  }, [invisibleObjectsList]);

  // activeObjectCategory
  useEffect(() => {
    painterRef.current.updateActiveObjectType(activeObjectCategory);
    cursorRef.current.updateActiveObjectType(activeObjectCategory);
    taskManagerRef.current.updateActiveObjectType(activeObjectCategory);

    painterRef.current.refreshCanvas(activeToolRef.current.currentObjects);
  }, [activeObjectCategory]);

  // hoveredObject
  useEffect(() => {
    painterRef.current.updateHover(hoveredObject);
    painterRef.current.refreshCanvas(activeToolRef.current.currentObjects);
  }, [hoveredObject]);

  useEffect(() => {
    if (currentTool !== undefined) {
      activeToolRef.current.resetCurrentObjects();
      setHoveredObject([]);
      setSelectedObjectsList([]);

      switch (currentTool) {
        case UI_TOOL_NAMES.CURSOR:
          activeToolRef.current = cursorRef.current;
          break;
        case UI_TOOL_NAMES.PENCIL:
          // cursorRef.current.resetSelected();
          activeToolRef.current = pencilRef.current;

          break;
        case UI_TOOL_NAMES.RECTANGLE:
          // cursorRef.current.resetSelected();
          activeToolRef.current = rectangleRef.current;
          break;
        case UI_TOOL_NAMES.TOPOLOGY:
          // cursorRef.current.resetSelected();
          topologyRef.current.currentObjects = [];
          activeToolRef.current = topologyRef.current;
          break;
        case UI_TOOL_NAMES.POI:
          // cursorRef.current.resetSelected();
          activeToolRef.current = poiRef.current;
          break;
        case UI_TOOL_NAMES.RACK:
          // cursorRef.current.resetSelected();
          activeToolRef.current = rackRef.current;
          break;
        case UI_TOOL_NAMES.STOP:
          // cursorRef.current.resetSelected();
          // activeToolRef.current = stopRef.current;
          break;
        case UI_TOOL_NAMES.RULER:
          // cursorRef.current.resetSelected();
          activeToolRef.current = rulerRef.current;
          break;
        default:
          break;
      }
      activeToolRef.current.updateImageOrigin(imageOrigin);
      activeToolRef.current.setTool(currentTool);
      zoomPanRef.current.setTool(currentTool);

      // painterRef.current.refreshCanvas();
    }
  }, [currentTool]);

  return <></>;
};

export default InteractionManager;
