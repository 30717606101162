import {
  OBJECT_TYPES,
  OBJECT_TYPES_TO_CATEGORY_MAP,
  UI_TOOL_NAMES,
  OBJECT_CATEGORY,
} from "common/TYPES";
import {
  transformPointToOriginalImageCoord,
  checkPointOnEdgeCenter,
  checkPointOnVertex,
  checkPointOnPoi,
  checkPointInRack,
  checkPointInRectangle,
  checkPointOnDrawing,
  checkPointOnCorner,
  checkPointNearCorner,
} from "features/utils";

export class Cursor {
  constructor() {
    this.imageOrigin = {};
    this.activeObjects = [];
    this.selectedRect = {
      data: [],
      center: { x: 0, y: 0 },
      drawCorner: false,
      drawRotator: false,
    };
    this.isSelectDragging = false;
    this.isMoving = false;
    this.isRotating = false;
    this.arrowPressed = false;
    this.transformedMouseDownPos = { x: 0, y: 0 };
    this.transformedMouseCurrentPos = { x: 0, y: 0 };
    this.transformedMouseUpPos = { x: 0, y: 0 };
    this.transformedPreviousMousePos = { x: 0, y: 0 };
    this.checkPointInBox = checkPointInRectangle;
  }

  init(
    imageOrigin,
    mapMetadata,
    canvasInfo,
    painter,
    taskManager,
    selectedObjectsList,
    setSelectedObjectsList,
    setObjectsDict,
    setHoveredObject,
    setActiveObjectCategory
  ) {
    this.imageOrigin = imageOrigin;
    this.mapMetadata = mapMetadata;
    this.canvasInfo = canvasInfo;
    this.canvasScale = canvasInfo.scale;
    this.selectedObjectsList = selectedObjectsList;
    this.setSelectedObjectsList = setSelectedObjectsList;
    this.painter = painter;
    this.taskManager = taskManager;
    this.setObjectsDict = setObjectsDict;
    this.setHoveredObject = setHoveredObject;
    this.setActiveObjectCategory = setActiveObjectCategory;
  }
  resetCurrentObjects() {
    // this.activeObjects = [];
    this.resetSelected();
  }
  setTool(currentTool) {
    this.currentTool = currentTool;
    if (this.currentTool === UI_TOOL_NAMES.CURSOR) {
      this.isActivated = true;
    } else {
      this.isActivated = false;
      this.resetSelected();
    }
  }
  updateImageOrigin(imageOrigin) {
    if (!imageOrigin) return;
    this.imageOrigin = imageOrigin;
  }
  updateCanvasInfo(canvasInfo) {
    this.canvasInfo = canvasInfo;
    this.canvasScale = canvasInfo.scale;
  }

  updateVertexSize(vertexSize) {
    this.vertexSize = vertexSize;
  }
  updateActiveObjectType(activeObjectCategory) {
    this.activeObjectCategory = activeObjectCategory;
    if (
      this.activeObjectCategory !== OBJECT_CATEGORY.RACK ||
      this.selectedObjectsList.length > 1
    ) {
      this.checkPointInBox = checkPointInRectangle;
    } else {
      this.checkPointInBox = checkPointInRack;
    }
    if (this.objectsDict) {
      this.activeObjects = [];

      const keys = Object.keys(this.objectsDict);
      for (let i = 0; i < keys.length; i++) {
        const objectType = this.objectsDict[keys[i]].type;
        if (
          OBJECT_TYPES_TO_CATEGORY_MAP[objectType] ===
            this.activeObjectCategory &&
          !this.invisibleObjectsList.includes(keys[i])
        ) {
          this.activeObjects.push(this.objectsDict[keys[i]]);
        }
      }
    }
  }
  updateObjectsDict(objectsDict) {
    this.objectsDict = objectsDict;
    if (this.objectsDict) {
      this.activeObjects = [];

      const keys = Object.keys(this.objectsDict);
      for (let i = 0; i < keys.length; i++) {
        const objectType = this.objectsDict[keys[i]].type;
        if (
          OBJECT_TYPES_TO_CATEGORY_MAP[objectType] ===
            this.activeObjectCategory &&
          !this.invisibleObjectsList.includes(keys[i])
        ) {
          this.activeObjects.push(this.objectsDict[keys[i]]);
        }
      }
    }

    let filteredSelectedObjectsList = [];
    for (let i = 0; i < this.selectedObjectsList.length; i++) {
      const key = this.selectedObjectsList[i];
      if (Object.keys(this.objectsDict).includes(key)) {
        filteredSelectedObjectsList.push(key);
      }
    }
    this.selectedObjectsList = filteredSelectedObjectsList;
    this.updateEntireSelectedRect();
  }
  updateSelectedObjectsList(selectedObjectsList) {
    this.selectedObjectsList = selectedObjectsList;
    if (
      this.activeObjectCategory !== OBJECT_CATEGORY.RACK ||
      this.selectedObjectsList.length > 1
    ) {
      this.checkPointInBox = checkPointInRectangle;
    } else {
      this.checkPointInBox = checkPointInRack;
    }
    this.updateEntireSelectedRect();
  }

  updateInvisibleObjectsList(invisibleObjectsList) {
    this.invisibleObjectsList = invisibleObjectsList;
    if (this.objectsDict) {
      this.activeObjects = [];
      const keys = Object.keys(this.objectsDict);
      for (let i = 0; i < keys.length; i++) {
        const objectType = this.objectsDict[keys[i]].type;
        if (
          OBJECT_TYPES_TO_CATEGORY_MAP[objectType] ===
            this.activeObjectCategory &&
          !this.invisibleObjectsList.includes(keys[i])
        ) {
          this.activeObjects.push(this.objectsDict[keys[i]]);
        }
      }
    }
  }

  listenMouseAction(mouseEvent) {
    if (!this.isActivated) return;
    const button = mouseEvent.event.button;
    const deltaY = mouseEvent.event.deltaY; // scroll down : positive, scroll up : negative
    const x = mouseEvent.event.offsetX;
    const y = mouseEvent.event.offsetY;
    const isShiftPressed = mouseEvent.event.shiftKey;
    const isCtrlPressed = mouseEvent.event.ctrlKey;
    const isAltPressed = mouseEvent.event.altKey;
    const isMetaPressed = mouseEvent.event.metaKey;

    let isCursorOnObject = false;
    let isCursorOnEntireSelectedRect = false;
    let isCursorNearCorner = false;
    let closestObjectID;
    switch (mouseEvent.action) {
      case "mousedown":
        this.mouseDownPos = { x, y };
        if (button === 0) {
          this.isMouseLeftDown = true;
        } else if (button === 1) {
          this.isMouseMiddleDown = true;
        } else if (button === 2) {
          this.isMouseRightDown = true;
        }

        this.transformedMouseDownPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin,
          this.canvasScale
        );
        this.transformedPreviousMousePos = { ...this.transformedMouseDownPos };

        isCursorOnObject = false;
        isCursorNearCorner = false;
        isCursorOnEntireSelectedRect = false;
        closestObjectID = null;
        [isCursorOnObject, closestObjectID] = this.checkPointOnObject(
          this.transformedMouseDownPos
        );
        if (this.selectedRect.data.length > 0) {
          isCursorOnEntireSelectedRect = this.checkPointInBox(
            this.transformedMouseDownPos,
            this.selectedRect,
            this.imageOrigin.scale
          );
          isCursorNearCorner =
            checkPointNearCorner(
              this.transformedMouseDownPos,
              this.selectedRect,
              this.imageOrigin.scale
            ) && this.selectedRect.drawRotator;
          isCursorOnObject = isCursorOnObject && !isCursorNearCorner;
        } else {
        }

        // single click on object
        if (this.isMouseLeftDown && !this.isSpacePressed) {
          if (isCursorOnObject) {
            // 오브젝트 위에 클릭했을 때
            if (!isCtrlPressed) {
              // ctrl 누르지 않았을 때
              if (!isCursorOnEntireSelectedRect && !isCursorNearCorner) {
                // 선택된 사각형 위 그리고 코너를 클릭하지 않았을 때
                this.select(closestObjectID); // 해당 오브젝트 선택
              } else if (isCursorOnEntireSelectedRect) {
                // 선택된 사각형 위를 클릭했을 때
                this.isMoving = true;
                this.taskManager.setSelectedToHistory("UPDATE");
              } else if (isCursorNearCorner) {
                // 코너를 클릭했을 때
                // rotate selected objects
                this.isRotating = true;
                this.painter.setCursorStyle("grabbing");

                this.taskManager.setSelectedToHistory("UPDATE");
              }
            } else {
              // ctrl 눌렀을 때
              this.selectAdd(closestObjectID); // 해당 오브젝트 선택 추가
            }
          } else {
            // 오브젝트 위가 아닌 곳을 클릭했을 때
            if (!isCursorOnEntireSelectedRect && !isCursorNearCorner) {
              // 선택된 사각형 위 그리고 코너를 클릭하지 않았을 때
              this.resetSelected(); // 선택된 오브젝트 초기화
              this.isSelectDragging = true;
            } else if (isCursorNearCorner) {
              // 코너를 클릭했을 때
              // rotate selected objects
              this.isRotating = true;
              this.taskManager.setSelectedToHistory("UPDATE");
            } else if (isCursorOnEntireSelectedRect && !this.isSelectDragging) {
              // 선택된 사각형 위를 클릭했을 때
              // moving selected objects
              this.isMoving = true;
              this.taskManager.setSelectedToHistory("UPDATE");
            }
          }
        }

        break;
      case "mousemove":
        this.mouseCurrentPos = { x, y };
        this.transformedMouseCurrentPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin,
          this.canvasScale
        );

        isCursorOnObject = false;
        isCursorNearCorner = false;
        isCursorOnEntireSelectedRect = false;
        closestObjectID = null;
        [isCursorOnObject, closestObjectID] = this.checkPointOnObject(
          this.transformedMouseCurrentPos
        );
        if (this.selectedRect.data.length > 0) {
          isCursorOnEntireSelectedRect = this.checkPointInBox(
            this.transformedMouseCurrentPos,
            this.selectedRect,
            this.imageOrigin.scale
          );
          isCursorNearCorner =
            checkPointNearCorner(
              this.transformedMouseCurrentPos,
              this.selectedRect,
              this.imageOrigin.scale
            ) && this.selectedRect.drawRotator;
        }
        if (
          !this.isMoving &&
          !this.isRotating &&
          !this.isSelectDragging &&
          !this.isSpacePressed &&
          this.isMouseLeftDown &&
          isCursorOnEntireSelectedRect &&
          !isCursorNearCorner
        ) {
          this.isMoving = true;
          this.taskManager.addHistory(
            [this.objectsDict[closestObjectID]],
            "UPDATE"
          );
        }

        if (
          !this.isMouseLeftDown &&
          !this.isSpacePressed &&
          !this.isMoving &&
          !isCursorOnEntireSelectedRect &&
          ((isCursorOnObject &&
            !isCursorOnEntireSelectedRect &&
            !isCursorNearCorner) ||
            (isCursorOnObject && isCtrlPressed))
        ) {
          this.painter.setCursorStyle("pointer");
          this.setHoveredObject([closestObjectID]);
        } else if (
          !this.isMouseLeftDown &&
          isCursorOnEntireSelectedRect &&
          !isCursorNearCorner &&
          !this.isSpacePressed &&
          !isCtrlPressed
        ) {
          this.painter.setCursorStyle("move");
        } else if (
          !this.isMouseLeftDown &&
          !isCursorOnEntireSelectedRect &&
          !isCtrlPressed &&
          !this.isSpacePressed &&
          isCursorNearCorner
        ) {
          this.painter.setCursorStyle("grab");
        } else if (!this.isSpacePressed && !this.isMoving && !this.isRotating) {
          this.painter.setCursorStyle("default");
          this.setHoveredObject([]);
        } else if (!this.isSpacePressed && this.isMoving) {
          this.painter.setCursorStyle("move");
        } else if (!this.isSpacePressed && this.isRotating) {
          this.painter.setCursorStyle("grabbing");
        }

        if (
          // moving selected objects
          this.isMouseLeftDown &&
          this.isMoving &&
          !isCtrlPressed &&
          !isCursorNearCorner
        ) {
          const dx =
            this.transformedMouseCurrentPos.x -
            this.transformedPreviousMousePos.x;
          const dy =
            this.transformedMouseCurrentPos.y -
            this.transformedPreviousMousePos.y;
          this.translateSelectedObjects(dx, dy);
          this.transformedPreviousMousePos = {
            ...this.transformedMouseCurrentPos,
          };
        } else if (
          // select dragging
          this.isMouseLeftDown &&
          this.isSelectDragging &&
          !isCtrlPressed &&
          !isCursorNearCorner
        ) {
          this.painter.updateDrag(
            this.isSelectDragging,
            this.mouseDownPos,
            this.mouseCurrentPos
          );
          this.selectDragging(
            { ...this.transformedMouseDownPos },
            { ...this.transformedMouseCurrentPos }
          );
        } else if (
          // rotate selected objects
          this.isMouseLeftDown &&
          this.isRotating &&
          !isCtrlPressed &&
          !this.isMoving &&
          !this.isSelectDragging
        ) {
          const center = this.selectedRect.center;
          const angle =
            Math.atan2(
              this.transformedMouseCurrentPos.y - center.y,
              this.transformedMouseCurrentPos.x - center.x
            ) -
            Math.atan2(
              this.transformedPreviousMousePos.y - center.y,
              this.transformedPreviousMousePos.x - center.x
            );
          for (let i = 0; i < this.selectedObjectsList.length; i++) {
            const object = this.objectsDict[this.selectedObjectsList[i]];
            object.rotate(center, angle);
            this.setObjectsDict({ type: "UPDATE", payload: object });
            this.objectsDict[this.selectedObjectsList[i]] = object;
            if (object.type === OBJECT_TYPES.VERTEX) {
              const edges = Object.values(this.objectsDict).filter(
                (object) => object.type === OBJECT_TYPES.EDGE
              );
              const connectedEdges = edges.filter((edge) =>
                edge.vertices.includes(object.id)
              );
              for (let i = 0; i < connectedEdges.length; i++) {
                const edge = connectedEdges[i];
                edge.update(
                  this.objectsDict,
                  this.mapMetadata.resolution,
                  this.canvasInfo.scale
                );
                this.setObjectsDict({ type: "UPDATE", payload: edge });
              }
            }
          }
          this.transformedPreviousMousePos = {
            ...this.transformedMouseCurrentPos,
          };
          this.updateEntireSelectedRect();
        }

        break;
      case "mouseleave":
      case "mouseup":
        this.mouseUpPos = { x, y };

        this.transformedMouseUpPos = transformPointToOriginalImageCoord(
          this.mouseUpPos,
          this.imageOrigin,
          this.canvasScale
        );

        isCursorOnObject = false;
        isCursorNearCorner = false;
        isCursorOnEntireSelectedRect = false;
        closestObjectID = null;
        [isCursorOnObject, closestObjectID] = this.checkPointOnObject(
          this.transformedMouseUpPos
        );
        if (this.selectedRect.data.length > 0) {
          isCursorOnEntireSelectedRect = checkPointInRectangle(
            this.transformedMouseUpPos,
            this.selectedRect,
            this.imageOrigin.scale
          );
          isCursorNearCorner =
            checkPointNearCorner(
              this.transformedMouseUpPos,
              this.selectedRect,
              this.imageOrigin.scale
            ) && this.selectedRect.drawRotator;
        }

        if (
          this.transformedMouseDownPos.x === this.transformedMouseUpPos.x &&
          this.transformedMouseDownPos.y === this.transformedMouseUpPos.y &&
          isCursorOnObject &&
          this.isMouseLeftDown &&
          !isCtrlPressed
        ) {
          this.select(closestObjectID);
        } else if (this.isSelectDragging) {
          this.selectDragging(
            { ...this.transformedMouseDownPos },
            { ...this.transformedMouseUpPos }
          );
          this.isSelectDragging = false;
          this.painter.updateDrag(
            this.isSelectDragging,
            this.mouseDownPos,
            this.mouseUpPos
          );
        }
        if (this.isMoving) {
          this.isMoving = false;
        } else if (this.isRotating) {
          this.isRotating = false;
        }

        this.isMouseLeftDown = false;
        this.isMouseMiddleDown = false;
        this.isMouseRightDown = false;
        break;
      default:
        break;
    }
  }

  listenKeyboardAction(keyboardEvent) {
    if (!this.isActivated) return;
    const event = keyboardEvent.event;
    const action = keyboardEvent.action;
    const key = event.key;
    const isShiftPressed = event.shiftKey;
    const isCtrlPressed = event.ctrlKey;
    const isAlt = event.altKey;
    const isMeta = event.metaKey;

    switch (action) {
      case "keydown":
        switch (key) {
          //! space-pressed
          case " ":
            this.isSpacePressed = true;

            break;
          //! select-all
          case "a":
          case "A":
            if (isCtrlPressed) {
              this.selectAll();
            }
            break;
          //! reset selected
          case "Escape":
            this.resetSelected();

            break;
          case "ArrowUp":
            if (this.arrowPressed === false) {
              this.taskManager.setSelectedToHistory("UPDATE");
            }
            this.arrowPressed = true;
            this.translateSelectedObjects(0, -1);
            break;
          case "ArrowDown":
            if (this.arrowPressed === false) {
              this.taskManager.setSelectedToHistory("UPDATE");
            }
            this.arrowPressed = true;
            this.translateSelectedObjects(0, 1);

            break;
          case "ArrowLeft":
            if (this.arrowPressed === false) {
              this.taskManager.setSelectedToHistory("UPDATE");
            }
            this.arrowPressed = true;
            this.translateSelectedObjects(-1, 0);

            break;
          case "ArrowRight":
            if (this.arrowPressed === false) {
              this.taskManager.setSelectedToHistory("UPDATE");
            }
            this.arrowPressed = true;
            this.translateSelectedObjects(1, 0);
            break;

          default:
            break;
        }
        break;

      case "keyup":
        switch (key) {
          //! space-released
          case " ":
            this.isSpacePressed = false;
            break;
          case "ArrowUp":
          case "ArrowDown":
          case "ArrowLeft":
          case "ArrowRight":
            this.arrowPressed = false;
            break;
          case "Tab":
            break;
          default:
            break;
        }
        break;
      //none
      default:
        break;
    }
  }

  getClosestObjectID(xy) {
    let minDist = 100000000;
    let closestObjectID = null;
    for (let i = 0; i < this.activeObjects.length; i++) {
      const object = this.activeObjects[i];
      const dist = Math.sqrt(
        (object.center.x - xy.x) ** 2 + (object.center.y - xy.y) ** 2
      );
      if (dist < minDist) {
        minDist = dist;
        closestObjectID = object.id;
      }
    }
    return closestObjectID;
  }

  checkPointOnObject(xy) {
    let result = false;
    let objectID = null;
    for (let object of this.activeObjects) {
      if (object === undefined) {
        continue;
      }
      if (object.type === undefined) {
        continue;
      }
      switch (object.type) {
        case OBJECT_TYPES.RECTANGLE:
          result = checkPointInRectangle(xy, object, this.imageOrigin.scale);
          if (result) {
            objectID = object.id;
            return [result, objectID];
          } else {
            break;
          }
        case OBJECT_TYPES.DRAWING:
        case OBJECT_TYPES.LINE:
          result = checkPointOnDrawing(xy, object);
          if (result) {
            objectID = object.id;
            return [result, objectID];
          } else {
            break;
          }

        case OBJECT_TYPES.VERTEX:
          result = checkPointOnVertex(xy, object, this.mapMetadata.resolution);
          if (result) {
            objectID = object.id;
            return [result, objectID];
          } else {
            break;
          }

        case OBJECT_TYPES.EDGE:
          result = checkPointOnEdgeCenter(
            xy,
            object,
            this.vertexSize,
            this.mapMetadata.resolution
          );
          if (result) {
            objectID = object.id;
            return [result, objectID];
          } else {
            break;
          }

        case OBJECT_TYPES.POI:
          result = checkPointOnPoi(xy, object, this.mapMetadata.resolution);
          if (result) {
            objectID = object.id;
            return [result, objectID];
          } else {
            break;
          }

        case OBJECT_TYPES.RACK:
          result = checkPointInRack(xy, object, this.imageOrigin.scale);
          if (result) {
            objectID = object.id;
            return [result, objectID];
          } else {
            break;
          }

        default:
          break;
      }
    }
    return [result, objectID];
  }

  updateEntireSelectedRect(margin = 0) {
    if (this.selectedObjectsList.length < 1) {
      this.selectedRect = {
        data: [],
        center: { x: 0, y: 0 },
        drawCorner: false,
        drawRotator: false,
      };
      this.painter.updateEntireSelectedRect(this.selectedRect);
      return;
    } else if (this.selectedObjectsList.length === 1) {
      const object = this.objectsDict[this.selectedObjectsList[0]];
      if (object.type === OBJECT_TYPES.EDGE) {
        this.painter.updateEntireSelectedRect({
          data: [],
          center: { x: 0, y: 0 },
          drawCorner: false,
          drawRotator: false,
        });
        return;
      } else if (object.type === OBJECT_TYPES.POI) {
        margin = object.size / this.mapMetadata.resolution;

        let minX = object.center.x - margin * 2;
        let minY = object.center.y - margin * 2;
        let maxX = object.center.x + margin * 2;
        let maxY = object.center.y + margin * 2;
        this.selectedRect = {
          data: [
            { x: minX, y: minY },
            { x: maxX, y: minY },
            { x: maxX, y: maxY },
            { x: minX, y: maxY },
          ],
          center: object.center,
          drawCorner: false,
          drawRotator: true,
        };
        this.painter.updateEntireSelectedRect(this.selectedRect);
        return;
      } else if (object.type === OBJECT_TYPES.VERTEX) {
        margin = this.vertexSize / this.mapMetadata.resolution;
        let minX = object.center.x - margin;
        let minY = object.center.y - margin;
        let maxX = object.center.x + margin;
        let maxY = object.center.y + margin;
        this.selectedRect = {
          data: [
            { x: minX, y: minY },
            { x: maxX, y: minY },
            { x: maxX, y: maxY },
            { x: minX, y: maxY },
          ],
          center: object.center,
          drawCorner: false,
          drawRotator: false,
        };
        this.painter.updateEntireSelectedRect(this.selectedRect);
        return;
      } else if (object.type === OBJECT_TYPES.RECTANGLE) {
        let minX = Math.min(...object.data.map((point) => point.x));
        let maxX = Math.max(...object.data.map((point) => point.x));
        let minY = Math.min(...object.data.map((point) => point.y));
        let maxY = Math.max(...object.data.map((point) => point.y));
        this.selectedRect = {
          data: object.data,
          center: {
            x: (minX + maxX) / 2,
            y: (minY + maxY) / 2,
          },
          drawCorner: true,
          drawRotator: true,
        };
        this.painter.updateEntireSelectedRect(this.selectedRect);
        return;
      } else if (object.type === OBJECT_TYPES.DRAWING) {
        let minX = Math.min(...object.data.map((point) => point.x));
        let maxX = Math.max(...object.data.map((point) => point.x));
        let minY = Math.min(...object.data.map((point) => point.y));
        let maxY = Math.max(...object.data.map((point) => point.y));
        const p1 = { x: minX, y: minY };
        const p2 = { x: maxX, y: minY };
        const p3 = { x: maxX, y: maxY };
        const p4 = { x: minX, y: maxY };
        const corners = [p1, p2, p3, p4];
        this.selectedRect = {
          data: corners,
          center: {
            x: (minX + maxX) / 2,
            y: (minY + maxY) / 2,
          },
          drawCorner: false,
          drawRotator: true,
        };
        this.painter.updateEntireSelectedRect(this.selectedRect);
        return;
      } else if (object.type === OBJECT_TYPES.RACK) {
        let minX = Math.min(...object.data.map((point) => point.x));
        let maxX = Math.max(...object.data.map((point) => point.x));
        let minY = Math.min(...object.data.map((point) => point.y));
        let maxY = Math.max(...object.data.map((point) => point.y));
        this.selectedRect = {
          data: object.data,
          center: {
            x: (minX + maxX) / 2,
            y: (minY + maxY) / 2,
          },
          drawCorner: false,
          drawRotator: true,
        };
        this.painter.updateEntireSelectedRect(this.selectedRect);
        return;
      }
    }
    if (this.activeObjectCategory === OBJECT_CATEGORY.TOPOLOGY) {
      margin = this.vertexSize / this.mapMetadata.resolution;
      // check if only edges are selected
      let isOnlyEdges = true;
      for (let i = 0; i < this.selectedObjectsList.length; i++) {
        const object = this.objectsDict[this.selectedObjectsList[i]];
        if (object.type !== OBJECT_TYPES.EDGE) {
          isOnlyEdges = false;
          break;
        }
      }
      if (isOnlyEdges) {
        this.painter.updateEntireSelectedRect({
          data: [],
          center: { x: 0, y: 0 },
          drawCorner: false,
          drawRotator: false,
        });
        return;
      }
    }

    let minX = 100000000;
    let minY = 100000000;
    let maxX = -100000000;
    let maxY = -100000000;
    for (let i = 0; i < this.selectedObjectsList.length; i++) {
      const object = this.objectsDict[this.selectedObjectsList[i]];
      if (object.type === OBJECT_TYPES.POI) {
        margin = object.size / this.mapMetadata.resolution;
      } else if (object.type === OBJECT_TYPES.EDGE) {
        continue;
      }
      let points;
      if (object.data) {
        points = object.data;
      } else {
        points = [object.center];
      }
      for (let j = 0; j < points.length; j++) {
        const point = points[j];
        if (point.x < minX) {
          minX = point.x;
        }
        if (point.y < minY) {
          minY = point.y;
        }
        if (point.x > maxX) {
          maxX = point.x;
        }
        if (point.y > maxY) {
          maxY = point.y;
        }
      }
    }
    // p1 ----- p2
    // |        |
    // p4 ----- p3
    this.selectedRect = {
      data: [],
      center: { x: 0, y: 0 },
      drawCorner: true,
      drawRotator: true,
    };
    this.selectedRect.data[0] = { x: minX - margin, y: minY - margin };
    this.selectedRect.data[1] = { x: maxX + margin, y: minY - margin };
    this.selectedRect.data[2] = { x: maxX + margin, y: maxY + margin };
    this.selectedRect.data[3] = { x: minX - margin, y: maxY + margin };
    this.selectedRect.center = {
      x: (minX + maxX) / 2,
      y: (minY + maxY) / 2,
    };
    if (this.selectedObjectsList.length > 1) {
      this.painter.updateEntireSelectedRect(this.selectedRect);
    } else {
      this.painter.updateEntireSelectedRect({
        data: [],
        center: { x: 0, y: 0 },
        drawCorner: false,
        drawRotator: false,
      });
    }
  }

  select(id) {
    this.setSelectedObjectsList([id]);
    this.selectedObjectsList = [id];
    this.updateEntireSelectedRect();
  }

  selectAdd(id) {
    if (this.selectedObjectsList.includes(id)) {
      this.selectedObjectsList = this.selectedObjectsList.filter(
        (objectID) => objectID !== id
      );
      this.setSelectedObjectsList(this.selectedObjectsList);
    } else {
      this.selectedObjectsList = [...this.selectedObjectsList, id];
      this.setSelectedObjectsList(this.selectedObjectsList);
    }
    this.updateEntireSelectedRect();
  }

  selectDragging(mouseDownPos, mouseCurrentPos) {
    const minX = Math.min(mouseDownPos.x, mouseCurrentPos.x);
    const minY = Math.min(mouseDownPos.y, mouseCurrentPos.y);
    const maxX = Math.max(mouseDownPos.x, mouseCurrentPos.x);
    const maxY = Math.max(mouseDownPos.y, mouseCurrentPos.y);

    for (let i = 0; i < this.activeObjects.length; i++) {
      const object = this.activeObjects[i];
      const center = object.center;
      if (
        center.x > minX &&
        center.x < maxX &&
        center.y > minY &&
        center.y < maxY
      ) {
        if (!this.selectedObjectsList.includes(object.id)) {
          this.selectedObjectsList = [...this.selectedObjectsList, object.id];
          this.setSelectedObjectsList(this.selectedObjectsList);
        }
      } else {
        if (this.selectedObjectsList.includes(object.id)) {
          this.selectedObjectsList = this.selectedObjectsList.filter(
            (objectID) => objectID !== object.id
          );
          this.setSelectedObjectsList(this.selectedObjectsList);
        }
      }
    }
    this.updateEntireSelectedRect();
  }

  selectAll() {
    this.selectedObjectsList = this.activeObjects.map((object) => object.id);
    this.setSelectedObjectsList(this.selectedObjectsList);
    this.updateEntireSelectedRect();
  }

  resetSelected() {
    this.selectedObjectsList = [];
    this.setSelectedObjectsList([]);

    this.selectedRect = {
      data: [],
      center: { x: 0, y: 0 },
      drawCorner: false,
      drawRotator: false,
    };
    this.painter.updateEntireSelectedRect(this.selectedRect);
  }

  translateSelectedObjects(dx, dy) {
    for (let i = 0; i < this.selectedObjectsList.length; i++) {
      const object = this.objectsDict[this.selectedObjectsList[i]];
      object.translate(dx, dy);
      this.setObjectsDict({ type: "UPDATE", payload: object });

      if (object.type === OBJECT_TYPES.VERTEX) {
        const edges = Object.values(this.objectsDict).filter(
          (object) => object.type === OBJECT_TYPES.EDGE
        );
        const connectedEdges = edges.filter((edge) =>
          edge.vertices.includes(object.id)
        );
        for (let i = 0; i < connectedEdges.length; i++) {
          const edge = connectedEdges[i];
          edge.update(
            this.objectsDict,
            this.mapMetadata.resolution,
            this.canvasInfo.scale
          );
          this.setObjectsDict({ type: "UPDATE", payload: edge });
        }
      }
    }
    this.updateEntireSelectedRect();
  }
}
