// import "./App.css";
import ToolButton from "./ToolButton";
import styles from "styles/ToolBar.module.css";
import { useEffect, useRef } from "react";

import SaveFlyout from "./flyout/SaveFlyout";
import PencilFlyout from "./flyout/PencilFlyout";
import RectangleFlyout from "./flyout/RectangleFlyout";
import TopologyFlyout from "./flyout/TopologyFlyout";
import RackFlyout from "./flyout/RackFlyout";
import PoiFlyout from "./flyout/PoiFlyout";
import LandmarkFlyout from "./flyout/LandmarkFlyout";
import RobotFlyout from "./flyout/RobotFlyout";

import { UI_TOOL_NAMES, TOOL_NAMES_TO_OBJECT_CATEGORY_MAP } from "common/TYPES";
import { useDOMContext } from "context/DOMContext";
import { useObjectContext } from "context/ObjectContext";
import { useToolContext } from "context/ToolContext";
function ToolBar() {
  const { toolBarRef } = useDOMContext();
  const { setCurrentTool } = useToolContext();
  const { setActiveObjectCategory } = useObjectContext();
  // set Tool as cursor when esc key is pressed
  useEffect(() => {
    function handleKeyDown(event) {}
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <div className="ToolBarContainer" ref={toolBarRef}>
      <ToolButton
        buttonName={UI_TOOL_NAMES.SAVE}
        icon="/assets/icons/save.svg"
        haveFlyout={true}
        flyout={<SaveFlyout />}
      />
      <ToolButton
        buttonName={UI_TOOL_NAMES.CURSOR}
        icon="/assets/icons/cursor.svg"
        haveFlyout={false}
        flyout={<></>}
      />
      <ToolButton
        buttonName={UI_TOOL_NAMES.RULER}
        icon="/assets/icons/ruler.svg"
        haveFlyout={false}
        flyout={<></>}
      />
      <ToolButton
        buttonName={UI_TOOL_NAMES.RECTANGLE}
        icon="/assets/icons/rectangle.svg"
        haveFlyout={false}
        flyout={<></>}
      />
      <ToolButton
        buttonName={UI_TOOL_NAMES.PENCIL}
        icon="/assets/icons/pencil.svg"
        haveFlyout={false}
        flyout={<></>}
      />
      <ToolButton
        buttonName={UI_TOOL_NAMES.TOPOLOGY}
        icon="/assets/icons/topology.svg"
        haveFlyout={false}
        flyout={<></>}
      />
      <ToolButton
        buttonName={UI_TOOL_NAMES.POI}
        icon="/assets/icons/poi.svg"
        haveFlyout={true}
        flyout={<PoiFlyout />}
      />
      <ToolButton
        buttonName={UI_TOOL_NAMES.RACK}
        icon="/assets/icons/rack.svg"
        haveFlyout={true}
        flyout={<RackFlyout />}
      />
      {/* <ToolButton
        buttonName={UI_TOOL_NAMES.STOP}
        icon="/assets/icons/stop.svg"
        haveFlyout={false}
        flyout={<></>}
      />
      <ToolButton
        buttonName={UI_TOOL_NAMES.LANDMARK}
        icon="/assets/icons/landmark.svg"
        haveFlyout={true}
        flyout={<LandmarkFlyout />}
      />
      <ToolButton
        buttonName={UI_TOOL_NAMES.ROBOT}
        icon="/assets/icons/robot.svg"
        haveFlyout={true}
        flyout={<RobotFlyout />}
      /> */}
      <div className={styles.helpButton}>
        <a
          href="https://www.notion.so/890b8adbe5594149839f4dc9bf0d250c"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className={styles.helpButton}
            src="/assets/icons/help.svg"
            alt="help"
          />
        </a>
      </div>
    </div>
  );
}

export default ToolBar;
