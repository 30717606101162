export const UI_TOOL_NAMES = {
  SAVE: "save",
  CURSOR: "cursor",
  RULER: "ruler",
  RECTANGLE: "rectangle",
  PENCIL: "pencil",
  TOPOLOGY: "topology",
  RACK: "rack",
  STOP: "stop",
  POI: "poi",
  LANDMARK: "landmark",
  ROBOT: "robot",
};

export const OBJECT_CATEGORY = {
  MAP: "map",
  AREA: "area",
  LOCATION: "location",
  POI: "poi",
  RACK: "rack",
  STOP: "stop",
  CELL: "cell",

  TOPOLOGY: "topology",
};

export const OBJECT_TYPES = {
  RECTANGLE: "rectangle",
  DRAWING: "drawing",
  LINE: "line",
  EDGE: "edge",
  VERTEX: "vertex",
  RACK: "rack",
  STOP: "stop",
  CELL: "cell",
  POI: "poi",
  ROBOT: "robot",
  LANDMARK: "landmark",
  RULER: "ruler",
  TRAJECTORY: "trajectory",
};

export const OBJECT_TYPES_TO_CATEGORY_MAP = {
  [OBJECT_TYPES.RECTANGLE]: OBJECT_CATEGORY.AREA,
  [OBJECT_TYPES.DRAWING]: OBJECT_CATEGORY.AREA,
  [OBJECT_TYPES.LINE]: OBJECT_CATEGORY.AREA,
  [OBJECT_TYPES.EDGE]: OBJECT_CATEGORY.TOPOLOGY,
  [OBJECT_TYPES.VERTEX]: OBJECT_CATEGORY.TOPOLOGY,
  [OBJECT_TYPES.RACK]: OBJECT_CATEGORY.RACK,
  [OBJECT_TYPES.STOP]: OBJECT_CATEGORY.STOP,
  [OBJECT_TYPES.CELL]: OBJECT_CATEGORY.CELL,
  [OBJECT_TYPES.POI]: OBJECT_CATEGORY.POI,
  [OBJECT_TYPES.ROBOT]: OBJECT_CATEGORY.MAP,
  [OBJECT_TYPES.LANDMARK]: OBJECT_CATEGORY.MAP,
  [OBJECT_TYPES.RULER]: OBJECT_CATEGORY.MAP,
  [OBJECT_TYPES.TRAJECTORY]: OBJECT_CATEGORY.MAP,
};

export const TOOL_NAMES_TO_OBJECT_CATEGORY_MAP = {
  [UI_TOOL_NAMES.CURSOR]: OBJECT_CATEGORY.DEFAULT,
  [UI_TOOL_NAMES.RECTANGLE]: OBJECT_CATEGORY.AREA,
  [UI_TOOL_NAMES.PENCIL]: OBJECT_CATEGORY.AREA,
  [UI_TOOL_NAMES.TOPOLOGY]: OBJECT_CATEGORY.TOPOLOGY,
  [UI_TOOL_NAMES.RACK]: OBJECT_CATEGORY.RACK,
  [UI_TOOL_NAMES.STOP]: OBJECT_CATEGORY.STOP,
  [UI_TOOL_NAMES.POI]: OBJECT_CATEGORY.POI,
  [UI_TOOL_NAMES.LANDMARK]: OBJECT_CATEGORY.MAP,
  [UI_TOOL_NAMES.ROBOT]: OBJECT_CATEGORY.MAP,
  [UI_TOOL_NAMES.RULER]: OBJECT_CATEGORY.MAP,
};

export const TOPOLOGY_TYPE = {
  VERTEX: [{ value: "default" }, { value: "intersection" }],
  EDGE: [{ value: "rack" }, { value: "freespace" }, { value: "crosswalk" }],
};

export const POI_TYPE = [
  "WORKSTATION",
  "WAITING-ZONE",
  "LOADING-ZONE",
  "UNLOADING-ZONE",
];

export const RACK_TYPES = ["스피드랙", "팔레트랙"];
