import { UI_TOOL_NAMES } from "common/TYPES";

export class ZoomPan {
  constructor() {
    this.zoomStep = 0.5; // Zoom step
    this.maxScale = 50; // Max zoom
    this.minScale = 0.2; // Min zoom

    this.panStep = 10; // Pan step

    this.imageOrigin = null;
    this.initialOrigin = null;

    this.prevMousePos = null;
    this.isPanning = false;
  }

  init(origin, setImageOrigin, canvas, setIsPanning) {
    this.imageOrigin = { ...origin };
    this.initialOrigin = { ...origin };
    this.setImageOrigin = setImageOrigin;
    this.canvas = canvas;
    this.setIsPanning = setIsPanning;
  }

  updateImageOrigin(origin) {
    this.imageOrigin = { ...origin };
  }

  setTool(currentTool) {
    this.currentTool = currentTool;
    if (this.currentTool === UI_TOOL_NAMES.CURSOR) {
      this.isSpacePanActivated = true;
    } else {
      this.isSpacePanActivated = false;
    }
  }

  calculateScaleFactor(deltaY) {
    let newScale = this.imageOrigin.scale;
    if (deltaY < 0) {
      // 확대
      newScale = Math.min(
        this.imageOrigin.scale + this.zoomStep,
        this.maxScale
      );
    } else {
      // 축소
      newScale = Math.max(
        this.imageOrigin.scale - this.zoomStep,
        this.minScale
      );
    }
    this.imageOrigin.scale = newScale;
  }

  zoom(deltaY, offsetX, offsetY) {
    if (this.imageOrigin.scale > this.maxScale / 10) {
      this.zoomStep = 1.0;
    } else {
      this.zoomStep = 0.2;
    }
    const prevScale = this.imageOrigin.scale;
    this.calculateScaleFactor(deltaY);

    const px_n0 = offsetX - this.imageOrigin.x;
    const py_n0 = offsetY - this.imageOrigin.y;

    // Get the current mouse cursor position relative to the top-left corner of the image after zoom
    const px_n1 = px_n0 * (this.imageOrigin.scale / prevScale);
    const py_n1 = py_n0 * (this.imageOrigin.scale / prevScale);

    // Get the difference between the previous and current mouse cursor position
    const dx = px_n1 - px_n0;
    const dy = py_n1 - py_n0;

    // Adjust the dx and dy values to keep the zoom centered
    this.imageOrigin.x -= Math.round(dx);
    this.imageOrigin.y -= Math.round(dy);
    this.setImageOrigin({ ...this.imageOrigin });
  }

  panX(deltaY) {
    if (deltaY > 0) {
      this.imageOrigin.x += Math.round(this.panStep * this.imageOrigin.scale);
    } else {
      this.imageOrigin.x -= Math.round(this.panStep * this.imageOrigin.scale);
    }
    this.setImageOrigin({ ...this.imageOrigin });
  }

  panY(deltaY) {
    if (deltaY < 0) {
      this.imageOrigin.y += Math.round(this.panStep * this.imageOrigin.scale);
    } else {
      this.imageOrigin.y -= Math.round(this.panStep * this.imageOrigin.scale);
    }
    this.setImageOrigin({ ...this.imageOrigin });
  }

  panXY(mouseCurrentPos) {
    const dx = mouseCurrentPos.x - this.prevMousePos.x;
    const dy = mouseCurrentPos.y - this.prevMousePos.y;

    this.prevMousePos = mouseCurrentPos;
    this.imageOrigin = {
      ...this.imageOrigin,
      x: this.imageOrigin.x + dx,
      y: this.imageOrigin.y + dy,
    };
    this.setImageOrigin(this.imageOrigin);
  }

  reset(origin = { x: 0, y: 0, scale: 1.0 }) {
    if (
      origin.x === this.imageOrigin.x &&
      origin.y === this.imageOrigin.y &&
      origin.scale === this.imageOrigin.scale
    )
      return;
    this.imageOrigin = { ...origin };
    this.setImageOrigin(this.imageOrigin);
  }

  listenMouseAction(mouseEvent) {
    const button = mouseEvent.event.button;
    const deltaY = mouseEvent.event.deltaY; // scroll down : positive, scroll up : negative
    const x = mouseEvent.event.offsetX;
    const y = mouseEvent.event.offsetY;
    const isShiftPressed = mouseEvent.event.shiftKey;
    const isCtrlPressed = mouseEvent.event.ctrlKey;
    const isAltPressed = mouseEvent.event.altKey;
    const isMetaPressed = mouseEvent.event.metaKey;
    switch (mouseEvent.action) {
      case "mousedown":
        if (button === 0) {
          this.isMouseLeftDown = true;
        } else if (button === 1) {
          this.isMouseMiddleDown = true;
        } else if (button === 2) {
          this.isMouseRightDown = true;
        }

        this.mouseDownPos = { x, y };
        this.mouseCurrentPos = { x, y };

        if (
          (this.isSpacePressed &&
            this.isMouseLeftDown &&
            this.isSpacePanActivated) ||
          this.isMouseMiddleDown
        ) {
          //! pan-xy-start
          this.isPanning = true;
          this.setIsPanning(true);
          this.prevMousePos = { x, y };
          this.canvas.style.cursor = "grabbing";
        }
        break;
      case "mousemove":
        this.mouseCurrentPos = { x, y };
        if (
          this.isSpacePressed &&
          !this.isMouseLeftDown &&
          this.isSpacePanActivated
        ) {
          //! space-pressed
          this.canvas.style.cursor = "grab";
        } else if (this.isPanning) {
          //! pan-xy
          this.panXY(this.mouseCurrentPos);
          this.canvas.style.cursor = "grabbing";
        } else {
          this.canvas.style.cursor = "default";
        }
        break;

      case "mouseup":
        this.isMouseLeftDown = false;
        this.isMouseMiddleDown = false;
        this.isMouseRightDown = false;
        this.mouseUpPos = { x, y };
        this.mouseCurrentPos = { x, y };

        if (this.isPanning) {
          this.isPanning = false;
          this.setIsPanning(false);
          this.canvas.style.cursor = "default";
        }
        break;
      case "wheel":
        if (!this.isPanning && !this.isMouseLeftDown && !this.isSpacePressed) {
          if (!isCtrlPressed) {
            if (isShiftPressed) {
              //! pan-x
              this.panX(deltaY);
            } else {
              //!pan-y
              this.panY(deltaY);
            }
          } else {
            //!zoom
            this.zoom(deltaY, x, y);
          }
        }
        break;
      case "mouseleave":
        this.isMouseLeftDown = false;
        this.isMouseMiddleDown = false;
        this.isMouseRightDown = false;
        this.isPanning = false;

        this.setIsPanning(false);
        this.canvas.style.cursor = "default";
        break;
      default:
        break;
    }
  }

  listenKeyboardAction(keyboardEvent) {
    const event = keyboardEvent.event;
    const action = keyboardEvent.action;
    const key = event.key;
    const isShiftPressed = event.shiftKey;
    const isCtrlPressed = event.ctrlKey;
    const isAlt = event.altKey;
    const isMeta = event.metaKey;

    switch (action) {
      case "keydown":
        switch (key) {
          //! space-pressed
          case " ":
            this.isSpacePressed = true;
            if (!this.isPanning && this.isSpacePanActivated) {
              this.canvas.style.cursor = "grab";
            }

            break;
          //zoom-pan-reset
          case "0":
            if (isCtrlPressed) {
              this.reset(this.initialOrigin);
            }
            break;

          default:
            break;
        }
        break;

      case "keyup":
        switch (key) {
          // space-released
          case " ":
            this.isSpacePressed = false;
            this.canvas.style.cursor = "default";
            break;
          default:
            break;
        }
        break;
      //none
      default:
        break;
    }
  }
}
