import { useEffect, useState } from "react";
import styles from "styles/LeftPanel.module.css";
import { useObjectContext } from "context/ObjectContext";
import { useDOMContext } from "context/DOMContext";
import { useToolContext } from "context/ToolContext";
import { useDataContext } from "context/DataContext";
import { OBJECT_TYPES, UI_TOOL_NAMES } from "common/TYPES";
import { getActiveCategoryColor } from "features/utils";

function TopologyObjectList({ searchTerm }) {
  const {
    objectsDict,
    setObjectsDict,
    selectedObjectsList,
    setSelectedObjectsList,
    invisibleObjectsList,
    setInvisibleObjectsList,
    activeObjectCategory,
    setActiveObjectCategory,
    setHoveredObject,
  } = useObjectContext();

  const { currentTool, zoomPanRef } = useToolContext();

  const { imageOrigin, setImageOrigin, canvasInfo, mapMetadata } =
    useDataContext();
  let count = {};
  function focus(object) {
    const center = object.center;
    const canvasCenter = {
      x: canvasInfo.canvasWidth / 2,
      y: canvasInfo.canvasHeight / 2,
    };
    const transformedX = center.x * imageOrigin.scale + imageOrigin.x;
    const transformedY = center.y * imageOrigin.scale + imageOrigin.y;
    const dx = canvasCenter.x - transformedX;
    const dy = canvasCenter.y - transformedY;
    const newImageOrigin = { ...imageOrigin };
    newImageOrigin.x += dx;
    newImageOrigin.y += dy;
    setImageOrigin(newImageOrigin);
    zoomPanRef.current.updateImageOrigin(newImageOrigin);
  }

  useEffect(() => {
    Object.keys(objectsDict).filter((key) => {
      // searchTerm이 있을 때
      if (searchTerm !== "") {
        if (key.toLowerCase().includes(searchTerm.toLowerCase())) {
          return true;
        }
        return false;
      }
      return true;
    });
  }, [searchTerm]);
  return (
    <div className={styles.ObjectListContainer}>
      <div className={styles.ObjectList}>
        <span>Vertex</span>
        {Object.keys(objectsDict).map((key) => {
          const type = objectsDict[key]["type"];
          if (
            objectsDict[key]["type"] === OBJECT_TYPES.VERTEX &&
            (searchTerm === "" ||
              key.toLowerCase().includes(searchTerm.toLowerCase()))
          ) {
            count[type] = count[type] ? count[type] + 1 : 1;
            const isInvisible = invisibleObjectsList.includes(key);

            return (
              <div
                key={key}
                className={styles.ObjectListItem}
                style={{
                  border: selectedObjectsList.includes(key)
                    ? "1px solid skyblue"
                    : "1px solid transparent",
                  backgroundColor: selectedObjectsList.includes(key)
                    ? "rgba(135, 206, 235, 0.2)"
                    : "transparent",
                }}
                onMouseOver={(e) => {
                  if (currentTool === UI_TOOL_NAMES.CURSOR) {
                    if (!selectedObjectsList.includes(key)) {
                      e.currentTarget.style.border = "1px solid skyblue";
                      e.currentTarget.style.cursor = "pointer";
                      setHoveredObject([key]);
                    }
                  } else {
                    e.currentTarget.style.cursor = "default";
                    e.currentTarget.style.border = "1px solid transparent";
                    setHoveredObject([]);
                  }
                }}
                onMouseOut={(e) => {
                  if (selectedObjectsList.includes(key)) {
                    e.currentTarget.style.border = "1px solid skyblue";
                  } else {
                    e.currentTarget.style.border = "1px solid transparent";
                    setHoveredObject([]);
                  }
                }}
                onClick={(e) => {
                  if (
                    e.button !== 0 ||
                    e.target.tagName === "IMG" ||
                    currentTool !== UI_TOOL_NAMES.CURSOR
                  )
                    return;
                  // single select
                  document.getSelection().removeAllRanges();
                  e.preventDefault();
                  if (
                    !selectedObjectsList.includes(key) &&
                    !e.ctrlKey &&
                    !e.shiftKey
                  ) {
                    setSelectedObjectsList([key]);
                    // 선택된 오브젝트가 캔버스 중앙에 오도록
                    if (objectsDict[key] && objectsDict[key].center) {
                      const selectedObject = objectsDict[key];
                      focus(selectedObject);
                    }
                  } else {
                    if (selectedObjectsList.length > 0) {
                      setSelectedObjectsList([]);
                    }
                  }

                  // ctrl select
                  if (e.ctrlKey) {
                    if (selectedObjectsList.includes(key)) {
                      setSelectedObjectsList(
                        selectedObjectsList.filter((item) => item !== key)
                      );
                    } else {
                      setSelectedObjectsList([...selectedObjectsList, key]);
                    }
                  } else {
                    setSelectedObjectsList([key]);
                  }

                  // shift select
                  if (e.shiftKey) {
                    const selectedObjects = [...selectedObjectsList];
                    const lastSelectedObject =
                      selectedObjects[selectedObjects.length - 1];
                    const lastSelectedObjectIndex =
                      Object.keys(objectsDict).indexOf(lastSelectedObject);
                    const currentObjectIndex =
                      Object.keys(objectsDict).indexOf(key);
                    const selectedObjectsBetween = Object.keys(
                      objectsDict
                    ).slice(
                      Math.min(lastSelectedObjectIndex, currentObjectIndex),
                      Math.max(lastSelectedObjectIndex, currentObjectIndex) + 1
                    );
                    setSelectedObjectsList(selectedObjectsBetween);
                  }
                  // }
                }}
              >
                {invisibleObjectsList.includes(key) ? (
                  <span style={{ opacity: 0.5, color: "grey" }}>{key}</span>
                ) : (
                  <span style={{ color: "black" }}>{key}</span>
                )}
                {isInvisible ? (
                  <div className={styles.visibleOptionButtonContainer}>
                    <img
                      key={key}
                      className={styles.visibleOptionButton}
                      src="/assets/icons/eye-closed.svg"
                      alt="invisible"
                      onClick={(e) => {
                        // if (currentTool === UI_TOOL_NAMES.CURSOR) {
                        if (selectedObjectsList.includes(key)) {
                          setSelectedObjectsList(
                            selectedObjectsList.filter((item) => item !== key)
                          );
                        }
                        setInvisibleObjectsList(
                          invisibleObjectsList.filter((item) => item !== key)
                        );
                        // }
                      }}
                    />
                  </div>
                ) : (
                  <div className={styles.visibleOptionButtonContainer}>
                    <img
                      key={key}
                      className={styles.visibleOptionButton}
                      src="/assets/icons/eye-open.svg"
                      alt="visible"
                      onClick={(e) => {
                        // if (currentTool === UI_TOOL_NAMES.CURSOR) {
                        if (selectedObjectsList.includes(key)) {
                          setSelectedObjectsList(
                            selectedObjectsList.filter((item) => item !== key)
                          );
                        }
                        setInvisibleObjectsList([...invisibleObjectsList, key]);
                        // }
                      }}
                    />
                  </div>
                )}
              </div>
            );
          } else {
            return null;
          }
        })}
        <span>Edge</span>

        {Object.keys(objectsDict).map((key) => {
          const type = objectsDict[key]["type"];
          if (
            objectsDict[key]["type"] === OBJECT_TYPES.EDGE &&
            (searchTerm === "" ||
              key.toLowerCase().includes(searchTerm.toLowerCase()))
          ) {
            count[type] = count[type] ? count[type] + 1 : 1;
            const isInvisible = invisibleObjectsList.includes(key);

            return (
              <div
                key={key}
                className={styles.ObjectListItem}
                style={{
                  border: selectedObjectsList.includes(key)
                    ? "1px solid skyblue"
                    : "1px solid transparent",
                  backgroundColor: selectedObjectsList.includes(key)
                    ? "rgba(135, 206, 235, 0.2)"
                    : "transparent",
                }}
                onMouseOver={(e) => {
                  if (currentTool === UI_TOOL_NAMES.CURSOR) {
                    if (!selectedObjectsList.includes(key)) {
                      e.currentTarget.style.border = "1px solid skyblue";
                      e.currentTarget.style.cursor = "pointer";
                      setHoveredObject([key]);
                    }
                  } else {
                    e.currentTarget.style.cursor = "default";
                    e.currentTarget.style.border = "1px solid transparent";
                    setHoveredObject([]);
                  }
                }}
                onMouseOut={(e) => {
                  if (selectedObjectsList.includes(key)) {
                    e.currentTarget.style.border = "1px solid skyblue";
                  } else {
                    e.currentTarget.style.border = "1px solid transparent";
                    setHoveredObject([]);
                  }
                }}
                onClick={(e) => {
                  if (
                    e.button !== 0 ||
                    e.target.tagName === "IMG" ||
                    currentTool !== UI_TOOL_NAMES.CURSOR
                  )
                    return;
                  // if (currentTool === UI_TOOL_NAMES.CURSOR) {
                  // single select
                  document.getSelection().removeAllRanges();
                  e.preventDefault();
                  if (
                    !selectedObjectsList.includes(key) &&
                    !e.ctrlKey &&
                    !e.shiftKey
                  ) {
                    setSelectedObjectsList([key]);
                    // 선택된 오브젝트가 캔버스 중앙에 오도록
                    if (objectsDict[key] && objectsDict[key].center) {
                      const selectedObject = objectsDict[key];
                      focus(selectedObject);
                    }
                  } else {
                    if (selectedObjectsList.length > 0) {
                      setSelectedObjectsList([]);
                    }
                  }

                  // ctrl select
                  if (e.ctrlKey) {
                    if (selectedObjectsList.includes(key)) {
                      setSelectedObjectsList(
                        selectedObjectsList.filter((item) => item !== key)
                      );
                    } else {
                      setSelectedObjectsList([...selectedObjectsList, key]);
                    }
                  } else {
                    setSelectedObjectsList([key]);
                  }

                  // shift select
                  if (e.shiftKey) {
                    const selectedObjects = [...selectedObjectsList];
                    const lastSelectedObject =
                      selectedObjects[selectedObjects.length - 1];
                    const lastSelectedObjectIndex =
                      Object.keys(objectsDict).indexOf(lastSelectedObject);
                    const currentObjectIndex =
                      Object.keys(objectsDict).indexOf(key);
                    const selectedObjectsBetween = Object.keys(
                      objectsDict
                    ).slice(
                      Math.min(lastSelectedObjectIndex, currentObjectIndex),
                      Math.max(lastSelectedObjectIndex, currentObjectIndex) + 1
                    );
                    setSelectedObjectsList(selectedObjectsBetween);
                  }
                  // }
                }}
              >
                {invisibleObjectsList.includes(key) ? (
                  <span style={{ opacity: 0.5, color: "grey" }}>{key}</span>
                ) : (
                  <span style={{ color: "black" }}>{key}</span>
                )}
                {isInvisible ? (
                  <div className={styles.visibleOptionButtonContainer}>
                    <img
                      key={key}
                      className={styles.visibleOptionButton}
                      src="/assets/icons/eye-closed.svg"
                      alt="invisible"
                      onClick={(e) => {
                        // if (currentTool === UI_TOOL_NAMES.CURSOR) {
                        if (selectedObjectsList.includes(key)) {
                          setSelectedObjectsList(
                            selectedObjectsList.filter((item) => item !== key)
                          );
                        }
                        setInvisibleObjectsList(
                          invisibleObjectsList.filter((item) => item !== key)
                        );
                        // }
                      }}
                    />
                  </div>
                ) : (
                  <div className={styles.visibleOptionButtonContainer}>
                    <img
                      key={key}
                      className={styles.visibleOptionButton}
                      src="/assets/icons/eye-open.svg"
                      alt="visible"
                      onClick={(e) => {
                        // if (currentTool === UI_TOOL_NAMES.CURSOR) {
                        if (selectedObjectsList.includes(key)) {
                          setSelectedObjectsList(
                            selectedObjectsList.filter((item) => item !== key)
                          );
                        }
                        setInvisibleObjectsList([...invisibleObjectsList, key]);
                        // }
                      }}
                    />
                  </div>
                )}
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
}

export default TopologyObjectList;
