import flyoutStyles from "styles/Flyout.module.css";
import { OBJECT_TYPES } from "common/TYPES";
import { useObjectContext } from "context/ObjectContext";
import { useToolContext } from "context/ToolContext";

function PoiSettingModal({ style, taskManagerRef }) {
  const { isPoiSettingModalEnabled, setIsPoiSettingModalEnabled } =
    useToolContext();
  const { objectsDict, setObjectsDict } = useObjectContext();

  const handleDelete = () => {
    const object = isPoiSettingModalEnabled.object;
    const prevObjectsDict = { ...objectsDict };
    // delete vertex and edge that are connected to the vertex
    taskManagerRef.current.addHistory([object], "DELETE");
    setObjectsDict({
      type: "DELETE",
      payload: prevObjectsDict[object.id],
    });

    setIsPoiSettingModalEnabled({ enabled: false, object: null });
  };
  return (
    <div className={flyoutStyles.flyoutContents} style={style}>
      <div className={flyoutStyles.flyoutItem}>
        <div className={flyoutStyles.flyoutSection}>
          <button onClick={handleDelete}>삭제</button>
        </div>
      </div>
    </div>
  );
}

export default PoiSettingModal;
