import styles from "styles/CanvasPanel.module.css";

import Canvas from "./Canvas";
import Loading from "components/view/Loading";
import VertexSettingModal from "components/modal/VertexSettingModal";
import EdgeSettingModal from "components/modal/EdgeSettingModal";
import PoiSettingModal from "components/modal/PoiSettingModal";
import SaveHandler from "./SaveHandler";

import { ROBOT_STUDIO_API } from "common/API";
import { useDataContext } from "context/DataContext";
import { useDOMContext } from "context/DOMContext";
import { useToolContext } from "context/ToolContext";
import { useObjectContext } from "context/ObjectContext";

import { useFetchData } from "hooks/useFetchData";
import { useFetchImage } from "hooks/useFetchImage";

import { useState, useEffect, useRef } from "react";

const mapMetaDataUrl = ROBOT_STUDIO_API.GET_MAP_METADATA;
const navMapUrl = ROBOT_STUDIO_API.GET_NAVIGATION_MAP_IMAGE;
const localizationMapUrl = ROBOT_STUDIO_API.GET_LOCALIZATION_MAP_IMAGE;
const topologyDataUrl = ROBOT_STUDIO_API.GET_TOPOLOGY_DATA;
const areaDataUrl = ROBOT_STUDIO_API.GET_AREA_DATA;
const poiDataUrl = ROBOT_STUDIO_API.GET_POI_DATA;
const rackTypesDataUrl = ROBOT_STUDIO_API.GET_RACK_TYPES_DATA;
const locationCodePatternsDataUrl =
  ROBOT_STUDIO_API.GET_LOCATION_CODE_PATTERNS_DATA;
const rackDataUrl = ROBOT_STUDIO_API.GET_RACK_DATA;

function CanvasPanel() {
  const [loading, setLoading] = useState(true);

  const {
    navMapImage,
    setNavMapImage,
    localizationMapImage,
    setLocalizationMapImage,
    mapMetadata,
    setMapMetadata,
    topologyData,
    setTopologyData,
    areaData,
    setAreaData,
    poiData,
    setPoiData,
    rackData,
    setRackData,
    rackTypesData,
    setRackTypesData,
    locationCodePatternsData,
    setLocationCodePatternsData,
    warehouseName,
    canvasInfo,
  } = useDataContext();

  const { canvasContainerRef } = useDOMContext();

  const {
    setIsSaveCalled,

    isVertexSettingModalEnabled,
    isEdgeSettingModalEnabled,
    isPoiSettingModalEnabled,
    setIsUploadModalEnabled,
    taskManagerRef,
  } = useToolContext();

  const { setObjectsDict, setSelectedObjectsList } = useObjectContext();

  // fetch data

  const { data: navMap } = useFetchImage(navMapUrl);
  const { data: localizationMap } = useFetchImage(localizationMapUrl);
  const { data: mapMetadataResponse } = useFetchData(mapMetaDataUrl);
  const { data: topologyDataResponse } = useFetchData(
    topologyDataUrl + `?branch_name=${warehouseName}`
  );
  const { data: areaDataResponse } = useFetchData(
    areaDataUrl + `?branch_name=${warehouseName}`
  );

  const { data: poiDataResponse } = useFetchData(
    poiDataUrl + `?branch_name=${warehouseName}`
  );

  const { data: rackTypesDataResponse } = useFetchData(rackTypesDataUrl);
  const { data: locationCodePatternsDataResponse } = useFetchData(
    locationCodePatternsDataUrl
  );
  const { data: rackDataResponse } = useFetchData(
    rackDataUrl + `?branch_name=${warehouseName}`
  );

  useEffect(() => {
    if (rackTypesDataResponse !== null) {
      const keys = Object.keys(rackTypesDataResponse);
      for (let i = 0; i < keys.length; i++) {
        if (!Array.isArray(rackTypesDataResponse[keys[i]].num_cell)) {
          let numCells = [];
          for (
            let j = 0;
            j < rackTypesDataResponse[keys[i]].level_heights.length;
            j++
          ) {
            numCells.push(rackTypesDataResponse[keys[i]].num_cell);
          }
          rackTypesDataResponse[keys[i]].num_cell = numCells;
        }
      }

      setRackTypesData(rackTypesDataResponse);
    }
    // key 확인
    if (locationCodePatternsDataResponse !== null) {
      const keys = Object.keys(locationCodePatternsDataResponse);
      for (let i = 0; i < keys.length; i++) {
        if (
          locationCodePatternsDataResponse[keys[i]].cell_scheme === undefined
        ) {
          locationCodePatternsDataResponse[keys[i]].cell_scheme = "normal";
        }
      }
      setLocationCodePatternsData(locationCodePatternsDataResponse);
    }
    setNavMapImage(navMap);
    setLocalizationMapImage(localizationMap);
    setMapMetadata(mapMetadataResponse);
    setTopologyData(topologyDataResponse);
    setAreaData(areaDataResponse);
    setPoiData(poiDataResponse);
    setRackData(rackDataResponse);
  }, [
    navMap,
    localizationMap,
    mapMetadataResponse,
    topologyDataResponse,
    areaDataResponse,
    poiDataResponse,
    rackDataResponse,
    rackTypesDataResponse,
    locationCodePatternsDataResponse,
    warehouseName,
    setNavMapImage,
    setLocalizationMapImage,
    setMapMetadata,
    setTopologyData,
    setAreaData,
    setPoiData,
    setRackData,
    setRackTypesData,
    setLocationCodePatternsData,
  ]);

  useEffect(() => {
    //check pending and set loading
    if (
      mapMetadataResponse &&
      navMap &&
      localizationMap &&
      topologyData &&
      areaData &&
      poiData &&
      rackTypesData &&
      rackData &&
      locationCodePatternsData
    ) {
      setLoading(false);
    }
  }, [
    mapMetadataResponse,
    navMap,
    localizationMap,
    topologyData,
    areaData,
    poiData,
    rackData,
    rackTypesData,
    locationCodePatternsData,
  ]);
  // 클릭한 위치를 상태로 관리합니다.
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });

  // canvas를 클릭할 때 호출될 함수
  const handleClick = (event) => {
    // canvas 요소의 좌표를 계산합니다.
    const canvasRect = event.target.getBoundingClientRect();
    const clickX = event.clientX - canvasRect.left;
    const clickY = event.clientY - canvasRect.top;

    // 클릭한 위치를 상태로 설정합니다.
    setClickPosition({ x: clickX, y: clickY });
    // 메뉴 모달을 표시합니다.
  };

  taskManagerRef.current.init(
    setObjectsDict,
    setSelectedObjectsList,
    setIsSaveCalled,
    setIsUploadModalEnabled,
    mapMetadata,
    canvasInfo
  );

  useEffect(() => {
    taskManagerRef.current.canvasInfo = canvasInfo;
    taskManagerRef.current.mapMetadata = mapMetadata;
  }, [canvasInfo, mapMetadata]);

  return (
    <div
      className="CanvasPanelContainer"
      ref={canvasContainerRef}
      onContextMenu={handleClick}
    >
      {loading ? (
        <Loading loadingInfo="맵 에디터 로딩중..." />
      ) : (
        <>
          <Canvas taskManagerRef={taskManagerRef} />
          <SaveHandler />
        </>
      )}
      {isVertexSettingModalEnabled.enabled && (
        <div>
          <VertexSettingModal
            style={{
              position: "absolute",
              top: clickPosition.y,
              left: clickPosition.x,
            }}
            taskManagerRef={taskManagerRef}
          />
        </div>
      )}
      {isEdgeSettingModalEnabled.enabled && (
        <div>
          <EdgeSettingModal
            style={{
              position: "absolute",
              top: clickPosition.y,
              left: clickPosition.x,
            }}
            taskManagerRef={taskManagerRef}
          />
        </div>
      )}
      {isPoiSettingModalEnabled.enabled && (
        <PoiSettingModal
          style={{
            position: "absolute",
            top: clickPosition.y,
            left: clickPosition.x,
          }}
          taskManagerRef={taskManagerRef}
        />
      )}
    </div>
  );
}

export default CanvasPanel;
