import { useEffect } from "react";

const useKeyboardEventHandlers = (setKeyboardEvent) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey) {
        event.preventDefault();
      }
      switch (event.key) {
        case "F1":
        case "F2":
        case "F3":
        case "F4":
        case "F6":
        case "F7":
        case "F8":
        case "F9":
        case "F10":
        case "F11":
        case "Tab":
          event.preventDefault();
          break;
        default:
          break;
      }

      setKeyboardEvent({
        action: "keydown",
        event: event,
      });
    };

    const handleKeyUp = (event) => {
      setKeyboardEvent({
        action: "keyup",
        event: event,
      });
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [setKeyboardEvent]);
};

export default useKeyboardEventHandlers;
