import {
  OBJECT_CATEGORY,
  TOOL_NAMES_TO_OBJECT_CATEGORY_MAP,
} from "common/TYPES";
function checkPointOnEdgeCenter(point, edge, vertexSize, resolution) {
  if (!edge) return false;
  const arrowSize = vertexSize / resolution;
  const distance = Math.sqrt(
    (point.x - edge.center.x) ** 2 + (point.y - edge.center.y) ** 2
  );
  if (distance <= arrowSize / 4) {
    return true;
  }
  return false;
}

function checkPointOnLine(point, line) {
  if (!line) return false;
  const points = line.data;
  for (let i = 0; i < points.length - 1; i++) {
    const p1 = points[i];
    const p2 = points[i + 1];
    const distance = Math.sqrt((point.x - p1.x) ** 2 + (point.y - p1.y) ** 2);
    const distance2 = Math.sqrt((point.x - p2.x) ** 2 + (point.y - p2.y) ** 2);
    const lineLength = Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
    if (distance + distance2 <= lineLength + 1) {
      return true;
    }
  }
  return false;
}

function checkPointOnDrawing(point, drawing) {
  if (!drawing) return false;
  const points = drawing.data;
  const minX = Math.min(...points.map((point) => point.x));
  const maxX = Math.max(...points.map((point) => point.x));
  const minY = Math.min(...points.map((point) => point.y));
  const maxY = Math.max(...points.map((point) => point.y));
  if (
    point.x >= minX &&
    point.x <= maxX &&
    point.y >= minY &&
    point.y <= maxY
  ) {
    return true;
  } else {
    return false;
  }
}

function checkPointOnVertex(point, vertex, resolution) {
  if (!vertex) return false;
  const pixelVertexSize = vertex.size / resolution;
  const distance = Math.sqrt(
    (point.x - vertex.center.x) ** 2 + (point.y - vertex.center.y) ** 2
  );
  if (distance <= pixelVertexSize) {
    return true;
  }
  return false;
}

function checkPointOnPoi(point, poi, resolution) {
  if (!poi) return false;
  const pixelPoiSize = poi.size / resolution;
  const distance = Math.sqrt(
    (point.x - poi.center.x) ** 2 + (point.y - poi.center.y) ** 2
  );
  if (distance <= pixelPoiSize) {
    return true;
  }
  return false;
}

function checkPointInRectangle(point, rectangle, scale, margin = -10) {
  if (!rectangle) return false;
  // Helper function to calculate the cross product of two vectors
  const corners = rectangle.data;
  const center = rectangle.center;
  const scaledPoint = {
    x: point.x * scale,
    y: point.y * scale,
  };
  const scaledCorners = corners.map((corner) => {
    return {
      x: corner.x * scale,
      y: corner.y * scale,
    };
  });
  const scaledCenter = {
    x: center.x * scale,
    y: center.y * scale,
  };
  const paddedCorners = scaledCorners.map((corner) => {
    const dx = corner.x - scaledCenter.x;
    const dy = corner.y - scaledCenter.y;
    const distance = Math.sqrt(dx ** 2 + dy ** 2);
    const angle = Math.atan2(dy, dx);
    const paddedX =
      scaledCenter.x + (distance + margin * scale) * Math.cos(angle);
    const paddedY =
      scaledCenter.y + (distance + margin * scale) * Math.sin(angle);
    return { x: paddedX, y: paddedY };
  });

  const x = scaledPoint.x;
  const y = scaledPoint.y;

  const isInside = paddedCorners
    .map((corner, index) => {
      const nextCorner = paddedCorners[(index + 1) % paddedCorners.length];
      const isLeft =
        (nextCorner.x - corner.x) * (y - corner.y) -
        (x - corner.x) * (nextCorner.y - corner.y);
      return isLeft >= 0;
    })
    .every((isLeft) => isLeft);
  return isInside;
}

function checkPointInRack(point, rack, scale) {
  if (!rack) return false;
  const corners = rack.data;
  const scaledPoint = {
    x: point.x * scale,
    y: point.y * scale,
  };
  const scaledCorners = corners.map((corner) => {
    return {
      x: corner.x * scale,
      y: corner.y * scale,
    };
  });

  const x = scaledPoint.x;
  const y = scaledPoint.y;
  const isInside = scaledCorners
    .map((corner, index) => {
      const nextCorner = scaledCorners[(index + 1) % scaledCorners.length];
      const isLeft =
        (nextCorner.x - corner.x) * (y - corner.y) -
        (x - corner.x) * (nextCorner.y - corner.y);
      return isLeft < 0;
    })
    .every((isLeft) => isLeft);
  return isInside;
}

function checkPointOnCorner(point, rectangle, scale, margin = 10) {
  if (!rectangle) return false;

  const x = point.x * scale;
  const y = point.y * scale;
  const corners = rectangle.data;
  for (let i = 0; i < corners.length; i++) {
    const corner = corners[i];
    const distance = Math.sqrt(
      (x - corner.x * scale) ** 2 + (y - corner.y * scale) ** 2
    );
    if (distance <= margin) {
      return true;
    }
  }

  return false;
}

function checkPointNearCorner(point, rectangle, scale, margin = 20) {
  if (!rectangle) return false;
  const scaledPoint = {
    x: point.x * scale,
    y: point.y * scale,
  };
  const scaledCorners = rectangle.data.map((corner) => {
    return {
      x: corner.x * scale,
      y: corner.y * scale,
    };
  });
  const scaledCenter = {
    x: rectangle.center.x * scale,
    y: rectangle.center.y * scale,
  };

  for (let i = 0; i < scaledCorners.length; i++) {
    const p = scaledCorners[i];
    const centeredX = p.x - scaledCenter.x;
    const centeredY = p.y - scaledCenter.y;
    const yaw = Math.atan2(centeredY, centeredX);
    let resizedX, resizedY;
    resizedX = p.x + 60 * Math.cos(yaw);
    resizedY = p.y + 60 * Math.sin(yaw);
    const distance = Math.sqrt(
      (scaledPoint.x - resizedX) ** 2 + (scaledPoint.y - resizedY) ** 2
    );

    if (distance <= margin) {
      return true;
    }
  }
  return false;
}

function transformPointToOriginalImageCoord(point, imageOrigin) {
  let x = Math.round((point.x - imageOrigin.x) / imageOrigin.scale);
  let y = Math.round((point.y - imageOrigin.y) / imageOrigin.scale);

  return {
    x: x,
    y: y,
  };
}

function getToolColor(currentTool, opacity = 0.2) {
  if (!currentTool) return;
  return getActiveCategoryColor(TOOL_NAMES_TO_OBJECT_CATEGORY_MAP[currentTool]);
}
function getActiveCategoryColor(type, opacity = 0.2) {
  switch (type) {
    case OBJECT_CATEGORY.MAP:
    case OBJECT_CATEGORY.DEFAULT:
      return `rgba(185,226,245, ${opacity})`;
    case OBJECT_CATEGORY.AREA:
      return `rgba(119,106,119, ${opacity})`;
    case OBJECT_CATEGORY.TOPOLOGY:
      return `rgba(182,195,162, ${opacity})`;
    case OBJECT_CATEGORY.POI:
      return `rgba(255, 120, 10, ${opacity})`;
    case OBJECT_CATEGORY.RACK:
      return `rgba(250, 190, 50, ${opacity})`;
    case OBJECT_CATEGORY.STOP:
      return `rgba(244, 154, 194, ${opacity})`;
    default:
      return `rgba(255,255,255, ${opacity})`;
  }
}

function getVertexTypeColor(type, opacity = 0.2) {
  switch (type) {
    case "default":
      return `rgba(155,207,83, ${opacity})`; //9BCF53
    case "intersection":
      // return `rgba(255,255,126, ${opacity})`; //FFF67E
      return `rgba(199,71,116, ${opacity})`; //FFF67E

    default:
      return `rgba(255,255,255, ${opacity})`;
  }
}

function getEdgeTypeColor(type, opacity = 0.2) {
  switch (type) {
    case "rack":
      return `rgba(232,117,26, ${opacity})`; // 7BD3EA
    case "freespace":
      return `rgba(199,71,116, ${opacity})`; // F6D6D6
    case "crosswalk":
      return `rgba(75,0,140, ${opacity})`;
    case "disabled":
      return `rgba(100,100,100, ${opacity})`;
    default:
      return `rgba(200,100,100, ${opacity})`;
  }
}
function hexToRgbA(hex, opacity = 0.2) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      `,${opacity})`
    );
  }
  throw new Error("Bad Hex");
}
function getPoiTypeColor(type, opacity = 0.2) {
  switch (type) {
    // case "WORKSTATION":
    //   return `rgba(155,207,83, ${opacity})`; //9BCF53
    // case "WAITING-ZONE":
    //   return `rgba(255,255,126, ${opacity})`; //FFF67E
    // case "LOADING-ZONE":
    //   return `rgba(232,117,26, ${opacity})`; // 7BD3EA
    // case "UNLOADING-ZONE":
    //   return `rgba(198,91,207, ${opacity})`; // F6D6D6
    default:
      // hash the type to get a color with opacity
      let hash = 0;
      for (let i = 0; i < type.length; i++) {
        hash = type.charCodeAt(i) + ((hash << 5) - hash);
      }
      const c = (hash & 0x00ffffff).toString(16).toUpperCase();
      const rgba = hexToRgbA(
        `#${"00000".substring(0, 6 - c.length) + c}`,
        opacity
      );

      // to rgba
      return rgba;
  }
}

function copyObject(obj) {
  const newObj = JSON.parse(JSON.stringify(obj));
  Object.setPrototypeOf(newObj, Object.getPrototypeOf(obj));
  return newObj;
}

function getLocationCode(codeConfig, locationPattern) {
  const stringPattern = locationPattern.string_pattern;
  const digitPattern = locationPattern.digit_pattern;
  let locationCode = "";
  for (let i = 0; i < stringPattern.length; i++) {
    const code = stringPattern[i];
    if (codeConfig[code] !== undefined && codeConfig[code] !== "-") {
      locationCode += String(codeConfig[code]).padStart(
        parseInt(digitPattern[code]),
        "0"
      );
    } else if (code === "-") {
      locationCode += "-";
    }
  }
  return locationCode;
}

export {
  checkPointOnEdgeCenter,
  checkPointOnVertex,
  checkPointOnPoi,
  checkPointInRectangle,
  checkPointOnDrawing,
  checkPointOnLine,
  checkPointOnCorner,
  checkPointNearCorner,
  transformPointToOriginalImageCoord,
  getToolColor,
  getActiveCategoryColor,
  getVertexTypeColor,
  getEdgeTypeColor,
  getPoiTypeColor,
  copyObject,
  getLocationCode,
  checkPointInRack,
};
