import EditorHeader from "components/view/EditorHeader";
import ToolBar from "components/toolBar/ToolBar";
import LeftPanel from "components/leftPanel/LeftPanel";
import RightPanel from "components/rightPanel/RightPanel";
import CanvasPanel from "components/canvasPanel/CanvasPanel";
import Loading from "components/view/Loading";
import { ROBOT_STUDIO_API } from "common/API";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDataContext } from "context/DataContext";
import { useObjectContext } from "context/ObjectContext";
import { useToolContext } from "context/ToolContext";
import UploadModal from "components/modal/UploadModal";
import RackTypeSettingModal from "components/modal/RackTypeSettingModal";
import LocationCodePatternSettingModal from "components/modal/LocationCodePatternSettingModal";

function Editor() {
  const { warehouseName, setWarehouseName } = useDataContext();

  const { env_name } = useParams();
  const [loading, setLoading] = useState(true);
  const [isRemoteUpdated, setIsRemoteUpdated] = useState(false);
  const {
    isUploadModalEnabled,
    isRackTypeSettingModalEnabled,
    isLocationCodePatternSettingModalEnabled,
  } = useToolContext();

  async function checkoutBranch(warehouseName) {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ branch_name: warehouseName }),
      };

      const response = await fetch(
        ROBOT_STUDIO_API.POST_SELECT_WAREHOUSE,
        requestOptions
      );
      const data = await response.json();
      setIsRemoteUpdated(data.message);
      setLoading(false);

      // console.log(data);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function getBranchName() {
    try {
      const response = await fetch(ROBOT_STUDIO_API.GET_WAREHOUSE_NAME);
      const data = await response.json();
      setWarehouseName(data.branch_name);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    // checkoutBranch(env_name);
    // setWarehouseName(env_name);
    getBranchName();
  }, []);
  useEffect(() => {
    if (isRemoteUpdated) {
      alert("원격 저장소에 변경사항이 있습니다.");
    }
  }, [isRemoteUpdated]);

  return (
    <>
      {loading ? (
        <Loading loadingInfo="창고 정보 로딩중..."></Loading>
      ) : (
        <div className="Editor">
          <EditorHeader title={warehouseName}></EditorHeader>
          <ToolBar></ToolBar>
          <div className="MainPanel">
            <LeftPanel></LeftPanel>
            <CanvasPanel></CanvasPanel>
            <RightPanel></RightPanel>
          </div>
          {isUploadModalEnabled && <UploadModal />}
          {isRackTypeSettingModalEnabled && <RackTypeSettingModal />}
          {isLocationCodePatternSettingModalEnabled && (
            <LocationCodePatternSettingModal />
          )}
        </div>
      )}
    </>
  );
}

export default Editor;
