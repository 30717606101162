// import "./App.css";
import { useEffect, useState, useRef } from "react";
import { useObjectContext } from "context/ObjectContext";
import { useToolContext } from "context/ToolContext";
import { UI_TOOL_NAMES, OBJECT_TYPES } from "common/TYPES";

import styles from "styles/RightPanel.module.css";
import { useDataContext } from "context/DataContext";
import { getLocationCode } from "features/utils";

function RackPanel() {
  const {
    objectsDict,
    setObjectsDict,
    selectedObjectsList,
    setSelectedObjectsList,
    activeObjectCategory,
    setActiveObjectCategory,
  } = useObjectContext();
  const { locationCodePatternsData, rackTypesData } = useDataContext();
  const { rackToolConfig, setRackToolConfig, currentTool, taskManagerRef } =
    useToolContext();
  const [rackObjects, setRackObjects] = useState([]);

  const [zoneId, setZoneId] = useState(null);
  const [aisleId, setAisleId] = useState(null);
  const [rackId, setRackId] = useState(null);
  const [pickingSide, setPickingSide] = useState(null);
  const [rackName, setRackName] = useState(null);
  const [rackTypeName, setRackTypeName] = useState(null);
  const [locationCodePatternName, setLocationCodePatternName] = useState(null);
  const [onlySelectedRack, setOnlySelectedRack] = useState(null);

  const [stopOffset, setStopOffset] = useState({ x: 0, y: 0, yaw: 0 });
  const pickingSides = ["left", "right"];
  useEffect(() => {
    // prevent drag or double click html selection
    document.addEventListener("dragstart", (e) => e.preventDefault());
    document.addEventListener("selectstart", (e) => e.preventDefault());
  }, []);

  const handleSave = () => {
    let updatedRacks = [];
    for (let i = 0; i < selectedObjectsList.length; i++) {
      const rack = objectsDict[selectedObjectsList[i]];
      updatedRacks.push(rack);
    }
    taskManagerRef.current.addHistory(updatedRacks, "UPDATE");

    for (let i = 0; i < selectedObjectsList.length; i++) {
      const rack = objectsDict[selectedObjectsList[i]];
      if (zoneId !== "Mixed") {
        rack.zoneId = zoneId;
      }
      if (aisleId !== "Mixed") {
        rack.aisleId = aisleId;
      }
      if (selectedObjectsList.length === 1) {
        rack.rackId = rackId;
      }
      if (pickingSide !== "Mixed") {
        rack.pickingSide = pickingSide;
      }
      if (rackName !== "Mixed") {
        rack.rackName = rackName;
      }
      if (rackTypeName !== "Mixed") {
        rack.setRackType(rackTypeName, rackTypesData[rackTypeName]);
      } else {
        rack.setRackType(rack.rackTypeName, rackTypesData[rack.rackTypeName]);
      }
      if (locationCodePatternName !== "Mixed") {
        rack.setLocationCodePattern(
          locationCodePatternName,
          locationCodePatternsData[locationCodePatternName]
        );
      } else {
        rack.setLocationCodePattern(
          rack.locationCodePatternName,
          locationCodePatternsData[rack.locationCodePatternName]
        );
      }

      rack.setRackPoints();
      rack.configure();

      setObjectsDict({
        type: "UPDATE",
        payload: rack,
      });
    }
  };

  useEffect(() => {
    if (selectedObjectsList.length === 1) {
      setOnlySelectedRack(objectsDict[selectedObjectsList[0]]);
    } else {
      setOnlySelectedRack(null);
    }
    if (selectedObjectsList.length > 0) {
      const firstRack = objectsDict[selectedObjectsList[0]];
      setZoneId(firstRack.zoneId);
      setAisleId(firstRack.aisleId);
      setRackId(firstRack.rackId);
      setPickingSide(firstRack.pickingSide);
      setRackName(firstRack.rackName);
      setRackTypeName(firstRack.rackTypeName);
      setLocationCodePatternName(firstRack.locationCodePatternName);
      setStopOffset(firstRack.defaultStopOffset);
      if (selectedObjectsList.length > 1) {
        for (let i = 1; i < selectedObjectsList.length; i++) {
          const rack = objectsDict[selectedObjectsList[i]];
          if (rack.zoneId !== firstRack.zoneId) {
            setZoneId("Mixed");
          }
          if (rack.aisleId !== firstRack.aisleId) {
            setAisleId("Mixed");
          }
          if (rack.rackId !== firstRack.rackId) {
            setRackId("Mixed");
          }
          if (rack.pickingSide !== firstRack.pickingSide) {
            setPickingSide("Mixed");
          }
          if (rack.rackName !== firstRack.rackName) {
            setRackName("Mixed");
          }
          if (rack.rackTypeName !== firstRack.rackTypeName) {
            setRackTypeName("Mixed");
          }
          if (
            rack.locationCodePatternName !== firstRack.locationCodePatternName
          ) {
            setLocationCodePatternName("Mixed");
          }
          if (rack.defaultStopOffset !== firstRack.defaultStopOffset) {
            setStopOffset({
              x: "Mixed",
              y: "Mixed",
              yaw: "Mixed",
            });
          }
        }
      }
    } else {
      setZoneId(null);
      setAisleId(null);
      setRackId(null);
      setPickingSide(null);
      setRackName(null);
      setRackTypeName(null);
      setLocationCodePatternName(null);
      setStopOffset({ x: 0, y: 0, yaw: 0 });
    }
  }, [selectedObjectsList, objectsDict]);

  useEffect(() => {
    // rack object만 정렬
    const rackObjects = Object.values(objectsDict).filter(
      (object) => object.type === OBJECT_TYPES.RACK
    );
    setRackObjects(rackObjects);
  }, [objectsDict]);

  useEffect(() => {
    if (rackTypesData || locationCodePatternsData) {
      setRackToolConfig({
        ...rackToolConfig,
        rackType: Object.keys(rackTypesData)[0],
        locationCodePattern: Object.keys(locationCodePatternsData)[0],
      });
    }
  }, []);

  return (
    <div className={styles.panelContainer}>
      {currentTool === UI_TOOL_NAMES.RACK &&
        selectedObjectsList.length === 0 && (
          <>
            <div className={styles.container}>
              <div className={styles.title}>툴 설정</div>
              <div className={styles.settingItemContainer}>
                <div className={styles.settingItemTitle}>랙 타입</div>
                <div className={styles.settingItemContent}>
                  {rackTypesData && (
                    <select
                      className={styles.settingInput}
                      onChange={(e) => {
                        setRackToolConfig({
                          ...rackToolConfig,
                          rackType: e.target.value,
                        });
                      }}
                      value={rackToolConfig.rackTypeName}
                    >
                      {Object.keys(rackTypesData).map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>

              <div className={styles.settingItemContainer}>
                <div className={styles.settingItemTitle}>
                  로케이션 코드 패턴
                </div>
                <div className={styles.settingItemContent}>
                  {locationCodePatternsData && (
                    <select
                      className={styles.settingInput}
                      onChange={(e) => {
                        setRackToolConfig({
                          ...rackToolConfig,
                          locationCodePattern: e.target.value,
                        });
                      }}
                      value={rackToolConfig.locationCodePattern}
                    >
                      {Object.keys(locationCodePatternsData).map((pattern) => (
                        <option key={pattern} value={pattern}>
                          {pattern}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>

              <div className={styles.settingItemContainer}>
                <div className={styles.settingItemTitle}>랙 넘버링 step</div>
                <div className={styles.settingItemContent}>
                  <input
                    type="number"
                    className={styles.settingInput}
                    placeholder="숫자만 입력"
                    onChange={(e) => {
                      if (
                        isNaN(e.target.value) ||
                        e.target.value === "Mixed" ||
                        e.target.value === "-"
                      ) {
                        return;
                      } else {
                        setRackToolConfig({
                          ...rackToolConfig,
                          rackNumberingStep: parseInt(e.target.value),
                        });
                      }
                    }}
                    value={rackToolConfig.rackNumberingStep}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      {selectedObjectsList.length > 0 && (
        <>
          <div className={styles.container}>
            <div className={styles.title}>랙 속성</div>
            <div className={styles.configItem}>
              <div className={styles.configTitle}>Zone ID</div>
              <input
                className={styles.configInput}
                type="text"
                autoComplete="off"
                id="zoneId"
                value={selectedObjectsList.length > 0 ? zoneId : "-"}
                placeholder={selectedObjectsList.length > 0 ? "입력" : "-"}
                disabled={selectedObjectsList.length > 0 ? false : true}
                onChange={(e) => {
                  e.preventDefault();
                  setZoneId(e.target.value);
                }}
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("zoneId").focus();
                }}
                onKeyDown={(e) => {
                  // tab 누르면 다음 input으로 이동
                  if ((e.key === "Tab" && !e.shiftKey) || e.key === "Enter") {
                    e.preventDefault();
                    document.getElementById("aisleId").focus();
                  }
                }}
              ></input>
            </div>
            <div className={styles.configItem}>
              <div className={styles.configTitle}>Aisle ID</div>
              <input
                className={styles.configInput}
                type="number"
                id="aisleId"
                value={selectedObjectsList.length > 0 ? aisleId : "-"}
                placeholder={selectedObjectsList.length > 0 ? "입력" : "-"}
                disabled={selectedObjectsList.length > 0 ? false : true}
                onChange={(e) => {
                  if (
                    isNaN(e.target.value) ||
                    e.target.value === "Mixed" ||
                    e.target.value === "-"
                  ) {
                    return;
                  } else {
                    setAisleId(parseInt(e.target.value));
                  }
                }}
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("aisleId").focus();
                }}
                onKeyDown={(e) => {
                  // tab 누르면 다음 input으로 이동
                  if ((e.key === "Tab" && !e.shiftKey) || e.key === "Enter") {
                    e.preventDefault();
                    document.getElementById("rackId").focus();
                  } else if (e.key === "Tab" && e.shiftKey) {
                    e.preventDefault();
                    document.getElementById("zoneId").focus();
                  }
                }}
              ></input>
            </div>
            <div className={styles.configItem}>
              <div className={styles.configTitle}>Rack ID</div>
              <input
                className={styles.configInput}
                type="number"
                id="rackId"
                value={selectedObjectsList.length === 1 ? rackId : "Mixed"}
                placeholder={
                  selectedObjectsList.length === 1
                    ? "-"
                    : selectedObjectsList.length !== 0
                    ? "Mixed"
                    : "-"
                }
                disabled={selectedObjectsList.length === 1 ? false : true}
                onChange={(e) => {
                  if (
                    isNaN(e.target.value) ||
                    e.target.value === "Mixed" ||
                    e.target.value === "-"
                  ) {
                    return;
                  } else {
                    setRackId(parseInt(e.target.value));
                  }
                }}
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("rackId").focus();
                }}
                onKeyDown={(e) => {
                  // tab 누르면 다음 input으로 이동
                  if ((e.key === "Tab" && !e.shiftKey) || e.key === "Enter") {
                    e.preventDefault();
                    document.getElementById("rackId").focus();
                  } else if (e.key === "Tab" && e.shiftKey) {
                    e.preventDefault();
                    document.getElementById("aisleId").focus();
                  }
                }}
              ></input>
            </div>
            <div className={styles.configItem}>
              <div className={styles.configTitle}>피킹 방향</div>
              {pickingSides.map((side) => (
                <div key={side} className={styles.radioInputContainer}>
                  <input
                    type="radio"
                    className={styles.radioInput}
                    id={side}
                    name="pickingSide"
                    value={side}
                    onChange={() => setPickingSide(side)}
                    checked={side === pickingSide}
                    disabled={selectedObjectsList.length > 0 ? false : true}
                  />
                  <label htmlFor={side} className={styles.radioInputLabel}>
                    {side}
                  </label>
                </div>
              ))}
            </div>
            <div className={styles.configItem}>
              <div className={styles.configTitle}>랙 타입</div>
              <div className={styles.configSelectInputContainer}>
                {rackTypesData && (
                  <select
                    className={styles.selectInput}
                    onChange={(e) => {
                      setRackTypeName(e.target.value);
                    }}
                    value={rackTypeName}
                  >
                    {rackTypeName === "Mixed" ? (
                      <option key="Mixed" value="Mixed">
                        Mixed
                      </option>
                    ) : (
                      ""
                    )}
                    {Object.keys(rackTypesData).map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
            <div className={styles.configItem}>
              <div className={styles.configTitle}>코드 패턴</div>
              <div className={styles.configSelectInputContainer}>
                {locationCodePatternsData && (
                  <select
                    className={styles.selectInput}
                    onChange={(e) => {
                      setLocationCodePatternName(e.target.value);
                    }}
                    value={locationCodePatternName}
                  >
                    {locationCodePatternName === "Mixed" ? (
                      <option key="Mixed" value="Mixed">
                        Mixed
                      </option>
                    ) : (
                      ""
                    )}
                    {Object.keys(locationCodePatternsData).map((pattern) => (
                      <option key={pattern} value={pattern}>
                        {pattern}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          </div>
          <div className={styles.saveBtnContainer}>
            <button
              className={styles.saveBtn}
              onClick={() => {
                handleSave();
              }}
            >
              저장
            </button>
          </div>
          {onlySelectedRack && onlySelectedRack.isRackConfigured && (
            <>
              <div className={styles.rackPreviewTitle}>
                <span>로케이션코드</span>
              </div>

              <div className={styles.rackPreviewContainer}>
                {[...onlySelectedRack.rackType.num_cell]
                  .reverse()
                  .map((cols, rowIndex) => (
                    <div key={rowIndex} className={styles.row}>
                      {Array.from({ length: cols }).map((_, colIndex) => (
                        <div
                          key={`${rowIndex}-${colIndex}`}
                          className={styles.cell}
                        >
                          {
                            onlySelectedRack.cells[
                              (onlySelectedRack.rackType.num_cell.length -
                                1 -
                                rowIndex) *
                                cols +
                                colIndex
                            ].id
                          }
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default RackPanel;
