// import "./App.css";
import styles from "styles/LeftPanel.module.css";
import { useEffect, useState, useRef } from "react";

import { OBJECT_CATEGORY } from "common/TYPES";
import { getActiveCategoryColor } from "features/utils";

import { useObjectContext } from "context/ObjectContext";
import { useToolContext } from "context/ToolContext";
import MainCategory from "./MainCategory";
import AreaObjectList from "./AreaObjectList";
import TopologyObjectList from "./TopologyObjectList";
import PoiObjectList from "./PoiObjectList";
import RackObjectList from "./RackObjectList";
import MapObjectList from "./MapObjectList";

function LeftPanel() {
  const leftPanelContainerRef = useRef(null);
  const mainCategoryContainerRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const {
    objectsDict,
    setObjectsDict,
    selectedObjectsList,
    setSelectedObjectsList,
    activeObjectCategory,
    setActiveObjectCategory,
  } = useObjectContext();
  const { currentTool } = useToolContext();

  useEffect(() => {
    if (!currentTool) return;
    leftPanelContainerRef.current.style.backgroundColor =
      getActiveCategoryColor(activeObjectCategory, 0.2);
  }, [activeObjectCategory]);

  useEffect(() => {}, [objectsDict]);

  return (
    <div className="LeftPanelContainer" ref={leftPanelContainerRef}>
      <div className={styles.filterContainer}>
        <img
          className={styles.objectFilterIcon}
          src="/assets/icons/search.svg"
          alt="filter-object"
        />
        <input
          type="text"
          placeholder="Search object..."
          value={searchTerm}
          onChange={handleSearchChange}
          onClick={(e) => {
            e.stopPropagation();
            e.target.select();
          }}
          className={styles.input}
        />
      </div>
      <div
        className={styles.mainCategoryContainer}
        ref={mainCategoryContainerRef}
      >
        <MainCategory
          title="Map"
          type={OBJECT_CATEGORY.MAP}
          parentRef={mainCategoryContainerRef}
          searchTerm={searchTerm}
        />
        <MainCategory
          title="Area"
          type={OBJECT_CATEGORY.AREA}
          parentRef={mainCategoryContainerRef}
          searchTerm={searchTerm}
        />
        <MainCategory
          title="Topology"
          type={OBJECT_CATEGORY.TOPOLOGY}
          parentRef={mainCategoryContainerRef}
          searchTerm={searchTerm}
        />
        <MainCategory
          title="PoI"
          type={OBJECT_CATEGORY.POI}
          parentRef={mainCategoryContainerRef}
          searchTerm={searchTerm}
        />
        <MainCategory
          title="Rack"
          type={OBJECT_CATEGORY.RACK}
          parentRef={mainCategoryContainerRef}
          searchTerm={searchTerm}
        />
        {/* <MainCategory
          title="Stop"
          type={OBJECT_CATEGORY.STOP}
          parentRef={mainCategoryContainerRef}
          searchTerm={searchTerm}
        /> */}
      </div>
      {activeObjectCategory === OBJECT_CATEGORY.AREA && (
        <AreaObjectList searchTerm={searchTerm} />
      )}
      {activeObjectCategory === OBJECT_CATEGORY.TOPOLOGY && (
        <TopologyObjectList searchTerm={searchTerm} />
      )}
      {activeObjectCategory === OBJECT_CATEGORY.POI && (
        <PoiObjectList searchTerm={searchTerm} />
      )}
      {activeObjectCategory === OBJECT_CATEGORY.RACK && (
        <RackObjectList searchTerm={searchTerm} />
      )}
      {activeObjectCategory === OBJECT_CATEGORY.MAP && (
        <MapObjectList searchTerm={searchTerm} />
      )}
    </div>
  );
}

export default LeftPanel;
