import React from "react";
import styles from "styles/UploadModal.module.css";
import { useToolContext } from "context/ToolContext";
import { useEffect, useRef } from "react";
import Snackbar from "components/canvasPanel/Snackbar";
const SnackbarType = {
  success: "success",
  fail: "fail",
};

function UploadModal() {
  const {
    setIsUploadModalEnabled,
    uploadDescription,
    setUploadDescription,
    setIsUploadCalled,
  } = useToolContext();
  const textAreaRef = useRef(null);
  const snackbarFailRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = async (event) => {
      if (event.ctrlKey || event.metaKey) {
        switch (event.key) {
          case "a":
            event.preventDefault();
            if (textAreaRef.current) {
              textAreaRef.current.select();
            }
            break;

          default:
            break;
        }
      }
    };

    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (textArea) {
        textArea.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.title}>
          <h1>변경 사항 업로드</h1>
        </div>
        <div className={styles.body}>
          <textarea
            className={styles.textInput}
            ref={textAreaRef}
            name="uploadDescription"
            placeholder={`변경 사항에 대한 설명을 입력하세요 \n(Commit Message)`}
            value={uploadDescription}
            onChange={(e) => {
              setUploadDescription(e.target.value);
            }}
          />
        </div>
        <div className={styles.footer}>
          <button
            onClick={() => {
              setIsUploadModalEnabled(false);
              setUploadDescription("");
            }}
            id={styles.cancelBtn}
          >
            취소
          </button>
          <button
            onClick={() => {
              if (uploadDescription === "") {
                snackbarFailRef.current.show(
                  "변경 사항에 대한 설명을 입력해주세요."
                );
                return;
              }
              setIsUploadCalled(true);
            }}
          >
            업로드
          </button>
        </div>
      </div>
      <Snackbar ref={snackbarFailRef} type={SnackbarType.fail} />
    </div>
  );
}

export default UploadModal;
