function objectReducer(state, action) {
  let prevState = { ...state };
  switch (action.type) {
    case "ADD":
      const obj = { [action.payload.id]: action.payload };
      // add to dict
      const newObj = { ...state, ...obj };
      return newObj;

    case "DELETE":
      delete prevState[action.payload.id];
      return prevState;

    case "UPDATE":
      // console.log(action.payload, prevState[action.payload.id]);
      prevState[action.payload.id] = action.payload;
      return prevState;

    default:
      return state;
  }
}

export { objectReducer };
