import { v4 as uuidv4 } from "uuid";
import { OBJECT_TYPES, OBJECT_TYPES_TO_CATEGORY_MAP } from "common/TYPES";

export class TaskManager {
  constructor() {
    this.selectedObjectsList = [];
    this.clipboard = [];

    this.objectsDict = {};
    this.undoStack = {};
    this.redoStack = {};
    this.activeObjectCategory = null;
  }

  init(
    setObjectsDict,
    setSelectedObjectsList,
    setIsSaveCalled,
    setIsUploadModalEnabled,
    mapMetadata,
    canvasInfo
  ) {
    this.setObjectsDict = setObjectsDict;
    this.setSelectedObjectsList = setSelectedObjectsList;
    this.setIsSaveCalled = setIsSaveCalled;
    this.setIsUploadModalEnabled = setIsUploadModalEnabled;
    this.mapMetadata = mapMetadata;
    this.canvasInfo = canvasInfo;
  }

  updateActiveObjectType(activeObjectCategory) {
    this.activeObjectCategory = activeObjectCategory;
  }

  updateObjectsDict(objectsDict) {
    this.objectsDict = objectsDict;
  }
  updateCanvasInfo(canvasInfo) {
    this.canvasInfo = canvasInfo;
  }

  updateSelectedObjectsList(selectedObjectsList) {
    this.selectedObjectsList = selectedObjectsList;
  }

  listenKeyboardAction(keyboardEvent) {
    const event = keyboardEvent.event;
    const action = keyboardEvent.action;
    const key = event.key;
    const isShiftPressed = event.shiftKey;
    const isCtrlPressed = event.ctrlKey;
    const isAlt = event.altKey;
    const isMeta = event.metaKey;

    switch (action) {
      case "keydown":
        switch (key) {
          case "z":
            if (isCtrlPressed) {
              this.undo();
            }
            break;
          case "Z":
            if (isCtrlPressed && isShiftPressed) {
              this.redo();
            }
            break;
          case "c":
            if (isCtrlPressed) {
              this.copy();
            }
            break;
          case "v":
            if (isCtrlPressed) {
              this.paste();
            }
            break;
          case "x":
            if (isCtrlPressed) {
              this.cut();
            }
            break;
          case "S":
          case "s":
            if (isCtrlPressed && !isShiftPressed) {
              event.preventDefault();

              console.log("save called");
              this.setIsSaveCalled(true);
            } else if (isCtrlPressed && isShiftPressed) {
              console.log("upload called");
              this.setIsUploadModalEnabled(true);
            }
            break;
          case "Delete":
            this.delete();
            break;

          default:
            break;
        }
        break;
      case "keyup":
        break;
      default:
        break;
    }
  }

  undo() {
    // undo
    if (!this.undoStack[this.activeObjectCategory]) return;
    if (this.undoStack[this.activeObjectCategory].length === 0) return;
    const lastAction = this.undoStack[this.activeObjectCategory].pop();
    const action = lastAction.action;
    const objects = lastAction.objects;
    if (action === "ADD") {
      let historyStack = [];
      for (let i = 0; i < objects.length; i++) {
        this.setObjectsDict({
          type: "DELETE",
          payload: objects[i],
        });
        historyStack.push(objects[i]);
      }
      this.redoStack[this.activeObjectCategory].push({
        action: "DELETE",
        objects: historyStack,
      });
      this.resetSelectedObjectsList();
    } else if (action === "DELETE") {
      let historyStack = [];
      for (let i = 0; i < objects.length; i++) {
        this.setObjectsDict({
          type: "ADD",
          payload: objects[i],
        });
        historyStack.push(objects[i]);
      }
      this.redoStack[this.activeObjectCategory].push({
        action: "ADD",
        objects: historyStack,
      });
      this.resetSelectedObjectsList();
    } else if (action === "UPDATE") {
      let historyStack = [];
      let updatedEdges = [];
      for (let i = 0; i < objects.length; i++) {
        const prevObject = this.objectsDict[objects[i].id];

        historyStack.push(prevObject);
        let object = objects[i];
        this.setObjectsDict({
          type: "UPDATE",
          payload: object,
        });
        this.objectsDict[object.id] = object;

        if (object.type === OBJECT_TYPES.VERTEX) {
          const edges = Object.values(this.objectsDict).filter(
            (object) => object.type === OBJECT_TYPES.EDGE
          );
          const connectedEdges = edges.filter((edge) =>
            edge.vertices.includes(object.id)
          );
          for (let i = 0; i < connectedEdges.length; i++) {
            const edge = connectedEdges[i];
            if (!updatedEdges.includes(edge)) {
              updatedEdges.push(edge);
            }
          }
        }
      }
      for (let i = 0; i < updatedEdges.length; i++) {
        const edge = updatedEdges[i];
        edge.update(
          this.objectsDict,
          this.mapMetadata.resolution,
          this.canvasInfo.scale
        );

        historyStack.push(edge);

        this.setObjectsDict({ type: "UPDATE", payload: edge });
      }
      this.redoStack[this.activeObjectCategory].push({
        action: "UPDATE",
        objects: historyStack,
      });
    }
  }
  redo() {
    // redo
    if (!this.redoStack[this.activeObjectCategory]) return;
    if (this.redoStack[this.activeObjectCategory].length === 0) return;
    const lastAction = this.redoStack[this.activeObjectCategory].pop();
    const action = lastAction.action;
    const objects = lastAction.objects;
    if (action === "ADD") {
      let historyStack = [];
      for (let i = 0; i < objects.length; i++) {
        this.setObjectsDict({
          type: "DELETE",
          payload: objects[i],
        });
        historyStack.push(objects[i]);
      }
      this.undoStack[this.activeObjectCategory].push({
        action: "DELETE",
        objects: historyStack,
      });
      this.resetSelectedObjectsList();
    } else if (action === "DELETE") {
      let historyStack = [];
      for (let i = 0; i < objects.length; i++) {
        this.setObjectsDict({
          type: "ADD",
          payload: objects[i],
        });
        historyStack.push(objects[i]);
      }
      this.undoStack[this.activeObjectCategory].push({
        action: "ADD",
        objects: historyStack,
      });
      this.resetSelectedObjectsList();
    } else if (action === "UPDATE") {
      let historyStack = [];
      let updatedEdges = [];
      for (let i = 0; i < objects.length; i++) {
        const prevObject = this.objectsDict[objects[i].id];
        historyStack.push(prevObject);
        let object = objects[i];
        this.setObjectsDict({
          type: "UPDATE",
          payload: object,
        });
        this.objectsDict[object.id] = object;
        if (object.type === OBJECT_TYPES.VERTEX) {
          const edges = Object.values(this.objectsDict).filter(
            (object) => object.type === OBJECT_TYPES.EDGE
          );
          const connectedEdges = edges.filter((edge) =>
            edge.vertices.includes(object.id)
          );
          for (let i = 0; i < connectedEdges.length; i++) {
            const edge = connectedEdges[i];
            if (!updatedEdges.includes(edge)) {
              updatedEdges.push(edge);
            }
          }
        }
      }
      for (let i = 0; i < updatedEdges.length; i++) {
        const edge = updatedEdges[i];
        edge.update(
          this.objectsDict,
          this.mapMetadata.resolution,
          this.canvasInfo.scale
        );

        historyStack.push(edge);

        this.setObjectsDict({ type: "UPDATE", payload: edge });
      }
      this.undoStack[this.activeObjectCategory].push({
        action: "UPDATE",
        objects: historyStack,
      });
    }
  }

  delete() {
    // delete
    let toDeleteObjects = [];
    for (let i = 0; i < this.selectedObjectsList.length; i++) {
      //  this.objectsDict[this.selectedObjectsList[i].id]
      const id = this.selectedObjectsList[i];
      const object = this.objectsDict[id];
      if (object.type === OBJECT_TYPES.VERTEX) {
        const edges = Object.values(this.objectsDict).filter(
          (object) => object.type === OBJECT_TYPES.EDGE
        );
        const connectedEdges = edges.filter((edge) =>
          edge.vertices.includes(object.id)
        );
        toDeleteObjects.push(object);
        connectedEdges.forEach((edge) => {
          toDeleteObjects.push(edge);
        });
      } else {
        toDeleteObjects.push(object);
      }
    }
    for (let i = 0; i < toDeleteObjects.length; i++) {
      this.setObjectsDict({
        type: "DELETE",
        payload: toDeleteObjects[i],
      });
    }
    this.addHistory(toDeleteObjects, "DELETE");
    this.resetSelectedObjectsList();
  }

  copy() {
    // copy
    this.clipboard = [];
    for (let i = 0; i < this.selectedObjectsList.length; i++) {
      const id = this.selectedObjectsList[i];
      const object = this.objectsDict[id];
      let newObject = JSON.parse(JSON.stringify(object));
      Object.setPrototypeOf(newObject, Object.getPrototypeOf(object));
      this.clipboard.push(newObject);
    }
  }

  paste() {
    // paste
    let historyStack = [];
    let newSelectedObjectsList = [];
    this.clipboard = this.clipboard.filter((object) => {
      return object !== null;
    });
    let nextClipboard = [];
    for (let i = 0; i < this.clipboard.length; i++) {
      let object = this.clipboard[i];
      if (object === null) continue;
      if (
        object.type === OBJECT_TYPES.RECTANGLE ||
        object.type === OBJECT_TYPES.DRAWING ||
        object.type === OBJECT_TYPES.LINE
      ) {
        let newObject = JSON.parse(JSON.stringify(object));
        Object.setPrototypeOf(newObject, Object.getPrototypeOf(object));
        newObject.id = uuidv4();
        newObject.translate(5, 5);
        this.setObjectsDict({
          type: "ADD",
          payload: newObject,
        });
        historyStack.push(newObject);
        newSelectedObjectsList.push(newObject.id);
        nextClipboard.push(newObject);
      } else if (object.type === OBJECT_TYPES.RACK) {
        if (object.isRackConfigured === false) {
          let newObject = JSON.parse(JSON.stringify(object));
          Object.setPrototypeOf(newObject, Object.getPrototypeOf(object));

          newObject.id = uuidv4();
          newObject.translate(5, 5);
          this.setObjectsDict({
            type: "ADD",
            payload: newObject,
          });
          historyStack.push(newObject);
          newSelectedObjectsList.push(newObject.id);
          nextClipboard.push(newObject);
        }
      }
    }
    this.addHistory(historyStack, "ADD");
    if (newSelectedObjectsList.length > 0) {
      this.setSelectedObjectsList(newSelectedObjectsList);
    }
    this.clipboard = nextClipboard;
  }

  cut() {
    // cut
  }

  addHistory(objects, action) {
    if (!objects) return;
    if (objects.length < 1) return;
    if (!objects[0]) return;
    const objectCategory = OBJECT_TYPES_TO_CATEGORY_MAP[objects[0].type];
    this.redoStack[objectCategory] = [];
    if (!this.undoStack[objectCategory]) {
      this.undoStack[objectCategory] = [];
    }
    let historyStack = [];
    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      let newObject = JSON.parse(JSON.stringify(object));
      Object.setPrototypeOf(newObject, Object.getPrototypeOf(object));
      historyStack.push(newObject);
    }
    this.undoStack[objectCategory].push({
      action: action,
      objects: historyStack,
    });
  }

  setSelectedToHistory(action) {
    Object.setPrototypeOf(this.objectsDict, this.objectsDict.__proto__);
    let historyStack = [];
    for (let i = 0; i < this.selectedObjectsList.length; i++) {
      const id = this.selectedObjectsList[i];
      // const object = this.objectsDict[id];
      const object = JSON.parse(JSON.stringify(this.objectsDict[id]));
      Object.setPrototypeOf(object, this.objectsDict[id].__proto__);

      historyStack.push(object);
    }
    this.addHistory(historyStack, action);
  }

  resetSelectedObjectsList() {
    if (this.selectedObjectsList.length > 0) {
      this.setSelectedObjectsList([]);
    }
  }
}
