import {
  OBJECT_TYPES,
  OBJECT_TYPES_TO_CATEGORY_MAP,
  UI_TOOL_NAMES,
} from "common/TYPES";
import {} from "common/TYPES";
import {
  transformPointToOriginalImageCoord,
  copyObject,
  checkPointOnVertex,
  checkPointOnEdgeCenter,
} from "features/utils";
import { Edge, Vertex } from "features/Object";

export class TopologyTool {
  constructor(setObjectsDict) {
    this.setObjectsDict = setObjectsDict;
    this.objectsDict = {};
    this.imageOrigin = {};
    this.currentObjects = [];
    this.vertexIdCount = 0;
    this.isGenerating = false;
    this.isGeneratingLine = false;

    this.movingObjects = [];
    this.isMovingObjects = false;
    this.isIdInitialized = false;
    this.isModalEnabled = false;

    this.prevVertex = null;
  }
  init(
    imageOrigin,
    mapMetadata,
    canvas,
    canvasInfo,
    taskManager,
    setIsVertexSettingModalEnabled,
    setIsEdgeSettingModalEnabled
  ) {
    this.imageOrigin = imageOrigin;
    this.mapMetadata = mapMetadata;
    this.canvas = canvas;
    this.canvasInfo = canvasInfo;
    this.canvasScale = canvasInfo.scale;
    this.taskManager = taskManager;
    this.setIsVertexSettingModalEnabled = setIsVertexSettingModalEnabled;

    this.setIsEdgeSettingModalEnabled = setIsEdgeSettingModalEnabled;
  }
  setId() {
    const vertices = Object.values(this.objectsDict).filter(
      (object) => object.type === OBJECT_TYPES.VERTEX
    );
    if (vertices.length === 0) {
      this.vertexIdCount = 0;
    } else {
      this.vertexIdCount =
        Math.max(
          ...vertices.map((vertex) => parseInt(vertex.id.split("_")[1]))
        ) + 1;
    }
  }

  resetCurrentObjects() {
    this.currentObjects = [];
    this.prevVertex = null;
    this.isGenerating = false;
    this.isGeneratingLine = false;

    this.setIsVertexSettingModalEnabled({
      enabled: false,
      object: null,
    });
    this.setIsEdgeSettingModalEnabled({
      enabled: false,
      object: null,
    });
  }

  updateModalEnabled(vertexModalEnabled, edgeModalEnabled) {
    this.isModalEnabled =
      vertexModalEnabled.enabled || edgeModalEnabled.enabled;
  }
  updateObjectsDict(objectsDict) {
    this.objectsDict = objectsDict;

    // filter topology
    const vertexObjects = Object.values(this.objectsDict).filter(
      (object) => object.type === OBJECT_TYPES.VERTEX
    );
    if (vertexObjects.length > 0 && !this.isIdInitialized) {
      this.setId();
      this.isIdInitialized = true;
    }
  }
  setTool(currentTool) {
    this.currentTool = currentTool;
    if (this.currentTool === UI_TOOL_NAMES.TOPOLOGY) {
      this.isActivated = true;
    } else {
      this.isActivated = false;
    }
  }
  updateImageOrigin(imageOrigin) {
    if (!imageOrigin) return;
    this.imageOrigin = imageOrigin;
  }
  updateCanvasInfo(canvasInfo) {
    this.canvasInfo = canvasInfo;
  }

  updateConfig(config) {
    this.config = config;
    for (let i = 0; i < this.currentObjects.length; i++) {
      if (this.currentObjects[i].type === OBJECT_TYPES.VERTEX) {
        this.currentObjects[i].setSize(this.config.vertexSize);
      }
    }
  }

  listenMouseAction(mouseEvent) {
    if (!this.isActivated) return;
    const button = mouseEvent.event.button;
    const x = mouseEvent.event.offsetX;
    const y = mouseEvent.event.offsetY;

    let closestVertex, isCursorOnVertex;
    let closestEdge, isCursorOnEdgeCenter;
    switch (mouseEvent.action) {
      case "mousedown":
        this.mouseDownPos = { x, y };
        const transformedMouseDownPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin
        );
        if (button === 0) {
          this.isMouseLeftDown = true;
        } else if (button === 1) {
          this.isMouseMiddleDown = true;
        } else if (button === 2) {
          this.isMouseRightDown = true;
        }

        closestVertex = this.getClosestVertex(transformedMouseDownPos);
        isCursorOnVertex = checkPointOnVertex(
          transformedMouseDownPos,
          closestVertex,
          this.mapMetadata.resolution
        );
        closestEdge = this.getClosestEdge(transformedMouseDownPos);
        isCursorOnEdgeCenter = checkPointOnEdgeCenter(
          transformedMouseDownPos,
          closestEdge,
          this.config.vertexSize,
          this.mapMetadata.resolution
        );
        // open vertex setting modal
        if (this.isMouseRightDown && isCursorOnVertex) {
          this.setIsVertexSettingModalEnabled({
            enabled: true,
            object: closestVertex,
          });
        } else {
          this.setIsVertexSettingModalEnabled({
            enabled: false,
            object: null,
          });
        }
        // open edge setting modal
        if (this.isMouseRightDown && isCursorOnEdgeCenter) {
          this.setIsEdgeSettingModalEnabled({
            enabled: true,
            object: closestEdge,
          });
        } else {
          this.setIsEdgeSettingModalEnabled({
            enabled: false,
            object: null,
          });
        }

        // generate new vertex if clicked on empty space
        if (
          this.isMouseLeftDown &&
          !this.isSpacePressed &&
          !isCursorOnVertex &&
          !this.isModalEnabled
        ) {
          const vertex = new Vertex();
          vertex.setID(this.vertexIdCount);
          vertex.setVertexType(this.config.vertexType);
          vertex.setSize(this.config.vertexSize);
          vertex.setCenter(transformedMouseDownPos);
          this.vertexIdCount++;
          this.prevVertex = vertex;
          this.setObjectsDict({
            type: "ADD",
            payload: vertex,
          });
          if (this.currentObjects.length > 0) {
            for (let i = 0; i < this.currentObjects.length; i++) {
              if (this.currentObjects[i].type === OBJECT_TYPES.EDGE) {
                this.config.vertexInterval = this.currentObjects[i].cost;
              }
              this.setObjectsDict({
                type: "ADD",
                payload: this.currentObjects[i],
              });
            }
            this.taskManager.addHistory(this.currentObjects, "ADD");

            this.currentObjects = [];
          } else {
            this.taskManager.addHistory([vertex], "ADD");
          }

          this.isGenerating = true;
        } else if (
          this.isMouseLeftDown &&
          isCursorOnVertex &&
          !this.isSpacePressed &&
          !this.isModalEnabled
        ) {
          if (this.isGenerating) {
            if (this.prevVertex.id === closestVertex.id) {
              this.isGenerating = false;
              this.currentObjects = [];
              break;
            } else if (
              Object.keys(this.objectsDict).includes(closestVertex.id)
            ) {
              const edge = new Edge();
              edge.setID(`${this.prevVertex.id}_${closestVertex.id}`);
              edge.setEdgeType(this.config.edgeType);
              edge.setVertices([this.prevVertex.id, closestVertex.id]);
              edge.setSrcPoint(this.prevVertex.center);
              edge.setDstPoint(closestVertex.center);
              edge.setVertexSize(this.config.vertexSize);
              edge.setCost(
                (Math.sqrt(
                  (this.prevVertex.center.x - closestVertex.center.x) ** 2 +
                    (this.prevVertex.center.y - closestVertex.center.y) ** 2
                ) *
                  this.mapMetadata.resolution) /
                  this.canvasInfo.scale
              );
              edge.setCenter();
              if (this.config.twoWay) {
                const invertedEdge = edge.getInverted();
                this.setObjectsDict({
                  type: "ADD",
                  payload: edge,
                });
                this.setObjectsDict({
                  type: "ADD",
                  payload: invertedEdge,
                });
                this.taskManager.addHistory([edge, invertedEdge], "ADD");
              } else {
                this.setObjectsDict({
                  type: "ADD",
                  payload: edge,
                });
                this.taskManager.addHistory([edge], "ADD");
              }
              this.isGenerating = false;
              this.currentObjects = [];
              break;
            } else {
              for (let i = 0; i < this.currentObjects.length; i++) {
                this.setObjectsDict({
                  type: "ADD",
                  payload: this.currentObjects[i],
                });
              }
              this.taskManager.addHistory(this.currentObjects, "ADD");

              this.currentObjects = [];
              this.isGenerating = false;
              this.vertexIdCount++;
            }
          } else {
            this.prevVertex = closestVertex;
            this.isGenerating = true;
          }
        } else if (
          this.isMouseLeftDown &&
          isCursorOnVertex &&
          this.isSpacePressed
        ) {
          this.isMovingObjects = true;
          // find connected edges
          const connectedEdges = Object.values(this.objectsDict).filter(
            (object) =>
              object.type === OBJECT_TYPES.EDGE &&
              object.vertices.includes(closestVertex.id)
          );
          let history = [];
          history.push(copyObject(closestVertex));
          this.movingObjects.push(closestVertex);
          for (let i = 0; i < connectedEdges.length; i++) {
            history.push(copyObject(connectedEdges[i]));
            this.movingObjects.push(connectedEdges[i]);
          }
          this.taskManager.addHistory(history, "UPDATE");
        }

        break;
      case "mousemove":
        this.mouseCurrentPos = { x, y };
        const transformedMouseCurrentPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin
        );

        closestVertex = this.getClosestVertex(transformedMouseCurrentPos);
        isCursorOnVertex = checkPointOnVertex(
          transformedMouseCurrentPos,
          closestVertex,
          this.mapMetadata.resolution
        );
        closestEdge = this.getClosestEdge(transformedMouseCurrentPos);
        isCursorOnEdgeCenter = checkPointOnEdgeCenter(
          transformedMouseCurrentPos,
          closestEdge,
          this.config.vertexSize,
          this.mapMetadata.resolution
        );

        if (
          (isCursorOnVertex || isCursorOnEdgeCenter) &&
          !this.isGenerating &&
          !this.isMouseLeftDown &&
          !this.isMovingObjects
        ) {
          if (this.isSpacePressed) {
            this.canvas.style.cursor = "move";
          } else {
            this.canvas.style.cursor = "pointer";
          }
        } else {
          this.canvas.style.cursor = "default";
        }
        //! 클릭 위치와 마우스 커서 위치가 이루는 직선 사이에 vertexInterval 마다 vertex,edge 생성
        if (
          this.isMouseLeftDown &&
          this.isGenerating &&
          !this.isSpacePressed &&
          !this.isModalEnabled
        ) {
          if (
            checkPointOnVertex(
              transformedMouseCurrentPos,
              this.prevVertex,
              this.mapMetadata.resolution
            )
          ) {
            break;
          }
          this.isGeneratingLine = true;
          // 클릭한 버텍스
          const prevVertexMeterCenter = this.pixelToMeter(
            this.prevVertex.center
          );

          // 마우스 커서
          const mouseMeterPos = this.pixelToMeter(transformedMouseCurrentPos);
          // 클릭한 버텍스와 마우스 커서 사이의 거리
          const distance = Math.sqrt(
            (prevVertexMeterCenter.x - mouseMeterPos.x) ** 2 +
              (prevVertexMeterCenter.y - mouseMeterPos.y) ** 2
          );
          // 방향벡터
          const directionVector = {
            x: (mouseMeterPos.x - prevVertexMeterCenter.x) / distance,
            y: (mouseMeterPos.y - prevVertexMeterCenter.y) / distance,
          };

          // vertex 간격
          const vertexInterval = this.config.vertexInterval;
          const num = Math.floor(distance / vertexInterval);

          // vertex 생성
          let tmpID = this.vertexIdCount;
          let currentObjects = [];
          let prevVertex = this.prevVertex;
          for (let i = 1; i < num + 1; i++) {
            const vertex = new Vertex();
            vertex.setID(tmpID);
            tmpID++;
            vertex.setVertexType(this.config.vertexType);
            vertex.setSize(this.config.vertexSize);
            const meterCenter = {
              x:
                prevVertexMeterCenter.x +
                directionVector.x * vertexInterval * i,
              y:
                prevVertexMeterCenter.y +
                directionVector.y * vertexInterval * i,
            };
            vertex.setCenter(this.meterToPixel(meterCenter));

            const edge = new Edge();
            edge.setID(`${prevVertex.id}_${vertex.id}`);
            edge.setEdgeType(this.config.edgeType);
            edge.setVertices([prevVertex.id, vertex.id]);
            edge.setSrcPoint(prevVertex.center);
            edge.setDstPoint(vertex.center);
            edge.setVertexSize(this.config.vertexSize);
            edge.setCost(
              (Math.sqrt(
                (prevVertex.center.x - vertex.center.x) ** 2 +
                  (prevVertex.center.y - vertex.center.y) ** 2
              ) *
                this.mapMetadata.resolution) /
                this.canvasInfo.scale
            );
            edge.setCenter();
            prevVertex = vertex;
            currentObjects.push(edge, vertex);
            if (this.config.twoWay) {
              const invertedEdge = edge.getInverted();
              currentObjects.push(invertedEdge);
            }
          }
          // 마지막 vertex가 기존 vertex와 겹치는 경우
          this.currentObjects = [...currentObjects];
          if (isCursorOnVertex) {
            // 전체 간격이 오차범위 이내인 경우, 마지막 버텍스 대체
            if (distance - num * vertexInterval < vertexInterval) {
              const edge = new Edge();
              edge.setID(`${prevVertex.id}_${closestVertex.id}`);
              edge.setEdgeType(this.config.edgeType);
              edge.setVertices([prevVertex.id, closestVertex.id]);
              edge.setSrcPoint(prevVertex.center);
              edge.setDstPoint(closestVertex.center);
              edge.setVertexSize(this.config.vertexSize);
              edge.setCost(
                (Math.sqrt(
                  (prevVertex.center.x - closestVertex.center.x) ** 2 +
                    (prevVertex.center.y - closestVertex.center.y) ** 2
                ) *
                  this.mapMetadata.resolution) /
                  this.canvasInfo.scale
              );
              edge.setCenter();

              this.currentObjects.push(edge);
              if (this.config.twoWay) {
                const invertedEdge = edge.getInverted();
                this.currentObjects.push(invertedEdge);
              }
            }
          }
        }

        // preview of next click
        if (
          !this.isMouseLeftDown &&
          this.isGenerating &&
          !this.isSpacePressed
        ) {
          const vertex = new Vertex();
          vertex.setID(this.vertexIdCount);
          vertex.setVertexType(this.config.vertexType);
          vertex.setSize(this.config.vertexSize);
          vertex.setCenter(transformedMouseCurrentPos);

          const edge = new Edge();
          edge.setID(`${this.prevVertex.id}_${vertex.id}`);
          edge.setEdgeType(this.config.edgeType);
          if (isCursorOnVertex) {
            if (this.prevVertex.id === closestVertex.id) {
              this.currentObjects = [];
              break;
            }
            edge.setVertices([this.prevVertex.id, closestVertex.id]);
            edge.setSrcPoint(this.prevVertex.center);
            edge.setDstPoint(closestVertex.center);
            edge.setVertexSize(this.config.vertexSize);

            const prevVertexMeterCenter = this.pixelToMeter(
              this.prevVertex.center
            );
            const closestVertexMeterCenter = this.pixelToMeter(
              closestVertex.center
            );
            edge.setCost(
              Math.sqrt(
                (prevVertexMeterCenter.x - closestVertexMeterCenter.x) ** 2 +
                  (prevVertexMeterCenter.y - closestVertexMeterCenter.y) ** 2
              )
            );
            edge.setCenter();
            if (this.config.twoWay) {
              const invertedEdge = edge.getInverted();
              this.currentObjects = [edge, isCursorOnVertex, invertedEdge];
            } else {
              this.currentObjects = [edge, isCursorOnVertex];
            }
          } else {
            edge.setVertices([this.prevVertex.id, vertex.id]);
            edge.setSrcPoint(this.prevVertex.center);
            edge.setDstPoint(vertex.center);
            edge.setVertexSize(this.config.vertexSize);
            edge.setCenter();
            const prevVertexMeterCenter = this.pixelToMeter(
              this.prevVertex.center
            );
            const vertexMeterCenter = this.pixelToMeter(vertex.center);

            edge.setCost(
              Math.sqrt(
                (prevVertexMeterCenter.x - vertexMeterCenter.x) ** 2 +
                  (prevVertexMeterCenter.y - vertexMeterCenter.y) ** 2
              )
            );

            this.currentObjects = [edge, vertex];
            if (this.config.twoWay) {
              const invertedEdge = edge.getInverted();
              this.currentObjects.push(invertedEdge);
            }
          }
        }

        // move vertex
        if (
          this.isMouseLeftDown &&
          !this.isGenerating &&
          this.isSpacePressed &&
          !this.isModalEnabled
        ) {
          let vertex;
          for (let i = 0; i < this.movingObjects.length; i++) {
            const object = this.movingObjects[i];
            if (object.type === OBJECT_TYPES.VERTEX) {
              vertex = object;
              vertex.setCenter({
                x: transformedMouseCurrentPos.x,
                y: transformedMouseCurrentPos.y,
              });
            } else if (object.type === OBJECT_TYPES.EDGE) {
              let srcVertex, dstVertex;
              if (object.vertices[0] === vertex.id) {
                srcVertex = vertex;
                dstVertex = this.objectsDict[object.vertices[1]];
                object.setSrcPoint(vertex.center);
              } else if (object.vertices[1] === vertex.id) {
                srcVertex = this.objectsDict[object.vertices[0]];

                dstVertex = vertex;
                object.setDstPoint(vertex.center);
              }
              object.setCenter();
              object.setCost(
                (Math.sqrt(
                  (srcVertex.center.x - dstVertex.center.x) ** 2 +
                    (srcVertex.center.y - dstVertex.center.y) ** 2
                ) *
                  this.mapMetadata.resolution) /
                  this.canvasScale
              );
            }
          }
          for (let i = 0; i < this.movingObjects.length; i++) {
            const object = this.movingObjects[i];
            this.setObjectsDict({
              type: "UPDATE",
              payload: object,
            });
          }
        }

        break;

      case "mouseleave":
      case "mouseup":
        this.mouseUpPos = { x, y };
        this.isMouseMiddleDown = false;
        this.isMouseRightDown = false;
        this.isMouseLeftDown = false;
        const transformedMouseUpPos = transformPointToOriginalImageCoord(
          { x, y },
          this.imageOrigin
        );
        if (this.isGeneratingLine) {
          this.isGeneratingLine = false;
          for (let i = 0; i < this.currentObjects.length; i++) {
            if (this.currentObjects[i].type === OBJECT_TYPES.VERTEX) {
              this.vertexIdCount++;
            }
            this.setObjectsDict({
              type: "ADD",
              payload: this.currentObjects[i],
            });
          }
          this.taskManager.addHistory(this.currentObjects, "ADD");

          this.resetCurrentObjects();
        }
        if (this.isMovingObjects) {
          this.isMovingObjects = false;

          this.movingObjects = [];
        }
        break;

      default:
        break;
    }
  }

  listenKeyboardAction(keyboardEvent) {
    const event = keyboardEvent.event;
    const action = keyboardEvent.action;
    const isCtrlPressed = event.ctrlKey;

    const key = event.key;

    switch (action) {
      case "keydown":
        switch (key) {
          case " ":
            this.isSpacePressed = true;
            break;
          case "Escape":
            this.resetCurrentObjects();
            break;
          case "z":
            if (isCtrlPressed) {
              this.resetCurrentObjects();
            }
            break;

          default:
            break;
        }
        break;
      case "keyup":
        switch (key) {
          case " ":
            this.isSpacePressed = false;
            break;

          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  getClosestVertex(point) {
    const vertices = Object.values(this.objectsDict).filter(
      (object) => object.type === OBJECT_TYPES.VERTEX
    );
    if (vertices.length === 0) return null;
    let minDistance = 100000;
    let minVertex = null;
    for (let i = 0; i < vertices.length; i++) {
      const vertex = vertices[i];
      const distance = Math.sqrt(
        (point.x - vertex.center.x) ** 2 + (point.y - vertex.center.y) ** 2
      );
      if (distance < minDistance) {
        minDistance = distance;
        minVertex = vertex;
      }
    }
    return minVertex;
  }

  getClosestEdge(point) {
    const edges = Object.values(this.objectsDict).filter(
      (object) => object.type === OBJECT_TYPES.EDGE
    );
    if (edges.length === 0) return null;
    let minDistance = 100000;
    let minEdge = null;
    for (let i = 0; i < edges.length; i++) {
      const edge = edges[i];
      const distance = Math.sqrt(
        (point.x - edge.center.x) ** 2 + (point.y - edge.center.y) ** 2
      );
      if (distance < minDistance) {
        minDistance = distance;
        minEdge = edge;
      }
    }
    return minEdge;
  }

  meterToPixel(meterPoint) {
    const pixelPoint = {
      x: Math.round(
        ((meterPoint.x - this.mapMetadata.origin[0]) /
          this.mapMetadata.resolution) *
          this.canvasInfo.scale
      ),
      y: Math.round(
        (this.imageOrigin.imageHeight -
          (meterPoint.y - this.mapMetadata.origin[1]) /
            this.mapMetadata.resolution) *
          this.canvasInfo.scale
      ),
    };
    return pixelPoint;
  }

  pixelToMeter(pixelPoint) {
    const meterPoint = {
      x:
        (pixelPoint.x / this.canvasInfo.scale) * this.mapMetadata.resolution +
        this.mapMetadata.origin[0],
      y:
        (this.imageOrigin.imageHeight - pixelPoint.y / this.canvasInfo.scale) *
          this.mapMetadata.resolution +
        this.mapMetadata.origin[1],
    };
    return meterPoint;
  }
}
