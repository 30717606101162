// import "./App.css";
import { useEffect, useState, useRef } from "react";
import { useObjectContext } from "context/ObjectContext";
import { useToolContext } from "context/ToolContext";
import { UI_TOOL_NAMES, OBJECT_TYPES, TOPOLOGY_TYPE } from "common/TYPES";

import styles from "styles/RightPanel.module.css";

import { useDataContext } from "context/DataContext";

function PoiPanel() {
  const {
    objectsDict,
    setObjectsDict,
    selectedObjectsList,
    setSelectedObjectsList,
    activeObjectCategory,
    setActiveObjectCategory,
  } = useObjectContext();
  const { currentTool } = useToolContext();
  const { poiToolConfig, setPoiToolConfig, poiTypes, setPoiTypes } =
    useToolContext();

  const [poiName, setPoiName] = useState("");

  const handlePoiNameAddInput = (event) => {
    // enter
    if (event.target.key === "Enter") handlePoiNameAddClick();
    // only allow alphabet and -
    const inputStr = event.target.value;
    const regex = /^[a-zA-Z-]*$/;
    if (!regex.test(inputStr)) return;

    // convert to uppercase
    const upperStr = inputStr.toUpperCase();
    setPoiName(upperStr);
  };

  const handlePoiNameAddClick = () => {
    if (poiTypes.includes(poiName) || poiName === "") return;
    setPoiTypes([...poiTypes, poiName]);
    setPoiToolConfig({ name: poiName });
    setPoiName("");
  };

  return (
    <div className={styles.panelContainer}>
      {currentTool === UI_TOOL_NAMES.POI &&
        selectedObjectsList.length === 0 && (
          <>
            <div className={styles.container}>
              <div className={styles.title}>툴 설정</div>
              <div className={styles.poiContent}>
                <div className={styles.poiTitle}>POI 타입</div>
                <div className={styles.verticalRadioContainer}>
                  {poiTypes.map((type) => (
                    <div key={type} className={styles.radioInputContainer}>
                      <input
                        type="radio"
                        className={styles.radioInput}
                        id={type}
                        name="poiType"
                        value={type}
                        onChange={() => setPoiToolConfig({ name: type })}
                        checked={type === poiToolConfig.name}
                      />
                      <label htmlFor={type} className={styles.radioInputLabel}>
                        {type}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
}

export default PoiPanel;
