import { useEffect, useState } from "react";
import styles from "styles/LeftPanel.module.css";
import { useObjectContext } from "context/ObjectContext";
import { useDOMContext } from "context/DOMContext";
import { useDataContext } from "context/DataContext";
import { useToolContext } from "context/ToolContext";
import {
  OBJECT_CATEGORY,
  OBJECT_TYPES,
  OBJECT_TYPES_TO_CATEGORY_MAP,
  UI_TOOL_NAMES,
} from "common/TYPES";
import { getActiveCategoryColor } from "features/utils";

function PoiObjectList({ searchTerm }) {
  const {
    objectsDict,
    setObjectsDict,
    selectedObjectsList,
    setSelectedObjectsList,
    invisibleObjectsList,
    setInvisibleObjectsList,
    activeObjectCategory,
    setActiveObjectCategory,
    setHoveredObject,
  } = useObjectContext();
  const { currentTool, zoomPanRef } = useToolContext();

  const { imageOrigin, setImageOrigin, canvasInfo, mapMetadata } =
    useDataContext();

  function focus(object) {
    const center = object.center;
    const canvasCenter = {
      x: canvasInfo.canvasWidth / 2,
      y: canvasInfo.canvasHeight / 2,
    };
    const transformedX = center.x * imageOrigin.scale + imageOrigin.x;
    const transformedY = center.y * imageOrigin.scale + imageOrigin.y;
    const dx = canvasCenter.x - transformedX;
    const dy = canvasCenter.y - transformedY;
    const newImageOrigin = { ...imageOrigin };
    newImageOrigin.x += dx;
    newImageOrigin.y += dy;
    setImageOrigin(newImageOrigin);
    zoomPanRef.current.updateImageOrigin(newImageOrigin);
  }
  return (
    <div className={styles.ObjectListContainer}>
      <div className={styles.ObjectList}>
        {Object.keys(objectsDict).map((key) => {
          const object = objectsDict[key];
          const type = object["type"];
          if (type === OBJECT_TYPES.POI) {
            if (
              searchTerm === "" ||
              (object["name"] + "-" + object["number"])
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            ) {
              const isInvisible = invisibleObjectsList.includes(key);

              return (
                <div
                  key={key}
                  className={styles.ObjectListItem}
                  style={{
                    border: selectedObjectsList.includes(key)
                      ? "1px solid skyblue"
                      : "1px solid transparent",
                    backgroundColor: selectedObjectsList.includes(key)
                      ? "rgba(135, 206, 235, 0.2)"
                      : "transparent",
                  }}
                  onMouseOver={(e) => {
                    if (currentTool === UI_TOOL_NAMES.CURSOR) {
                      if (!selectedObjectsList.includes(key)) {
                        e.currentTarget.style.border = "1px solid skyblue";
                        e.currentTarget.style.cursor = "pointer";
                        setHoveredObject([key]);
                      }
                    } else {
                      e.currentTarget.style.cursor = "default";
                      e.currentTarget.style.border = "1px solid transparent";
                      setHoveredObject([]);
                    }
                  }}
                  onMouseOut={(e) => {
                    if (selectedObjectsList.includes(key)) {
                      e.currentTarget.style.border = "1px solid skyblue";
                    } else {
                      e.currentTarget.style.border = "1px solid transparent";
                      setHoveredObject([]);
                    }
                  }}
                  onClick={(e) => {
                    if (e.button !== 0 || e.target.tagName === "IMG") return;
                    document.getSelection().removeAllRanges();
                    e.preventDefault();
                    if (currentTool === UI_TOOL_NAMES.CURSOR) {
                      // single select
                      if (
                        !selectedObjectsList.includes(key) &&
                        !e.ctrlKey &&
                        !e.shiftKey
                      ) {
                        setSelectedObjectsList([key]);
                        focus(objectsDict[key]);
                      } else {
                        if (selectedObjectsList.length > 0) {
                          setSelectedObjectsList([]);
                        }
                      }

                      // ctrl select
                      if (e.ctrlKey) {
                        if (selectedObjectsList.includes(key)) {
                          setSelectedObjectsList(
                            selectedObjectsList.filter((item) => item !== key)
                          );
                        } else {
                          setSelectedObjectsList([...selectedObjectsList, key]);
                        }
                      } else {
                        setSelectedObjectsList([key]);
                      }

                      // shift select
                      if (e.shiftKey) {
                        const poiObjects = Object.keys(objectsDict).filter(
                          (item) =>
                            OBJECT_TYPES_TO_CATEGORY_MAP[
                              objectsDict[item]["type"]
                            ] === OBJECT_CATEGORY.POI
                        );
                        const selectedObjects = [...selectedObjectsList];
                        const lastSelectedObject =
                          selectedObjects[selectedObjects.length - 1];
                        const lastSelectedObjectIndex =
                          poiObjects.indexOf(lastSelectedObject);
                        const currentObjectIndex = poiObjects.indexOf(key);
                        const selectedObjectsBetween = poiObjects.slice(
                          Math.min(lastSelectedObjectIndex, currentObjectIndex),
                          Math.max(
                            lastSelectedObjectIndex,
                            currentObjectIndex
                          ) + 1
                        );
                        setSelectedObjectsList(selectedObjectsBetween);
                      }
                    }
                  }}
                >
                  {invisibleObjectsList.includes(key) ? (
                    <span style={{ opacity: 0.5, color: "grey" }}>
                      {object["name"] + "-" + object["number"]}
                    </span>
                  ) : (
                    <span style={{ color: "black" }}>
                      {object["name"] + "-" + object["number"]}
                    </span>
                  )}
                  {isInvisible ? (
                    <div className={styles.visibleOptionButtonContainer}>
                      <img
                        key={key}
                        className={styles.visibleOptionButton}
                        src="/assets/icons/eye-closed.svg"
                        alt="invisible"
                        onClick={(e) => {
                          if (selectedObjectsList.includes(key)) {
                            setSelectedObjectsList(
                              selectedObjectsList.filter((item) => item !== key)
                            );
                          }
                          setInvisibleObjectsList(
                            invisibleObjectsList.filter((item) => item !== key)
                          );
                        }}
                      />
                    </div>
                  ) : (
                    <div className={styles.visibleOptionButtonContainer}>
                      <img
                        key={key}
                        className={styles.visibleOptionButton}
                        src="/assets/icons/eye-open.svg"
                        alt="visible"
                        onClick={(e) => {
                          if (selectedObjectsList.includes(key)) {
                            setSelectedObjectsList(
                              selectedObjectsList.filter((item) => item !== key)
                            );
                          }
                          setInvisibleObjectsList([
                            ...invisibleObjectsList,
                            key,
                          ]);
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            } else {
              return null;
            }
          }
        })}
      </div>
    </div>
  );
}

export default PoiObjectList;
