// import "./App.css";
import { useEffect, useState, useRef } from "react";
import { useObjectContext } from "context/ObjectContext";
import { useToolContext } from "context/ToolContext";
import { UI_TOOL_NAMES, OBJECT_TYPES } from "common/TYPES";
import { COLOR } from "common/COLOR";

import styles from "styles/RightPanel.module.css";

import { useDataContext } from "context/DataContext";

function PencilPanel() {
  const { selectedObjectsList } = useObjectContext();
  const { mapMetadata, canvasInfo } = useDataContext();
  const { currentTool } = useToolContext();
  const { pencilToolConfig, setPencilToolConfig } = useToolContext();
  const [activeColor, setActiveColor] = useState(pencilToolConfig.color);
  useEffect(() => {}, [pencilToolConfig, activeColor]);

  return (
    <div className={styles.panelContainer}>
      {currentTool === UI_TOOL_NAMES.PENCIL &&
        selectedObjectsList.length === 0 && (
          <>
            <div className={styles.container}>
              <div className={styles.title}>툴 설정</div>
              <div className={styles.settingItemContainer}>
                <div className={styles.settingItemTitle}> Area 타입 지정</div>
                <div className={styles.settingItemContent}>
                  <div className={styles.circleButtonContainer}>
                    <div
                      className={styles.circleButton}
                      style={{
                        backgroundColor: COLOR.FREE_SPACE,
                        border:
                          activeColor === COLOR.FREE_SPACE
                            ? "4px solid #2196f3"
                            : "1px solid skyblue",
                      }}
                      onClick={() => {
                        setPencilToolConfig({
                          ...pencilToolConfig,
                          color: COLOR.FREE_SPACE,
                        });
                        setActiveColor(COLOR.FREE_SPACE);
                      }}
                    ></div>
                    <div
                      className={styles.circleButton}
                      style={{
                        backgroundColor: COLOR.OCCUPIED,
                        border:
                          activeColor === COLOR.OCCUPIED
                            ? "4px solid #2196f3"
                            : "1px solid skyblue",
                      }}
                      onClick={() => {
                        setPencilToolConfig({
                          ...pencilToolConfig,
                          color: COLOR.OCCUPIED,
                        });
                        setActiveColor(COLOR.OCCUPIED);
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className={styles.settingItemContainer}>
                <div className={styles.settingItemTitle}>
                  {" "}
                  두께:{" "}
                  {(
                    (pencilToolConfig.stroke * mapMetadata.resolution) /
                    canvasInfo.scale
                  ).toFixed(2)}{" "}
                  m
                </div>
                <input
                  className={styles.strokeRange}
                  type="range"
                  min="1"
                  max="200"
                  value={pencilToolConfig.stroke}
                  onChange={(e) => {
                    setPencilToolConfig({
                      ...pencilToolConfig,
                      stroke: e.target.value,
                    });
                  }}
                ></input>
              </div>
            </div>
          </>
        )}
    </div>
  );
}

export default PencilPanel;
