import { createContext, useContext, useReducer, useState, useRef } from "react";
import { COLOR } from "common/COLOR";
import { TaskManager } from "features/TaskManager";
import { ZoomPan } from "features/ZoomPan";

import { UI_TOOL_NAMES, TOPOLOGY_TYPE, POI_TYPE } from "common/TYPES";
// 1) context creation...
const CTX = createContext();

// 2) context provide to others...
export const ToolContext = ({ children }) => {
  const taskManagerRef = useRef(new TaskManager());

  const [isSaveCalled, setIsSaveCalled] = useState(false);
  const [isUploadCalled, setIsUploadCalled] = useState(false);

  const [isUploadModalEnabled, setIsUploadModalEnabled] = useState(false);
  const [uploadDescription, setUploadDescription] = useState("");
  const [currentTool, setCurrentTool] = useState(UI_TOOL_NAMES.CURSOR);

  const [rectangleToolConfig, setRectangleToolConfig] = useState({
    color: COLOR.OCCUPIED,
    fill: true,
  });

  const [pencilToolConfig, setPencilToolConfig] = useState({
    color: COLOR.OCCUPIED,
    stroke: 3.0,
  });

  // topology
  const [topologyToolConfig, setTopologyToolConfig] = useState({
    vertexInterval: 1.0,
    vertexType: TOPOLOGY_TYPE.VERTEX[0].value,
    vertexSize: 0.5,
    edgeType: TOPOLOGY_TYPE.EDGE[0].value,
    twoWay: false,
  });

  const [isVertexSettingModalEnabled, setIsVertexSettingModalEnabled] =
    useState({
      enabled: false,
      object: null,
    });
  const [isEdgeSettingModalEnabled, setIsEdgeSettingModalEnabled] = useState({
    enabled: false,
    object: null,
  });

  // poi
  const [poiToolConfig, setPoiToolConfig] = useState({
    name: POI_TYPE[0],
  });
  const [poiTypes, setPoiTypes] = useState([...POI_TYPE]);
  const [isPoiSettingModalEnabled, setIsPoiSettingModalEnabled] = useState({
    enabled: false,
    object: null,
  });
  const [isRackTypeSettingModalEnabled, setIsRackTypeSettingModalEnabled] =
    useState(false);
  const [
    isLocationCodePatternSettingModalEnabled,
    setIsLocationCodePatternSettingModalEnabled,
  ] = useState(false);

  const [rackToolConfig, setRackToolConfig] = useState({
    rackType: "",
    locationCodePattern: "",
    rackNumberingStep: 1,
  });

  const [rulerToolConfig, setRulerToolConfig] = useState({
    size: 0.1,
  });

  const zoomPanRef = useRef(new ZoomPan());

  const values = {
    isSaveCalled,
    setIsSaveCalled,
    isUploadCalled,
    setIsUploadCalled,
    isUploadModalEnabled,
    setIsUploadModalEnabled,
    uploadDescription,
    setUploadDescription,

    isRackTypeSettingModalEnabled,
    setIsRackTypeSettingModalEnabled,
    isLocationCodePatternSettingModalEnabled,
    setIsLocationCodePatternSettingModalEnabled,

    currentTool,
    setCurrentTool,

    rectangleToolConfig,
    setRectangleToolConfig,

    pencilToolConfig,
    setPencilToolConfig,

    topologyToolConfig,
    setTopologyToolConfig,
    isVertexSettingModalEnabled,
    setIsVertexSettingModalEnabled,
    isEdgeSettingModalEnabled,
    setIsEdgeSettingModalEnabled,

    poiToolConfig,
    setPoiToolConfig,
    poiTypes,
    setPoiTypes,
    isPoiSettingModalEnabled,
    setIsPoiSettingModalEnabled,

    rackToolConfig,
    setRackToolConfig,

    rulerToolConfig,
    setRulerToolConfig,

    taskManagerRef,
    zoomPanRef,
  };

  return <CTX.Provider value={values}>{children}</CTX.Provider>;
};

export const useToolContext = () => useContext(CTX);
