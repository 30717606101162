import styles from "styles/Flyout.module.css";
import { POI_TYPE } from "common/TYPES";
import { useState } from "react";
import { useToolContext } from "context/ToolContext";

function PoiFlyout() {
  const { poiToolConfig, setPoiToolConfig, poiTypes, setPoiTypes } =
    useToolContext();

  const [poiName, setPoiName] = useState("");

  const handlePoiNameAddInput = (event) => {
    // enter
    if (event.target.key === "Enter") handlePoiNameAddClick();
    // only allow alphabet and -
    const inputStr = event.target.value;

    // convert to uppercase
    const upperStr = inputStr.toUpperCase();
    setPoiName(upperStr);
  };

  const handlePoiNameAddClick = () => {
    if (poiTypes.includes(poiName) || poiName === "") return;
    const regex = /^[a-zA-Z-]*$/;
    if (!regex.test(poiName)) {
      alert("영어와 '-'(하이픈) 만 입력 가능합니다.");
      return;
    }
    setPoiTypes([...poiTypes, poiName]);
    setPoiToolConfig({ name: poiName });
    setPoiName("");
  };
  return (
    <div className={styles.poiFlyoutContents}>
      <div className={styles.flyoutItem}></div>
      <div className={styles.flyoutItem}>
        <div className={styles.flyoutSection}>
          <span className={styles.flyoutLabel}>POI 타입 추가</span>
          <div className={styles.settingContainer}>
            <input
              className={styles.settingInput}
              placeholder="POI 이름 추가"
              value={poiName}
              onChange={handlePoiNameAddInput}
              onKeyDown={(event) => {
                if (event.key === "Enter" && poiName !== "")
                  handlePoiNameAddClick();
              }}
            />
            <button
              className={styles.settingButton}
              onClick={handlePoiNameAddClick}
            >
              추가
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PoiFlyout;
