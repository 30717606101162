import Snackbar from "./Snackbar";
import { ROBOT_STUDIO_API } from "common/API";
import { useDataContext } from "context/DataContext";
import { useToolContext } from "context/ToolContext";
import { useObjectContext } from "context/ObjectContext";

import { useFetchData } from "hooks/useFetchData";

import { useState, useEffect, useRef } from "react";
import { OBJECT_CATEGORY, OBJECT_TYPES } from "common/TYPES";

const saveNavMapUrl = ROBOT_STUDIO_API.POST_NAVIGATION_MAP_IMAGE;
const saveTopologyUrl = ROBOT_STUDIO_API.POST_TOPOLOGY;
const saveAreaUrl = ROBOT_STUDIO_API.POST_AREA;
const savePoiUrl = ROBOT_STUDIO_API.POST_POI;
const saveRackUrl = ROBOT_STUDIO_API.POST_RACK;
const uploadUrl = ROBOT_STUDIO_API.POST_UPLOAD;

const SnackbarType = {
  success: "success",
  fail: "fail",
};

function SaveHandler() {
  const snackbarSuccessRef = useRef(null);

  const { warehouseName, setWarehouseName } = useDataContext();

  const { objectsDict, activeObjectCategory } = useObjectContext();
  const {
    isSaveCalled,
    setIsSaveCalled,
    isUploadCalled,
    setIsUploadCalled,
    setIsUploadModalEnabled,
    uploadDescription,
    setUploadDescription,
  } = useToolContext();
  const { navMapImage, localizationMapImage, mapMetadata, canvasInfo } =
    useDataContext();

  useEffect(() => {}, [canvasInfo]);

  const localizationMapImg = new Image();
  localizationMapImg.src = localizationMapImage;
  const prevObjectsDict = useRef();
  const pixelToMeter = (pixelPoint) => {
    const meterPoint = {
      x:
        (pixelPoint.x / canvasInfo.scale) * mapMetadata.resolution +
        mapMetadata.origin[0],
      y:
        (localizationMapImg.height - pixelPoint.y / canvasInfo.scale) *
          mapMetadata.resolution +
        mapMetadata.origin[1],
    };
    return meterPoint;
  };
  const upload = async (description) => {
    try {
      const response = await fetch(uploadUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any additional headers if needed
        },
        body: JSON.stringify({
          branch_name: warehouseName,
          description: description,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to send nav map image to server");
      }
      console.log("nav map image sent successfully to server");
      // You can handle the response from the server here if needed
    } catch (error) {
      console.error("Error sending nav map image to server:", error.message);
    }
  };
  const saveImage = () => {
    const canvasScale = canvasInfo.scale;
    // download the image and draw with original image size
    const localizationMapImg = new Image();
    localizationMapImg.src = localizationMapImage;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = localizationMapImg.width;
    canvas.height = localizationMapImg.height;
    ctx.drawImage(localizationMapImg, 0, 0);
    const ids = Object.keys(objectsDict);
    if (ids.length > 0) {
      for (let i = 0; i < ids.length; i++) {
        const object = objectsDict[ids[i]];
        switch (object.type) {
          case OBJECT_TYPES.DRAWING:
          case OBJECT_TYPES.LINE:
            ctx.beginPath();
            ctx.strokeStyle = object.color;
            ctx.lineWidth = object.stroke / canvasScale;
            // only dot for lineCap
            ctx.lineCap = "round";
            ctx.lineJoin = "round";
            ctx.moveTo(
              object.data[0].x / canvasScale,
              object.data[0].y / canvasScale
            );

            for (let i = 0; i < object.data.length; i++) {
              const point = object.data[i];
              ctx.lineTo(point.x / canvasScale, point.y / canvasScale);
              ctx.moveTo(point.x / canvasScale, point.y / canvasScale);
            }
            ctx.stroke();
            ctx.closePath();
            break;
          case OBJECT_TYPES.RECTANGLE:
            if (object.fill) {
              ctx.fillStyle = object.color;
            } else {
              ctx.strokeStyle = object.color;
            }
            ctx.beginPath();
            ctx.strokeStyle = object.color;
            ctx.lineWidth = 1;
            ctx.moveTo(
              object.data[0].x / canvasScale,
              object.data[0].y / canvasScale
            );
            ctx.lineTo(
              object.data[1].x / canvasScale,
              object.data[1].y / canvasScale
            );
            ctx.lineTo(
              object.data[2].x / canvasScale,
              object.data[2].y / canvasScale
            );
            ctx.lineTo(
              object.data[3].x / canvasScale,
              object.data[3].y / canvasScale
            );
            ctx.lineTo(
              object.data[0].x / canvasScale,
              object.data[0].y / canvasScale
            );
            if (object.fill) {
              ctx.fill();
            }
            ctx.stroke();
            break;
          default:
            break;
        }
      }
    }
    const canvasUrl = canvas.toDataURL("image/png");

    sendNavMapUrlToServer(canvasUrl);
  };

  const sendNavMapUrlToServer = async (imgUrl) => {
    try {
      const response = await fetch(saveNavMapUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any additional headers if needed
        },
        body: JSON.stringify({ image_url: imgUrl, branch_name: warehouseName }),
      });

      if (!response.ok) {
        throw new Error("Failed to send nav map image to server");
      }
      console.log("nav map image sent successfully to server");
      // You can handle the response from the server here if needed
    } catch (error) {
      console.error("Error sending nav map image to server:", error.message);
    }
  };

  const saveTopologyData = () => {
    let topologyData = { Vertex: {}, Edge: {} };
    const ids = Object.keys(objectsDict);
    for (let i = 0; i < ids.length; i++) {
      const object = objectsDict[ids[i]];
      if (object.type === OBJECT_TYPES.VERTEX) {
        // const xMeter = object.center.x / ;
        const meterPoint = pixelToMeter(object.center);
        const x = parseFloat(meterPoint.x.toFixed(3));
        const y = parseFloat(meterPoint.y.toFixed(3));
        topologyData.Vertex[i] = {
          frame_id: object.frame_id,
          id: ids[i],
          x: x,
          y: y,
          type: object.vertexType,
          enabled: object.enabled,
        };
      } else if (object.type === OBJECT_TYPES.EDGE) {
        const cost = parseFloat(object.cost.toFixed(3));
        topologyData.Edge[i] = {
          type: object.edgeType,
          src: object.vertices[0],
          dst: object.vertices[1],
          cost: cost,
        };
      }
    }
    sendTopologyDataToServer(topologyData);
  };

  const sendTopologyDataToServer = async (topologyData) => {
    try {
      const response = await fetch(saveTopologyUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any additional headers if needed
        },
        body: JSON.stringify({
          data: topologyData,
          branch_name: warehouseName,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to send topology data to server");
      }
      console.log("topology data sent successfully to server");
      // You can handle the response from the server here if needed
    } catch (error) {
      console.error("Error sending topology data to server:", error.message);
    }
  };

  const saveAreaData = () => {
    let areaData = {};

    const ids = Object.keys(objectsDict);
    for (let i = 0; i < ids.length; i++) {
      const object = objectsDict[ids[i]];
      if (object.type === OBJECT_TYPES.RECTANGLE) {
        const meterCenter = pixelToMeter(object.center);
        const x = parseFloat(meterCenter.x.toFixed(3));
        const y = parseFloat(meterCenter.y.toFixed(3));
        const data = object.data.map((point) => {
          const meterPoint = pixelToMeter(point);
          return {
            x: parseFloat(meterPoint.x.toFixed(3)),
            y: parseFloat(meterPoint.y.toFixed(3)),
          };
        });

        areaData[object.id] = {
          type: object.type,
          center: { x: x, y: y },
          data: data,
          yaw: object.yaw,
          color: object.color,
          fill: object.fill,
        };
      } else if (
        object.type === OBJECT_TYPES.DRAWING ||
        object.type === OBJECT_TYPES.LINE
      ) {
        const meterCenter = pixelToMeter(object.center);
        const x = parseFloat(meterCenter.x.toFixed(3));
        const y = parseFloat(meterCenter.y.toFixed(3));
        const data = object.data.map((point) => {
          const meterPoint = pixelToMeter(point);
          return {
            x: parseFloat(meterPoint.x.toFixed(3)),
            y: parseFloat(meterPoint.y.toFixed(3)),
          };
        });

        areaData[object.id] = {
          type: object.type,
          center: { x: x, y: y },
          data: data,
          yaw: object.yaw,
          color: object.color,
          stroke: object.stroke,
        };
      }
    }
    sendAreaDataToServer(areaData);
  };

  const sendAreaDataToServer = async (areaData) => {
    try {
      const response = await fetch(saveAreaUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any additional headers if needed
        },
        body: JSON.stringify({ data: areaData, branch_name: warehouseName }),
      });

      if (!response.ok) {
        throw new Error("Failed to send area data to server");
      }
      console.log("area data sent successfully to server");
      // You can handle the response from the server here if needed
    } catch (error) {
      console.error("Error sending area data to server:", error.message);
    }
  };

  const savePoiData = () => {
    let poiData = {};
    const ids = Object.keys(objectsDict);
    for (let i = 0; i < ids.length; i++) {
      const object = objectsDict[ids[i]];
      if (object.type === OBJECT_TYPES.POI) {
        const meterCenter = pixelToMeter(object.center);
        const x = parseFloat(meterCenter.x.toFixed(3));
        const y = parseFloat(meterCenter.y.toFixed(3));
        poiData[object.id] = {
          type: object.type,
          center: { x: x, y: y },
          yaw: -object.yaw,
          name: object.name,
          number: object.number,
          size: object.size,
        };
      }
    }
    sendPoiDataToServer(poiData);
  };

  const sendPoiDataToServer = async (poiData) => {
    try {
      const response = await fetch(savePoiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any additional headers if needed
        },
        body: JSON.stringify({ data: poiData, branch_name: warehouseName }),
      });

      if (!response.ok) {
        throw new Error("Failed to send poi data to server");
      }
      console.log("poi data sent successfully to server");
      // You can handle the response from the server here if needed
    } catch (error) {
      console.error("Error sending poi data to server:", error.message);
    }
  };

  const saveRackData = () => {
    let rackData = {};
    const ids = Object.keys(objectsDict);
    for (let i = 0; i < ids.length; i++) {
      const object = objectsDict[ids[i]];
      if (object.type === OBJECT_TYPES.RACK) {
        const meterCenter = pixelToMeter(object.center);
        const x = parseFloat(meterCenter.x.toFixed(3));
        const y = parseFloat(meterCenter.y.toFixed(3));
        const meterOrigin = pixelToMeter(object.origin);
        const origin = {
          x: parseFloat(meterOrigin.x.toFixed(3)),
          y: parseFloat(meterOrigin.y.toFixed(3)),
        };
        const yaw = parseFloat(-object.yaw.toFixed(3));
        const rackTypeName = object.rackTypeName;
        const rackType = object.rackType;
        const locationCodePatternName = object.locationCodePatternName;
        const locationCodePattern = object.locationCodePattern;
        const zoneId = object.zoneId;
        const aisleId = object.aisleId;
        const rackId = object.rackId;
        const cells = object.cells;
        const cellDataArr = [];
        const pickingSide = object.pickingSide;
        for (let j = 0; j < cells.length; j++) {
          const cellData = {};
          const cell = cells[j];
          const meterCellCenter = pixelToMeter(cell.center);
          const x = parseFloat(meterCellCenter.x.toFixed(3));
          const y = parseFloat(meterCellCenter.y.toFixed(3));
          const z = parseFloat((cell.center.z * object.resolution).toFixed(3));
          const yaw = parseFloat(-cell.yaw.toFixed(3));
          cellData[cell.id] = [x, y, z, yaw];
          cellDataArr.push(cellData);
        }

        rackData[object.id] = {
          rackName: object.rackName,
          center: { x: x, y: y },
          origin: origin,
          yaw: yaw,
          rackTypeName: rackTypeName,
          rackType: rackType,
          locationCodePatternName: locationCodePatternName,
          locationCodePattern: locationCodePattern,
          pickingSide: pickingSide,
          zoneId: zoneId,
          aisleId: aisleId,
          rackId: rackId,
          cells: cellDataArr,
        };
      }
    }
    sendRackDataToServer(rackData);
  };

  const sendRackDataToServer = async (rackData) => {
    try {
      const response = await fetch(saveRackUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any additional headers if needed
        },
        body: JSON.stringify({ data: rackData, branch_name: warehouseName }),
      });

      if (!response.ok) {
        throw new Error("Failed to send poi data to server");
      }
      console.log("poi data sent successfully to server");
      // You can handle the response from the server here if needed
    } catch (error) {
      console.error("Error sending poi data to server:", error.message);
    }
  };

  useEffect(() => {
    if (isSaveCalled) {
      prevObjectsDict.current = objectsDict;
      console.log("save");
      if (activeObjectCategory === OBJECT_CATEGORY.AREA) {
        saveImage();
        saveAreaData();
      } else if (activeObjectCategory === OBJECT_CATEGORY.TOPOLOGY) {
        saveTopologyData();
      } else if (activeObjectCategory === OBJECT_CATEGORY.POI) {
        savePoiData();
      } else if (activeObjectCategory === OBJECT_CATEGORY.RACK) {
        saveRackData();
      }

      // alert("현재 편집 내용 저장 완료");
      snackbarSuccessRef.current.show("현재 상태 저장 완료");
    }
    setIsSaveCalled(false);
  }, [isSaveCalled, setIsSaveCalled]);

  useEffect(() => {
    if (isUploadCalled && prevObjectsDict.current !== objectsDict) {
      prevObjectsDict.current = objectsDict;
      if (activeObjectCategory === OBJECT_CATEGORY.AREA) {
        // saveImage();
        // saveAreaData();
      } else if (activeObjectCategory === OBJECT_CATEGORY.TOPOLOGY) {
        // saveTopologyData();
      } else if (activeObjectCategory === OBJECT_CATEGORY.POI) {
        // savePoiData();
      } else if (activeObjectCategory === OBJECT_CATEGORY.RACK) {
        // saveRackData();
      }
      // upload(uploadDescription);
      snackbarSuccessRef.current.show("서버 업로드 완료");
      setIsUploadCalled(false);
      setIsUploadModalEnabled(false);
      setUploadDescription("");
    } else if (isUploadCalled && prevObjectsDict.current === objectsDict) {
      snackbarSuccessRef.current.show("변경 사항이 없습니다");
      setIsUploadCalled(false);
      setIsUploadModalEnabled(false);
      setUploadDescription("");
    }
  }, [
    isUploadCalled,
    setIsUploadCalled,
    uploadDescription,
    setUploadDescription,
    setIsUploadModalEnabled,
  ]);

  return (
    <>
      <Snackbar ref={snackbarSuccessRef} type={SnackbarType.success} />
    </>
  );
}

export default SaveHandler;
