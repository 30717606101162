const server_url = `https://back.map-editor.floatic.io`;

export const ROBOT_STUDIO_API = {
  GET_MAP_METADATA: `${server_url}/metadata`,
  GET_NAVIGATION_MAP_IMAGE: `${server_url}/navigation/map`,
  GET_LOCALIZATION_MAP_IMAGE: `${server_url}/localization/map`,
  GET_WAREHOUSE_LIST: `${server_url}/branches`,
  GET_WAREHOUSE_NAME: `${server_url}/branch_name`,
  GET_TOPOLOGY_DATA: `${server_url}/topology`,
  GET_AREA_DATA: `${server_url}/area`,
  GET_POI_DATA: `${server_url}/poi`,
  GET_RACK_TYPES_DATA: `${server_url}/rack_types`,
  GET_LOCATION_CODE_PATTERNS_DATA: `${server_url}/location_code_patterns`,
  GET_RACK_DATA: `${server_url}/rack`,
  GET_ROBOT_POSES: `${server_url}/robot_poses`,
  POST_SELECT_WAREHOUSE: `${server_url}/checkout`,
  POST_NAVIGATION_MAP_IMAGE: `${server_url}/navigation/save/`,
  POST_TOPOLOGY: `${server_url}/topology/save/`,
  POST_AREA: `${server_url}/area/save/`,
  POST_POI: `${server_url}/poi/save/`,
  POST_RACK: `${server_url}/rack/save/`,
  POST_LOCATION_CODE_PATTERN: `${server_url}/location_code_pattern/save/`,
  POST_RACK_TYPE: `${server_url}/rack_type/save/`,
  POST_UPLOAD: `${server_url}/upload_saved`,
};
