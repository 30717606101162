import flyoutStyles from "styles/Flyout.module.css";
import { OBJECT_TYPES, TOPOLOGY_TYPE } from "common/TYPES";
import { useObjectContext } from "context/ObjectContext";
import { useToolContext } from "context/ToolContext";

function VertexSettingModal({ style, taskManagerRef }) {
  const { isVertexSettingModalEnabled, setIsVertexSettingModalEnabled } =
    useToolContext();
  const { objectsDict, setObjectsDict } = useObjectContext();
  const vertexTypeOnChange = (value) => {
    const object = isVertexSettingModalEnabled.object;
    taskManagerRef.current.addHistory([object], "UPDATE");

    object.setVertexType(value.target.value);
    setObjectsDict({ ...objectsDict, [object.id]: object });
    setIsVertexSettingModalEnabled({ enabled: false, object: null });
  };

  const handleDelete = () => {
    const object = isVertexSettingModalEnabled.object;
    // delete vertex and edge that are connected to the vertex
    const edges = Object.values(objectsDict).filter(
      (object) => object.type === OBJECT_TYPES.EDGE
    );
    const connectedEdges = edges.filter((edge) =>
      edge.vertices.includes(object.id)
    );
    taskManagerRef.current.addHistory([object, ...connectedEdges], "DELETE");
    const prevObjectsDict = { ...objectsDict };
    setObjectsDict({
      type: "DELETE",
      payload: prevObjectsDict[object.id],
    });
    connectedEdges.forEach((edge) => {
      setObjectsDict({
        type: "DELETE",
        payload: prevObjectsDict[edge.id],
      });
    });
    setIsVertexSettingModalEnabled({ enabled: false, object: null });
  };
  return (
    <div className={flyoutStyles.flyoutContents} style={style}>
      <div className={flyoutStyles.flyoutItem}>
        <div className={flyoutStyles.flyoutSection}>
          <div className={flyoutStyles.settingContainer}>
            <span className={flyoutStyles.settingLabel}>타입</span>
            <select
              onChange={vertexTypeOnChange}
              className={flyoutStyles.settingInput}
              value={isVertexSettingModalEnabled.object.vertexType}
            >
              {TOPOLOGY_TYPE.VERTEX.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.value}
                </option>
              ))}
            </select>
            <span className={flyoutStyles.settingUnit}></span>
          </div>
        </div>
        <div className={flyoutStyles.flyoutSection}>
          <span className={flyoutStyles.flyoutLabel}>Enabled</span>
          <div className={flyoutStyles.toggleButton}>
            <label className={flyoutStyles.switch}>
              <input
                type="checkbox"
                checked={isVertexSettingModalEnabled.object.enabled}
                onChange={() => {
                  const object = isVertexSettingModalEnabled.object;
                  taskManagerRef.current.addHistory([object], "UPDATE");

                  object.setEnabled(!object.enabled);
                  setObjectsDict({ ...objectsDict, [object.id]: object });
                  setIsVertexSettingModalEnabled({
                    enabled: false,
                    object: null,
                  });
                }}
              />
              <span className={flyoutStyles.toggle}></span>
            </label>
          </div>
        </div>
      </div>
      <div className={flyoutStyles.flyoutItem}>
        <div className={flyoutStyles.flyoutSection}>
          <button onClick={handleDelete}>삭제</button>
        </div>
      </div>
    </div>
  );
}

export default VertexSettingModal;
