import { createContext, useContext, useRef, useState } from "react";

// 1) context creation...
const CTX = createContext();

// 2) context provide to others...
export const DOMContext = ({ children }) => {
  const toolBarRef = useRef(null);
  const canvasContainerRef = useRef(null);
  const canvasRef = useRef(null);
  const [painter, setPainter] = useState(null);

  const values = {
    toolBarRef,
    canvasContainerRef,
    canvasRef,
    painter,
    setPainter,
  };

  return <CTX.Provider value={values}>{children}</CTX.Provider>;
};

export const useDOMContext = () => useContext(CTX);
