import { createContext, useContext, useState, useRef, useMemo } from "react";

// 1) context creation...
const CTX = createContext();

// 2) context provide to others...
export const DataContext = ({ children }) => {
  const [navMapImage, setNavMapImage] = useState(null);
  const [localizationMapImage, setLocalizationMapImage] = useState(null);
  const [mapMetadata, setMapMetadata] = useState(null);
  const [topologyData, setTopologyData] = useState(null);
  const [areaData, setAreaData] = useState(null);
  const [poiData, setPoiData] = useState(null);
  const [rackData, setRackData] = useState(null);
  const [canvasInfo, setCanvasInfo] = useState({
    canvasWidth: 0,
    canvasHeight: 0,
    scale: 2,
  });
  const [rackTypesData, setRackTypesData] = useState(null);
  const [warehouseName, setWarehouseName] = useState("");
  const [imageOrigin, setImageOrigin] = useState({});

  const [locationCodePatternsData, setLocationCodePatternsData] = useState("");

  const values = {
    navMapImage,
    setNavMapImage,
    localizationMapImage,
    setLocalizationMapImage,
    mapMetadata,
    setMapMetadata,
    topologyData,
    setTopologyData,
    areaData,
    setAreaData,
    poiData,
    setPoiData,
    rackData,
    setRackData,
    rackTypesData,
    setRackTypesData,
    canvasInfo,
    setCanvasInfo,
    warehouseName,
    setWarehouseName,
    locationCodePatternsData,
    setLocationCodePatternsData,
    imageOrigin,
    setImageOrigin,
  };

  return <CTX.Provider value={values}>{children}</CTX.Provider>;
};

export const useDataContext = () => useContext(CTX);
