import { useEffect, useState } from "react";
import styles from "styles/LeftPanel.module.css";
import { useObjectContext } from "context/ObjectContext";
import { useDataContext } from "context/DataContext";
import { useToolContext } from "context/ToolContext";
import {
  OBJECT_CATEGORY,
  OBJECT_TYPES,
  OBJECT_TYPES_TO_CATEGORY_MAP,
  UI_TOOL_NAMES,
} from "common/TYPES";
import { getActiveCategoryColor } from "features/utils";

function RackObjectList({ searchTerm }) {
  const {
    objectsDict,
    setObjectsDict,
    selectedObjectsList,
    setSelectedObjectsList,
    invisibleObjectsList,
    setInvisibleObjectsList,
    activeObjectCategory,
    setActiveObjectCategory,
    setHoveredObject,
  } = useObjectContext();
  const { currentTool, zoomPanRef } = useToolContext();

  const { imageOrigin, setImageOrigin, canvasInfo, mapMetadata } =
    useDataContext();

  function focus(object) {
    const center = object.center;
    const canvasCenter = {
      x: canvasInfo.canvasWidth / 2,
      y: canvasInfo.canvasHeight / 2,
    };
    const transformedX = center.x * imageOrigin.scale + imageOrigin.x;
    const transformedY = center.y * imageOrigin.scale + imageOrigin.y;
    const dx = canvasCenter.x - transformedX;
    const dy = canvasCenter.y - transformedY;
    const newImageOrigin = { ...imageOrigin };
    newImageOrigin.x += dx;
    newImageOrigin.y += dy;
    setImageOrigin(newImageOrigin);
    zoomPanRef.current.updateImageOrigin(newImageOrigin);
  }

  const [seeMoreRack, setSeeMoreRack] = useState([]);

  return (
    <div className={styles.ObjectListContainer}>
      <div className={styles.ObjectList}>
        {Object.keys(objectsDict).map((key) => {
          const object = objectsDict[key];
          const type = object["type"];

          if (type === OBJECT_TYPES.RACK) {
            let isCellSearch = false;
            for (let i = 0; i < object["cells"].length; i++) {
              if (
                object &&
                object["cells"] &&
                object["cells"][i] &&
                object["cells"][i]["id"] &&
                object["cells"][i]["id"]
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              ) {
                isCellSearch = true;
                break;
              }
            }
            if (
              searchTerm === "" ||
              object["rackName"]
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              isCellSearch
            ) {
              const isInvisible = invisibleObjectsList.includes(key);

              return (
                <div key={key} className={styles.RackListItem}>
                  <div
                    className={styles.ObjectListItem}
                    style={{
                      border: selectedObjectsList.includes(key)
                        ? "1px solid skyblue"
                        : "1px solid transparent",
                      backgroundColor: selectedObjectsList.includes(key)
                        ? "rgba(135, 206, 235, 0.2)"
                        : "transparent",
                    }}
                    onMouseOver={(e) => {
                      if (currentTool === UI_TOOL_NAMES.CURSOR) {
                        if (!selectedObjectsList.includes(key)) {
                          e.currentTarget.style.border = "1px solid skyblue";
                          e.currentTarget.style.cursor = "pointer";
                          setHoveredObject([key]);
                        }
                      } else {
                        e.currentTarget.style.cursor = "default";
                        e.currentTarget.style.border = "1px solid transparent";
                        setHoveredObject([]);
                      }
                    }}
                    onMouseOut={(e) => {
                      if (selectedObjectsList.includes(key)) {
                        e.currentTarget.style.border = "1px solid skyblue";
                      } else {
                        e.currentTarget.style.border = "1px solid transparent";
                        setHoveredObject([]);
                      }
                    }}
                    onClick={(e) => {
                      if (e.button !== 0 || e.target.tagName === "IMG") return;
                      document.getSelection().removeAllRanges();
                      e.preventDefault();
                      if (currentTool === UI_TOOL_NAMES.CURSOR) {
                        // single select
                        if (
                          !selectedObjectsList.includes(key) &&
                          !e.ctrlKey &&
                          !e.shiftKey
                        ) {
                          setSelectedObjectsList([key]);
                          focus(objectsDict[key]);
                        } else {
                          if (selectedObjectsList.length > 0) {
                            setSelectedObjectsList([]);
                          }
                        }

                        // ctrl select
                        if (e.ctrlKey) {
                          if (selectedObjectsList.includes(key)) {
                            setSelectedObjectsList(
                              selectedObjectsList.filter((item) => item !== key)
                            );
                          } else {
                            setSelectedObjectsList([
                              ...selectedObjectsList,
                              key,
                            ]);
                          }
                        } else {
                          setSelectedObjectsList([key]);
                        }

                        // shift select
                        if (e.shiftKey) {
                          const rackObjects = Object.keys(objectsDict).filter(
                            (item) =>
                              OBJECT_TYPES_TO_CATEGORY_MAP[
                                objectsDict[item]["type"]
                              ] === OBJECT_CATEGORY.RACK
                          );
                          const selectedObjects = [...selectedObjectsList];
                          const lastSelectedObject =
                            selectedObjects[selectedObjects.length - 1];
                          const lastSelectedObjectIndex =
                            rackObjects.indexOf(lastSelectedObject);
                          const currentObjectIndex = rackObjects.indexOf(key);
                          const selectedObjectsBetween = rackObjects.slice(
                            Math.min(
                              lastSelectedObjectIndex,
                              currentObjectIndex
                            ),
                            Math.max(
                              lastSelectedObjectIndex,
                              currentObjectIndex
                            ) + 1
                          );
                          setSelectedObjectsList(selectedObjectsBetween);
                        }
                      }
                    }}
                  >
                    {invisibleObjectsList.includes(key) ? (
                      <span style={{ opacity: 0.5, color: "grey" }}>
                        {object["rackName"] === ""
                          ? object["rackTypeName"]
                          : object["rackName"]}
                      </span>
                    ) : (
                      <>
                        {object.isRackConfigured ? (
                          <>
                            <div className={styles.seeMoreButtonContainer}>
                              {seeMoreRack.includes(key) ? (
                                <img
                                  key={key}
                                  className={styles.seeMoreButton}
                                  src="/assets/icons/see-more-active.svg"
                                  alt="see-more-active"
                                  onClick={(e) => {
                                    const newSeeMoreRack = seeMoreRack.filter(
                                      (item) => item !== key
                                    );
                                    setSeeMoreRack(newSeeMoreRack);
                                  }}
                                  onMouseOver={(e) => {
                                    // pointer
                                    e.currentTarget.style.cursor = "pointer";
                                  }}
                                  onMouseOut={(e) => {
                                    // default
                                    e.currentTarget.style.cursor = "default";
                                  }}
                                />
                              ) : (
                                <img
                                  key={key}
                                  className={styles.seeMoreButton}
                                  src="/assets/icons/see-more.svg"
                                  alt="see-less"
                                  onClick={(e) => {
                                    const newSeeMoreRack = [
                                      ...seeMoreRack,
                                      key,
                                    ];
                                    setSeeMoreRack(newSeeMoreRack);
                                  }}
                                  onMouseOver={(e) => {
                                    // pointer
                                    e.currentTarget.style.cursor = "pointer";
                                  }}
                                  onMouseOut={(e) => {
                                    // default
                                    e.currentTarget.style.cursor = "default";
                                  }}
                                />
                              )}
                            </div>
                            <span
                              style={{ color: "black" }}
                              className={styles.itemLabel}
                            >
                              {object["rackName"] === ""
                                ? object["rackTypeName"]
                                : object["rackName"]}
                            </span>
                          </>
                        ) : (
                          <span
                            style={{ color: "black" }}
                            className={styles.itemLabel}
                          >
                            {object["rackName"] === ""
                              ? object["rackTypeName"]
                              : object["rackName"]}
                          </span>
                        )}
                      </>
                    )}
                    {isInvisible ? (
                      <div className={styles.visibleOptionButtonContainer}>
                        <img
                          key={key}
                          className={styles.visibleOptionButton}
                          src="/assets/icons/eye-closed.svg"
                          alt="invisible"
                          onClick={(e) => {
                            if (selectedObjectsList.includes(key)) {
                              setSelectedObjectsList(
                                selectedObjectsList.filter(
                                  (item) => item !== key
                                )
                              );
                            }
                            setInvisibleObjectsList(
                              invisibleObjectsList.filter(
                                (item) => item !== key
                              )
                            );
                          }}
                        />
                      </div>
                    ) : (
                      <div className={styles.visibleOptionButtonContainer}>
                        <img
                          key={key}
                          className={styles.visibleOptionButton}
                          src="/assets/icons/eye-open.svg"
                          alt="visible"
                          onClick={(e) => {
                            if (selectedObjectsList.includes(key)) {
                              setSelectedObjectsList(
                                selectedObjectsList.filter(
                                  (item) => item !== key
                                )
                              );
                            }
                            setInvisibleObjectsList([
                              ...invisibleObjectsList,
                              key,
                            ]);
                            const newSeeMoreRack = seeMoreRack.filter(
                              (item) => item !== key
                            );
                            setSeeMoreRack(newSeeMoreRack);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {seeMoreRack.includes(key) ? (
                    <div className={styles.cellItem}>
                      {object["cells"].map((cell, index) => {
                        return (
                          <div key={index} className={styles.cellItemContainer}>
                            {cell !== undefined &&
                            cell["id"] !== undefined &&
                            cell["id"]
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase()) &&
                            searchTerm !== "" ? (
                              <div
                                className={styles.cellItem}
                                style={{
                                  backgroundColor: "rgba(135, 206, 235, 0.5)",
                                }}
                              >
                                {cell["id"]}
                              </div>
                            ) : (
                              <div className={styles.cellItem}>
                                {cell["id"]}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            } else {
              return null;
            }
          }
        })}
      </div>
    </div>
  );
}

export default RackObjectList;
