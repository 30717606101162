// import "./App.css";
import { useEffect, useState, useRef } from "react";
import { useObjectContext } from "context/ObjectContext";
import { useToolContext } from "context/ToolContext";
import { UI_TOOL_NAMES, OBJECT_TYPES, TOPOLOGY_TYPE } from "common/TYPES";
import NumericInput from "react-numeric-input";

import styles from "styles/RightPanel.module.css";

import { useDataContext } from "context/DataContext";

function RulerPanel() {
  const {
    objectsDict,
    setObjectsDict,
    selectedObjectsList,
    setSelectedObjectsList,
    activeObjectCategory,
    setActiveObjectCategory,
  } = useObjectContext();
  const { currentTool, taskManagerRef } = useToolContext();
  const { rulerToolConfig, setRulerToolConfig } = useToolContext();

  const sizeOnChange = (event) => {
    setRulerToolConfig({
      ...rulerToolConfig,
      size: event.target.value,
    });
    for (let key in objectsDict) {
      if (objectsDict[key].type === OBJECT_TYPES.RULER) {
        objectsDict[key].size = event.target.value;
      }
    }
    setObjectsDict({ ...objectsDict });
  };

  return (
    <div className={styles.panelContainer}>
      {currentTool === UI_TOOL_NAMES.RULER &&
        selectedObjectsList.length === 0 && (
          <>
            <div className={styles.container}>
              <div className={styles.title}>툴 설정</div>
              <div className={styles.settingItemContainer}>
                <div className={styles.settingItemTitle}>포인트 크기 [m]</div>
                <div className={styles.settingItemContent}>
                  <input
                    type="range"
                    min="0.1"
                    max="1"
                    step={0.05}
                    value={rulerToolConfig.size}
                    className={styles.settingInputRange}
                    onChange={sizeOnChange}
                  />
                  <span className={styles.settingUnit}>
                    {rulerToolConfig.size}m
                  </span>
                </div>
              </div>
              <div className={styles.settingItemContainer}></div>

              <div className={styles.saveBtnContainer}>
                <button
                  className={styles.saveBtn}
                  onClick={() => {
                    const objects = Object.values(objectsDict).filter(
                      (object) => object.type === OBJECT_TYPES.RULER
                    );
                    taskManagerRef.current.addHistory(objects, "DELETE");
                    for (let i = 0; i < objects.length; i++) {
                      setObjectsDict({
                        type: "DELETE",
                        payload: objects[i],
                      });
                    }
                  }}
                >
                  전체 삭제
                </button>
              </div>
            </div>
          </>
        )}
    </div>
  );
}

export default RulerPanel;
